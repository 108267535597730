import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tiktok, tiktokState } from "./tiktokInterface";
import Tiktok from "../../screens/dashboard/tiktok/listTiktok";

const initalState: tiktokState = {
    tiktokAccountList: [] ,
    tiktokDetail: {
        id: 0,
        creatorId: 0,
        socialMediaId: 0,
        accountName: "",
        accountLink: "",
        subCount: 0,
        avgView: "",
        avgPostPerMonth: undefined,
        refershToken: undefined,
        quota_point: undefined,
        channelOwnerId: "",
        rate: undefined,
        channelId: "",
        status: "",
        nicheId: 0
    } 
}

const tiktokSlice = createSlice({
    name: "titkok",
    initialState: initalState,
    reducers: {
        setTiktok: (state, action) => {
            state = action.payload;
            return state;
        },
        setTiktokAccountList: (state, action: PayloadAction<tiktok[]>) => {
            state.tiktokAccountList = action.payload;
        },
        updateTiktokDetail: (state, action: PayloadAction<tiktok>) => {
            state.tiktokDetail = action.payload;
        },
        updateTiktokAccount: (state, action: PayloadAction<tiktok>) => {
            state.tiktokAccountList = state.tiktokAccountList.map((tiktok) => {
                if (tiktok.id === action.payload.id) {
                    return action.payload;
                }
                return tiktok;
            });
        },
    }
})

export const { setTiktok, setTiktokAccountList, updateTiktokDetail } = tiktokSlice.actions;
export default tiktokSlice.reducer;