import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/RootState';
import {
  setCurrentStep,
  setSocialMedia,
  setPlan,
  setNiche,
  setSelectedNicheId,
  setDescription,
  setExpectedViews,
  setStartDate,
  setVideoLength,
  setFileObjects,
  updateCreatorSearch,
  resetCampaignCreation,
  setCampaignPhoto,
  setSelectedCreatorList,
  updateWebsiteList,
  setErrorObject,
  setSelectedCreatorListPlus,
  setAccessablity,
  setCreatorFilter,
  setSocialMediaId,
  setCreatorList,
  setPostDescription
} from '../../../../store/campaigns/campaignSlice';
import { Creator, CreatorFilter } from '../../../../store/creator/creatorInterface';
import { setLoading } from '../../../../store/zeterState/zeterStateSlice';
import { ErrorComponentProps } from '../components/ErrorComponent';
import { getCreatorListForBussinessLoggedOUtThunk, getCreatorListForBussinessThunk } from '../../../../store/creator/creatorThunk';
import { AppDispatch } from '../../../../store';
import { campaignCreation } from '../../../../store/campaigns/campaignThunk';

export const useCampaignCreation = () => {

  const dispatch = useDispatch<AppDispatch>();
  const campaignState = useSelector((state: RootState) => state.campaign.creation);
  const zeterStats = useSelector((state: RootState) => state.zeterState);
  const lookups = useSelector((state: RootState) => state.lookUp);

  const nextStep = () => {
    if (campaignState.currentStep < campaignState.totalSteps - 1) {
      dispatch(setCurrentStep(campaignState.currentStep + 1));
    }
  };

  const updateCreatorFilter = (filterData: CreatorFilter) => {
    dispatch(setCreatorFilter(filterData));
  }

  const updateCreatorListContent = (creatorList: Creator[]) => {
    dispatch(setCreatorList(creatorList));
  }

  const getCreatorList = (filterData: CreatorFilter, socialMediaId: number = campaignState.socialMediaId) => {
    dispatch(getCreatorListForBussinessThunk({
      socialMediaId: socialMediaId,
      CreatorFilter: filterData
    }));
  }

  const getCreatorListLogedout = (filterData: CreatorFilter, socialMediaId: number = campaignState.socialMediaId) => {
    dispatch(getCreatorListForBussinessLoggedOUtThunk({
      socialMediaId: socialMediaId,
      CreatorFilter: filterData
    }));
  }

  const updateCampaignPhoto = (value: string) => {
    dispatch(setCampaignPhoto(value));
  }

  const previousStep = () => {
    if (campaignState.currentStep > 0) {
      dispatch(setCurrentStep(campaignState.currentStep - 1));
    }
  };

  const buildFinalJson = (): any => {

    let finalJson = {
      "socialMediaId": campaignState.socialMediaId -1 ,
      "planId": campaignState.plan || 2,
      "nicheId": campaignState.selectedNicheId || 1 ,
      "postDescription": campaignState.postDescription,
      "description": campaignState.description,
      "expectedViews": campaignState.expectedViews || 2,
      "startDate": campaignState.startDate,
      "videoLength": campaignState.videoLength,
      "videoUrl": campaignState.videoUrl,
      "photoUrl": campaignState.campaignPhoto,
      "selectedCreatorList": campaignState.creatorSearch.selectedCreators,
    }

    return finalJson;
  }

  const CreateCampaign = () => {
    let finalJson = buildFinalJson();
    dispatch(campaignCreation({finalJson, 
      callback: () => {
       window.location.href = "/dashboard/business/listCampaign"; 
      }
    }));
  }

  const updateSocialMedia = (media: string) => {
    let socialMediaId = media === "Youtube" ? 1 : media === "Telegram" ? 3 : 2;
    dispatch(setSocialMediaId(socialMediaId))
    dispatch(setSocialMedia(media));
    getCreatorList(campaignState.creatorSearch.creatorFilter, socialMediaId);
  };

  const updatePlan = (plan: string) => {
    dispatch(setPlan(plan));
    nextStep();
  };

  const updateNiche = (niche: string) => {
    dispatch(setNiche(niche));
    nextStep();
  };

  const updateSelectedNicheId = (id: number) => {
    dispatch(setSelectedNicheId(id));
  };

  const updateDescription = (description: string) => {
    dispatch(setDescription(description));
  };

  const updateExpectedViews = (views: number) => {
    dispatch(setExpectedViews(views));
  };

  const updateStartDate = (date: string) => {
    dispatch(setStartDate(date));
  };

  const updateVideoLength = (length: string) => {
    dispatch(setVideoLength(length));
  };

  const updateFileObjects = (files: Array<{ name: string; duration?: number }>) => {
    dispatch(setFileObjects(files));
  };

  const updateLoading = (value: boolean) => {
    dispatch(setLoading(value));
  }

  const updateCreatorSearchState = (searchState: {
    searchTerm?: string;
    isSearching?: boolean;
    selectedCreators?: Array<any>;
  }) => {
    dispatch(updateCreatorSearch(searchState));
  };

  const updateCreatorList = (creators: Creator[]) => {
    dispatch(setSelectedCreatorList(creators));
  }

  const updateWebsiteListt = (websites: any[]) => {
    dispatch(updateWebsiteList(websites))
  }

  const resetCreation = () => {
    dispatch(resetCampaignCreation());
  };

  const updateErrorObject = (errorObject: ErrorComponentProps) => {
    dispatch(setErrorObject(errorObject));
  }

  const updateSelectedCreatorListPlus = (creators: Creator[]) => {
    dispatch(setSelectedCreatorListPlus(creators));
  }

  const updateSelectedCreator = (creators: Creator[]) => {
    dispatch(setSelectedCreatorList(creators));
  }

  const updateAccess = (isPublic: boolean) => {
    dispatch(setAccessablity(isPublic))
  }

  const updatePostDescription = (description: string) => {
    dispatch(setPostDescription(description))
  }

  return {
    lookups: lookups,
    status: zeterStats,
    state: campaignState,
    updateAccess,
    updateCreatorList,
    nextStep,
    previousStep,
    updateSocialMedia,
    updatePlan,
    updateNiche,
    updateSelectedNicheId,
    updateDescription,
    updateExpectedViews,
    updateStartDate,
    updateVideoLength,
    updateFileObjects,
    updateCreatorSearchState,
    resetCreation,
    updateLoading,
    updateCampaignPhoto,
    updateWebsiteListt,
    updateErrorObject,
    updateSelectedCreatorListPlus,
    updatePostDescription,
    updateSelectedCreator,
    getCreatorList,
    updateCreatorFilter,
    updateCreatorListContent,
    buildFinalJson,
    CreateCampaign,
    getCreatorListLogedout
  };
};