import React, { useState, useRef, useEffect } from 'react';
import { BsSend } from 'react-icons/bs';
import { useZeterBot } from './hooks/useZeterBot';
import { Message } from '../../../store/zeterAI/interfaceZeterAi';
import DOMPurify from 'dompurify';

DOMPurify.setConfig({
    ALLOWED_TAGS: ['a', 'p', 'span', 'div', 'b', 'i', 'strong', 'em', 'br', 'ul', 'li'],
    ALLOWED_ATTR: ['href', 'target', 'class', 'id', 'style', 'rel'],
    ADD_ATTR: ['target'],
    FORCE_BODY: true,
    SANITIZE_DOM: true,
    ALLOW_DATA_ATTR: false,
    USE_PROFILES: {html: true}
});

export default function ZeterBot() {
    const isMobile = window.innerWidth <= 768; 
    const [messages, setMessages] = useState<Message[]>([
        {
            text: 'Hello! I\'m ZeterBot. How can I assist you today?',
            isBot: true,
            timestamp: new Date()
        }
    ]);
    const [inputMessage, setInputMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const { loading, response, passPromptDispatch } = useZeterBot();

    useEffect(() => {
        scrollToBottom();
        inputRef.current?.focus();
    }, [messages]);

    const handleBotResponse = (userMessage: string) => {
        setIsTyping(true);
        passPromptDispatch(userMessage);
    };

    useEffect(() => {
        if (response && isTyping) {
            setIsTyping(false);
            setMessages(prev => [...prev, {
                text: response[0].text,
                isBot: true,
                timestamp: new Date()
            }]);
        }
    }, [response]);

    const handleSendMessage = async () => {
        if (inputMessage.trim()) {
            const userMessage = inputMessage.trim();
            setMessages(prev => [...prev, {
                text: userMessage,
                isBot: false,
                timestamp: new Date()
            }]);
            setInputMessage('');
            await handleBotResponse(userMessage);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className="flex flex-col h-[85vh] bg-gray-50 relative m-9 w-[90%]">
            <div className={`flex-1 overflow-hidden flex flex-col w-full p-4 ${isMobile ? 'pb-8 max-h-[80vh]' : 'pb-24 md:pb-4'}`}>
                <style>
                    {`
                    .message-html-content a {
                        display: inline-block;
                        padding: 0.5rem 1rem;
                        margin: 0.25rem 0;
                        border-radius: 0.5rem;
                        background-color: #3b82f6;
                        color: white;
                        text-decoration: none;
                        transition: all 0.2s ease-in-out;
                        font-weight: 500;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    }
                    .message-html-content a:hover {
                        background-color: #2563eb;
                        transform: translateY(-1px);
                        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                    }
                    .message.bot .message-html-content a {
                        background-color: #3b82f6;
                        color: white;
                    }
                    .message.user .message-html-content a {
                        background-color: white;
                        color: #3b82f6;
                    }
                    `}
                </style>
                <div className="flex-1 overflow-y-auto space-y-4 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
                    {messages.map((message, index) => (
                        <div key={index} className={`flex ${message.isBot ? 'justify-start' : 'justify-end'}`}>
                            <div className={`max-w-[80%] ${isMobile ? 'max-w-[90%]' : ''} rounded-2xl p-4 ${message.isBot ? 'bg-white shadow-md' : 'bg-blue-600 text-white'}`}>
                                <div
                                    className="message-html-content"
                                    // className="prose prose-sm max-w-none"
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.text) }}
                                    onClick={(e) => {
                                        const target = e.target as HTMLElement;
                                        const link = target.closest('a');
                                        if (link) {
                                            e.preventDefault();
                                            window.open(link.href, '_blank', 'noopener,noreferrer');
                                        }
                                    }}
                                />
                                {message.timestamp && (
                                    <div className={`text-xs mt-2 ${message.isBot ? 'text-gray-500' : 'text-blue-100'}`}>
                                        {message.timestamp.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                    {isTyping && (
                        <div className="flex justify-start">
                            <div className="bg-white rounded-2xl p-4 shadow-md space-x-2 flex items-center">
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
                                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
                            </div>
                        </div>
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <div className={`mt-4 fixed bottom-0 left-0 right-0 bg-gray-50 p-4 md:relative md:p-0 ${isMobile ? 'mb-20' : ''}`}>
                    <div className="flex items-center space-x-2 bg-white rounded-lg shadow-md p-2 max-w-6xl mx-auto">
                        <input
                            ref={inputRef}
                            type="text"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                            onKeyPress={handleKeyPress}
                            placeholder="Type your message here..."
                            aria-label="Message input"
                            disabled={isTyping}
                            className="flex-1 px-4 py-2 text-gray-700 bg-transparent focus:outline-none"
                        />
                        <button
                            onClick={handleSendMessage}
                            className={`p-2 rounded-full transition-colors ${inputMessage.trim() ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-gray-200 text-gray-400 cursor-not-allowed'}`}
                            aria-label="Send message"
                            disabled={isTyping || !inputMessage.trim()}
                        >
                            <BsSend className="w-5 h-5" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
