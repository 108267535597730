import { useCampaignCreation } from "../hooks/useCampaignCreation";

export default function SocialMediaTabs() {
    const { state, updateSocialMedia } = useCampaignCreation();

    return (
        <div className="flex justify-between w-full mb-4 px-2">
            {['Tiktok', 'Telegram', 'Youtube'].map((tab) => (
                <button
                    key={tab}
                    onClick={() => {
                        updateSocialMedia(tab);
                    }}
                    className={`px-2 sm:px-4 py-2 rounded-lg flex-1 mx-1 first:ml-0 last:mr-0 text-sm sm:text-base font-medium transition-all duration-300 transform hover:scale-105 ${state.socialMedia === tab
                        ? 'bg-gradient-to-r from-indigo-600 to-blue-500 text-white shadow-lg'
                        : 'bg-white text-gray-700 hover:bg-gradient-to-r hover:from-gray-50 hover:to-gray-100 border border-gray-200'
                        }`}
                >
                    <span className="flex items-center justify-center whitespace-nowrap">
                        {tab === 'Tiktok' && <span className="mr-1 sm:mr-2">🎵</span>}
                        {tab === 'Telegram' && <span className="mr-1 sm:mr-2">✈️</span>}
                        {tab === 'Youtube' && <span className="mr-1 sm:mr-2">🎥</span>}
                        {tab}
                    </span>
                </button>
            ))}
        </div>
    );
}