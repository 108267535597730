import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { getJson } from "../../../component/forms/controller.form";
import axios from "axios";
import { url } from "../../../util/constant";
import axiosInstance from "../../../axiosConfig";
import ManualConnectTiktok from "./manualConnectTiktok"
interface AddTiktokChannelProp {
  closeTiktok: any,
}

export default function AddTiktokChannel() {
  const [minute, setMinute] = useState<number>(5); // 2 minutes
  const [second, setSecond] = useState<number>(0); // 0 seconds
  const [tiktokInfo, setTiktokInfo] = useState<any>({});
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  useEffect(() => {
    const interval = setInterval(() => {
      setSecond((prevSecond) => {
        if (prevSecond === 0) {
          setMinute((prevMinute) => (prevMinute > 0 ? prevMinute - 1 : 0));
          return 59;
        } else {
          return prevSecond - 1;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (


    <div style={{
      borderRadius: 5,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontWeight: 'bolder',
      width: '100%'
    }}>


      <ManualConnectTiktok setTiktokInfo={setTiktokInfo} />
      <Button style={{ backgroundColor: '#090D25', margin: 20, color: 'white' }} onClick={() => {
        let jsonInfo = getJson(tiktokInfo);
        jsonInfo["payment"] = paymentInfo;
        const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;
        

        axiosInstance.post(url + "/tiktok/add_tiktok_channel", jsonInfo, {
          headers: {
            "Authorization": "Bearer " + userInfo
          }
        }).then((res) => {
          
          if (res.data.status === "failed") {
            alert(res.data.message);
            return;
          }
          alert("Channel Added Successfully");
          window.location.href = "/dashboard/tiktok";

        }
        ).catch((err) => {
          alert("Something Went Wrong");

          
        })

        
      }} >
        Add Channel
      </Button>
    </div>

  );
}
