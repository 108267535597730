import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../../store/RootState';
import { fetchDashboardData } from '../../../../store/dashboard/dashboardThunks';
import { CampaignStats, EarningsData } from '../../../../store/dashboard/dashboardInterface';
import {  UnknownAction } from '@reduxjs/toolkit';
import { ZeterState } from '../../../../store/zeterState/zeterStateSlice';

export interface DashboardData  extends ZeterState{
  fhfData: any;
  weeklyOrder: any;
  averageMonthlyEarning: number;
  campaignDetail: CampaignStats | null;
  earningData: EarningsData | null;
  allCampaignDetail: any[];
  isLoading: boolean;
}

export const useDashboardData = (): DashboardData => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootState) => state.dashboard);
  const zeterState = useSelector((state: RootState) => state.zeterState);

  useEffect(() => {
    dispatch(fetchDashboardData() as unknown as UnknownAction);
  }, [dispatch]);

  return {
    error: zeterState.error,
    loading: zeterState.loading,
    errorMessage: zeterState.errorMessage,
    errorCode: zeterState.errorCode,
    successMessage: zeterState.successMessage,
    fhfData: dashboardState.fhfData,
    weeklyOrder: dashboardState.weeklyOrder,
    averageMonthlyEarning: dashboardState.averageMonthlyEarning,
    campaignDetail: dashboardState.campaignDetail ,
    earningData: dashboardState.earningData ,
    allCampaignDetail: dashboardState.allCampaignDetail,
    isLoading: dashboardState.isLoading,
  };
};