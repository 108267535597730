import Header from "../../component/header/header";
import "./style.how_it_works.css";
import Footer from "../../component/footer/footer";
import ContentCard from "../../component/content_card/content_card";
import { useEffect, useState } from "react";
import Video from "../../component/video/video";
import VideoCard from "../../component/video/video";
import Tutorial from "../dashboard/tutorial/tutorial";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Table from "../../component/table/table";
export default function HowItWorks() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryProp = params.get('category');
  
  const [category, setCategory] = useState(categoryProp === "business" ? "business" : "creator");

  useEffect(() => {
    if (categoryProp) {
      setCategory(categoryProp);
    }
  }, [categoryProp]);

  return (
    <div className="howItWorks">
      <Header title="how_it_works" category={category} />

      <div className="bodyContent-2">
        <span className="toggleButton">
          <button
            onClick={() => setCategory("business")}
            className={
              category === "business" ? "toggleOption-selected" : "toggleOption"
            }
          >
            Business
          </button>
          <button
            onClick={() => setCategory("creator")}
            className={
              category === "creator" ? "toggleOption-selected" : "toggleOption"
            }
          >
            Creator
          </button>
        </span>
        <div className="bottomBody-2">
          {category === "creator" || categoryProp === "creator" ? (
            <div className="textContaint-tables">
              <p>
                At Zeter, we recognize the invaluable nature of content creation. It's like making art that thousands of people love to watch. Sadly, many creators in our country don’t get paid enough for their hard work. Zeter wants to change that.

              Here’s how you can make money with Zeter:
              <br />
              <br />

              1. Create an Account: Sign up on our platform and complete your profile.
              <br />  
              2. Get Campaign Requests:You’ll start getting requests to add ads to your videos.
              <br />
              3. pick a Campaign: Choose a campaign that fits your style.
              <br />
              4.Make Your Video: Add the advertiser’s content to your video.
              <br />
              5. Earn Money:Get paid based on the number of views your video gets.
              <br />
              6. Withdraw Your Earnings:Once the campaign is successful, you can easily withdraw your money.

              Join Zeter today and Turn Your Creativity into Money

              

              </p>
              <span className="middle-titles" style={{ color: "#6968FF", marginTop: 25, fontSize: "2em", fontWeight: 700 }}> Monetization Requirements  </span>
              <table style={{clear:'both'}}> 
                <tr>
                  <td style={{color:'#6968FF'}}>Social Media</td>
                  <td style={{color:'#6968FF'}}>Average View</td>
                  <td style={{color:'#6968FF'}}>Posts</td>
                  <td style={{color:'#6968FF'}}>Subscribers</td>

                </tr>
                <tr>
                  <td>Youtube</td>
                  <td>1000</td>
                  <td>10</td>
                  <td> 500 </td>
                </tr>
                <tr>
                  <td>Tiktok</td>
                  <td>3000</td>
                  <td>20</td>
                  <td> 4000 </td>

                </tr>
                <tr>
                  <td>Telegram</td>
                  <td>350</td>
                  <td>20</td>
                  <td> 1000 </td>

                </tr>
               
              </table> 
            </div>
          ) : (
            <div className="textContaint">
              <p>
                Zeter streamlines the process for businesses,Zeter makes it super easy for businesses to work with creators on TikTok, YouTube, and Telegram. Recognizing the importance of maximizing return on investment, We have a simple payment system where you only pay for every 1,000 views your ad gets.
<br />
<br />
                Understanding the significance of aligning creators' values with your brand, our expert and premium plans empower businesses to browse through a curated list of creators and select the perfect fit for their campaigns. Moreover, recognizing the need for diverse ad types, Zeter offers a range of options, spanning mentions, text, pictures, short videos, and comprehensive promotional videos tailored to showcase your business.
                <br />
                <br />

                Zeter goes beyond providing options for creator selection, ad types, niche targeting, and ad distribution. For businesses seeking a hassle-free experience, we offer a service where you simply choose the basics – budget and upload your ad creative. The rest is managed by our algorithm. We facilitate the distribution of your ad across numerous creators, ensuring your brand is displayed everywhere, effectively dominating the digital landscape. If that isn't taking over the internet what is?

                <br /> 
                <br /> 

              <span className="middle-title"> Benefits of working with zeter</span> 
              <br /> 
              <br />

                Tax deductible reciept
              <br />
                Budget friendly 
                <br />
                Secure transaction
              <br />
                Hassle free process
              <br />
                Control over your campaigns

              </p>
            </div>
          )}
        </div>
      </div>

      <div className="content_list">
        {category === "creator" ? (
          <ContentCard
            title={"Titel"}
            description={"How to Create An Account"}
            url={
              "https://www.youtube.com/embed/Ch9PZFoWBZg?si=TUyUxIixotGN1usU"
            }
          />
        ) : (
          <ContentCard
            title={"Titel"}
            description={"How to Create An Account"}
            url={
              "https://www.youtube.com/embed/UipGxBdYdVA?si=HE3zZpfk_FbQghd3"
            }
          />
        )}
      </div>

      <div>
        <h1 className="FAQheader">
          Frequently Asked Questions
        </h1>
      </div>
      <Tutorial />





      <Footer />
    </div>
  );
}
