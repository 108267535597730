import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ZeterState {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  successMessage: string;
  errorCode: number | null;
}

const initialState: ZeterState = {
  loading: false,
  error: false,
  errorMessage: '',
  successMessage: '',
  errorCode: null,
};

export const zeterStateSlice = createSlice({
  name: 'zeterState',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<{ message: string; code: number }>) => {
      state.error = true;
      state.errorMessage = action.payload.message;
      state.errorCode = action.payload.code;
    },
    clearError: (state) => {
      state.error = false;
      state.errorMessage = '';
      state.errorCode = null;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
    clearSuccessMessage: (state) => {
      state.successMessage = '';
    },
  },
});

export const {
  setLoading,
  setError,
  clearError,
  setSuccessMessage,
  clearSuccessMessage,
} = zeterStateSlice.actions;

export default zeterStateSlice.reducer;