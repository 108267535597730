import axios from "axios";
import { userProp } from "./prop.creator";
import { url } from "../../util/constant";

export function loginController(userData: { userName: string, password: string }, userType: string) {
  return new Promise((resolve, reject) => {
    axios.post(
      url + `${userType === "admin" ? "/auth/login/admin" :
        userType === "business" ? "/auth/login/buisness" : "/auth/login/creator"}`
      , userData)
      .then(function (response) {
        
        resolve(response);
        loginLocalConfig(response.data);
      })
      .catch(function (error) {
        
        resolve(error);
      });
  });
}


export function loginLocalConfig(userData: userProp) {
  localStorage.setItem("UserInfo", JSON.stringify(userData));
}