import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import { url } from "../../../util/constant";
import axios from "axios";
import { IoCloudUpload } from "react-icons/io5";
import { AiOutlineFundView } from "react-icons/ai";
import { FaPeopleGroup, FaToggleOff, FaToggleOn } from "react-icons/fa6";
import { TbTargetArrow } from "react-icons/tb";
import { Button, Input } from '@mui/material';
import { useState } from 'react';
import { GiTakeMyMoney } from 'react-icons/gi';
import { MdMoneyOff } from 'react-icons/md';
import axiosInstance from '../../../axiosConfig';
import { updateTiktokThunk } from '../../../store/tiktok/tiktokThunk';
import { AppDispatch } from '../../../store';
import { tiktok } from '../../../store/tiktok/tiktokInterface';


export function GetColumns(refersh: any, updateContent: any, updatedContent: any, nicheList: any[], ageGroup: any[], dispatch: AppDispatch): GridColDef[] {

  const RenderCell = (params: any) => {
    // Initialize state to hold the input value
    const [inputValue, setInputValue] = useState(params.value || '');

    // Handle the change event
    const handleChange = (e: any) => {
      const newValue = e.target.value;
      setInputValue(newValue); // Update the state
      // Update the params object
      params.value = newValue;
      let oldContent = { ...updatedContent };
      if (oldContent[params.row.id] == undefined) {
        oldContent[params.row.id] = {};
      }
      oldContent[params.row.id]["avgView"] = newValue;
      updateContent(oldContent)
    };

    return (

      <span>
        {inputValue}
      </span>
    );
  };

  const navigation = useNavigate();

  const ToggleEnabler = (params: any) => {

    const [enabled, setEnabled] = useState(params.row.status);
    return (

      <Button onClick={() => {
        enableTelegramAccount({ socialMediaAccountId: params.row.id, enabled: !enabled }).then((res: any) => {
          setEnabled(!enabled);
        }
        ).catch((err: any) => {

        });

      }} className="actionButton" style={{ display: 'flex', flexDirection: 'row', margin: 5 }} >
        {enabled &&
          <FaToggleOn style={{ fontSize: 43, marginLeft: 4 }} />
        }
        {!enabled &&
          <FaToggleOff style={{ fontSize: 43, marginLeft: 4 }} />
        }
      </Button>
    )

  }

  const columns: GridColDef[] = [
    {
      field: 'status', headerName: 'status', width: 200,
      renderCell: (params: any) => {
        return (

          <span style={{ background: 'green', padding: 5, color: 'white' }}>
            {params.row.status}
          </span>
        )
      }
    },
    { field: 'accountName', headerName: 'Account Name', width: 150 },
    {
      field: 'subCount', headerName: 'Sub Count', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
            {params.value.toLocaleString()}
            <FaPeopleGroup style={{ fontSize: 23 }} />
          </span>
        )
      }
    },
    {
      field: 'rate', headerName: 'Per Post ', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>

            <Input
              onChange={(e: any) => {
                const newValue = e.target.value;
                //params.row.rate = newValue; // Update the params object
                //params.value = newValue;
                let oldContent =  {...updatedContent} ;
                if (oldContent[params.row.id] == undefined) {
                  oldContent[params.row.id] = {};

                }
                oldContent[params.row.id]["rate"] = newValue;


                // setForceRender(!forceRender);
                updateContent(oldContent)
              }
              }

              value={params.row.rate} style={{ border: '1px black solid', borderRadius: 5, color: 'black', width: '100%' }} type="number" />
          </span>
        )
      }
    },
    {
      field: 'avgView', headerName: 'Avg View', width: 150,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
            {params.value.toLocaleString()}
            <AiOutlineFundView style={{ fontSize: 23 }} />
          </span>
        )
      }
    },
    {
      field: 'Niche', headerName: 'Niche', width: 150,
      renderCell: (params: any) => {
        const sortedNicheList = nicheList
          ? [...nicheList].sort((a, b) => {
            if (a.id === params.row.nicheId) return -1; // Move selected niche to the top
            if (b.id === params.row.nicheId) return 1;
            return 0;
          })
          : [];
        return (
          <select onChange={(e: any) => {


            const newValue = e.target.value;
            //params.row.ageGroup = newValue; // Update the params object
            params.value = newValue;
            let oldContent = { ...updatedContent };
            if (oldContent[params.row.id] == undefined) {
              oldContent[params.row.id] = {};
            }
            oldContent[params.row.id]["nicheId"] = newValue;
            // setForceRender(!forceRender);


            updateContent(oldContent)
          }} style={{ padding: 10, borderRadius: 5, color: 'black', width: '100%' }}>
            {sortedNicheList && sortedNicheList.map((niche: any) => {

              return (
                <option key={niche.id} value={niche.id} style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                  <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                    {niche?.name}
                    <TbTargetArrow style={{ fontSize: 23 }} />
                  </span>
                </option>
              )

            })
            }
          </select>

        )
      }
    },
    {
      field: 'audience',
      headerName: 'Audience Age',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params: any) => {
        const sortedAgeGroup = ageGroup
          ? [...ageGroup].sort((a, b) => {
            if (a.id === params.row.id) return -1; // Move selected niche to the top
            if (b.id === params.row.id) return 1;
            return 0;
          })
          : [];


        return (

          <select onChange={(e: any) => {


            const newValue = e.target.value;
            //params.row.ageGroup = newValue; // Update the params object
            params.value = newValue;
            let oldContent = { ...updatedContent };
            if (oldContent[params.row.id] === undefined) {
              oldContent[params.row.id] = {};
            }
            oldContent[params.row.id]["audienceAge"] = newValue;

            //  alert("OldContent: "+ JSON.stringify(oldContent));
            updateContent(oldContent)
          }} style={{ padding: 10, borderRadius: 5, color: 'black' }}>
            {sortedAgeGroup && sortedAgeGroup.map((age: any) => {

              return (
                <option value={age?.id} style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                  <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
                    {age?.name}
                    <TbTargetArrow style={{ fontSize: 23 }} />
                  </span>
                </option>
              )
            })
            }

          </select>
        );
      },
      width: 160,

    },
    {
      field: 'e',
      headerName: 'Enabled',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: ToggleEnabler
    },
    {
      field: 'Monitization',
      headerName: 'Monitization',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (param: any) => {
        if (param.row.avgView > 59 && param.row.subCount > 59) {
          return (
            <div style={{ display: 'flex' }}>
              <GiTakeMyMoney style={{ fontSize: 33, color: 'green' }} />
            </div>
          )
        } else {
          return (
            <div style={{ display: 'flex' }}>
              <MdMoneyOff style={{ fontSize: 33, color: 'red' }} />
            </div>
          )
        }
      }
    },
    {
      field: '',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params: any) => {



        return (
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>


            <Button onClick={() => {

              let socialMediaAccountId = params.row.id;
              let nicheId = updatedContent[params.row.id]["nicheId"] ? updatedContent[params.row.id]["nicheId"] : params.row.nicheId;
              let audienceAge = updatedContent[params.row.id]["audienceAge"] ? updatedContent[params.row.id]["audienceAge"] : params.row.audienceAge;
              let rate = updatedContent[params.row.id]["rate"] ? updatedContent[params.row.id]["rate"] : params.row.rate;
              rate = rate ? parseInt(rate) : 0;
              let updatedContentData : tiktok= {
                id: params.row.id,
                creatorId: 0,
                socialMediaId: 0,
                accountName: '',
                accountLink: '',
                subCount: 0,
                avgView: '',
                avgPostPerMonth: undefined,
                refershToken: undefined,
                quota_point: undefined,
                channelOwnerId: '',
                rate: params.row.rate,
                channelId: '',
                status: '',
                nicheId: nicheId 
              };
              dispatch(updateTiktokThunk(updatedContentData))

            }} className="actionButton" style={{ display: 'flex', margin: 5, flexDirection: 'row', backgroundColor: '#1c0d05', color: 'white' }} >
              Update
              <IoCloudUpload style={{ fontSize: 23, marginLeft: 4 }} />
            </Button>


          </div>
        );
      },
      width: 280,

    }
  ];




  return columns;
}

export function getRows(): any {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

  return new Promise((resolve, reject) => {
    axiosInstance.get(url + '/tiktok/get_tiktok_channels', {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {

        resolve(response.data.data);
      })
      .catch(function (error) {

        resolve(error);
      });
  });

}

export function getNicheList() {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

  return new Promise((resolve, reject) => {
    axiosInstance.get(url + '/creator/get_all_niches', {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function getAgeGroup() {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

  return new Promise((resolve, reject) => {
    axiosInstance.get(url + '/creator/get_all_age_groups', {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function updateTelegramAccount({ socialMediaAccountId, nicheId, audienceAge, rate }: any) {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

  return new Promise((resolve, reject) => {
    axios.post(url + '/tiktok/update_telegram_channel', {
      socialMediaAccountId: socialMediaAccountId,
      nicheId: nicheId,
      audienceAge: audienceAge,
      rate: rate
    }, {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}

export function enableTelegramAccount({ socialMediaAccountId, enabled }: any) {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

  return new Promise((resolve, reject) => {
    axiosInstance.get(url + '/telegram/enable_telegram_channel/' + socialMediaAccountId + '/' + enabled, {
      headers: {
        "Authorization": "Bearer " + userInfo
      }
    })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        resolve(error);
      });
  });
}
