import axios from "axios";
import { formBuilder } from "../../component/forms/prop.form";
import { createAccountJson } from "./api.model.create_account";
import { url } from "../../util/constant";


export function photoUpload(file: any) {
  // file upload code goes here
  // 
  // i have an endpoint that accepts image file
  // /uploads/
  // and that takes a file as a parameter
  // and returns an object 
  var formData = new FormData();
  formData.append("file", file);

  return new Promise((resolve, reject) => {
    axios.post(url + '/uploads', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(function (response) {
        
        resolve(response);
      })
      .catch(function (error) {
        
        resolve(error);
      });
  }
  );
}

export function fileUpload(file: any) {
  // file upload code goes here
  // 
  // i have an endpoint that accepts image file
  // /uploads/
  // and that takes a file as a parameter
  // and returns an object 
  var formData = new FormData();
  formData.append("video", file);

  return new Promise((resolve, reject) => {
    axios.post(url + '/uploads/upload2youtube', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(function (response) {
        
        resolve(response);
      })
      .catch(function (error) {
        
        resolve(error);
      });
  }
  );


}



export function buildCreatorApiBody(newJson: any) {
  
  var createAccountJson: createAccountJson = {
    user: {
      "userName": newJson.userName,
      "password": newJson.password,
    },
    creator: {
      "firstName": newJson.firstName,
      "lastName": newJson.lastName,
      "dob": newJson.dob,
      "gender": newJson.gender,
      "email": newJson.email,
      "phoneNumber": newJson.phoneNumber,
      "woreda": newJson.woreda,
      "subCity": newJson.subCity,
      "city": newJson.city,
      "isActive": true,
      "region": newJson.region,
      "identificationImage": newJson.identificationImage,
    }
  };

  return createAccountJson;
}



export async function createAccountApiCall(body: createAccountJson) {
  // TODO: Implement API call here
  return new Promise((resolve, reject) => {
    axios.post(url + '/creator', body)
      .then(function (response) {
        
        resolve(response);
      })
      .catch(function (error) {
        
        resolve(error);
      });
  });
}

export async function checkIfUserNamesExists(userName: string, email: string) {
  return new Promise((resolve, reject) => {
    axios.post(url + '/creator/check_if_creator_exist/email_or_username',
      {
        "userName": userName,
        "email": email
      })
      .then(function (response) {
        
        resolve(response);
      })
      .catch(function (error) {
        
        resolve(error);
      });
  });
}