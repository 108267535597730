import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { lookupState } from "./lookupInterface";

const initalState : lookupState = {
    niche: [],
    audienceAge: ["14-18","18-24","25-34","35-44","45-54","55-64","65+"]
}; 

export const lookupSlice = createSlice({
    name: "lookUp",
    initialState: initalState,
    reducers: {
         setNicheList: (state ,action: PayloadAction<any[]>) => {
            state.niche = action.payload;
        },
        setAudienceAge: (state, action: PayloadAction<string[]>) =>{
            state.audienceAge = action.payload;
        }     
    },
}); 

export const { setNicheList, setAudienceAge } = lookupSlice.actions;
export default lookupSlice.reducer;