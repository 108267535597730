import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSuccessMessage } from '../../../store/dashboard/dashboardSlice';
import { initializeDashboard } from '../../../store/dashboard/dashboardThunks';
import { AppDispatch } from '../../../store';

export function useDashboardInitialization() {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(setSuccessMessage(true));
        const timer = setTimeout(() => {
            dispatch(setSuccessMessage(false));
        }, 2000);
        dispatch(initializeDashboard());
        return () => {
            clearTimeout(timer);
        };
    }, [dispatch]);
}