import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axiosConfig";
import { url, version } from "../../util/constant";
import { setCampaignList } from "./campaignSlice";
import { clearError, setError, setLoading } from "../zeterState/zeterStateSlice";

export const getCampaignInfoForCreator = createAsyncThunk(
    "creator/getCampaign",
    async (_, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            //get campaign list by creator 
            const campaignList = await axiosInstance.get(
                `${url}/${version}/creator/campaigns`,
                { headers }
            );
            
            if(campaignList.data.statusCode === 200){
            dispatch(setCampaignList(campaignList.data.data));
            }
        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error fetching dashboard data',
                code: error.response?.status || 500
            }));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }

    }
);

export const getCreatorList = createAsyncThunk(
    "creator/getCreatorList",
    async (_, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            const response = await axiosInstance.get(`${url}/${version}/creator`);
        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error fetching dashboard data',
                code: error.response?.status || 500
            }));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const searchCreator = createAsyncThunk(
    "creator/searchCreator",
    async (searchTerm: string, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            const response = await axiosInstance.get(`${url}/${version}/creator/search?searchTerm=${searchTerm}`, { headers });
            return response.data;
        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error fetching dashboard data',
                code: error.response?.status || 500
            }));
        
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const campaignCreation = createAsyncThunk(
    "creator/campaignCreation",
    async ({finalJson, callback}: any, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            const response = await axiosInstance.post(`${url}/business/campaign/create/new`, finalJson, { headers });
            return response.data;
        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error fetching dashboard data',
                code: error.response?.status || 500
            }));
        } finally {
            dispatch(setLoading(false));
            callback();
        }
    })