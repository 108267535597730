import PublicCampaignFlow from "./flow/PublicCampaignFlow";
import PrivateCampaignFlow from "./flow/PrivateCampaginFlow";
import { useCampaignCreation } from "./hooks/useCampaignCreation";
import SearchCreators from "./SearchCreatorComponent/SearchCreators";

export default function CreateNewCampaign() {

    const { state } = useCampaignCreation();
    return (
        <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <div className='campaignContainer' style={{width: '90%'}}>
                {
                    state.currentStep === 0 &&
                        <SearchCreators/>
                }
                <PublicCampaignFlow />
                <PrivateCampaignFlow />
            </div>
        </div>
    )
}

const style = {
    centeritation: {
        alignSelf: 'center',
        display: 'flex',
        width: '100%',
        height: '70vh',
        overflow: 'scroll',
    }
};