import { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";
import { RiCloseCircleFill, RiCopyleftLine, RiFileCopyLine } from "react-icons/ri";

import { Button } from "@mui/material";
import { getJson } from "../../../../component/forms/controller.form";
import axios from "axios";
import { url } from "../../../../util/constant";
import axiosInstance from "../../../../axiosConfig";
import ManualConnectYoutube from "./connectForm";

interface AddTelegramChannelProp {
    closeTelegram: any,
}

export default function AddTelegramChannel() {
    const [minute, setMinute] = useState<number>(5); // 2 minutes
    const [second, setSecond] = useState<number>(0); // 0 seconds
    const [youtubeInfo, setYoutubeInfo] = useState<any>({});
    const [paymentInfo,setPaymentInfo] = useState<any>({});
    useEffect(() => {
        const interval = setInterval(() => {
            setSecond((prevSecond) => {
                if (prevSecond === 0) {
                    setMinute((prevMinute) => (prevMinute > 0 ? prevMinute - 1 : 0));
                    return 59;
                } else {
                    return prevSecond - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (


        <div style={{

            borderRadius: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontWeight: 'bolder',
            width: '100%'
        }}>

            <p style={{fontSize:13,width: '50%',textAlign:'left',color:'gray'}}>Provid a link of one of your youtube videos, make sure to add the key provided below in the description of the video to verify it's your channel</p>
            <ManualConnectYoutube setYoutubeInfo={setYoutubeInfo} />
            <Button 
            
                style={{backgroundColor:'#090D25',color:'white',marginTop:10}}
            onClick={()=>{
                    let jsonInfo = getJson(youtubeInfo);
                    jsonInfo["payment"] = paymentInfo;
                    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

                    axiosInstance.post(url + "/youtube/add_youtube_video", jsonInfo,{
                        headers: {
                            "Authorization": "Bearer " + userInfo   
                        }}).then((res) => {
                        
                        if(res.data["status"] === "error"){
                            alert(res.data["message"]); 
                        }else{

                            alert("Channel Added Successfully");
                            window.location.href = "/dashboard/youtube";
                        }

                    }
                    ).catch((err) => {
                        alert("Something Went Wrong");

                        
                    })

                    
                    
            }} > 
                Add Channel 
            </Button>
        </div>

    );
}
