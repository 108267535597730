
import { url } from "../../../util/constant";
import axios from "axios";
 

export function getRefershToken(code: string){
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axios.post(url+'/creator/connectSocialMedia', {
            code: code
        },{
            headers: {
                "Authorization": "Bearer " + userInfo   
            }
        })
          .then(function (response) {
        
            resolve(response);
          })
          .catch(function (error) {
            
          resolve(error);
          });
        });
}
