import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store/RootState"
import { AppDispatch } from "../../../../store";
import { zeterAiState } from "../../../../store/zeterAI/interfaceZeterAi";
import { ZeterState } from "../../../../store/zeterState/zeterStateSlice";
import { passPrompt } from "../../../../store/zeterAI/zeterAiThunk";

interface zeterAiProp extends zeterAiState , ZeterState {
    passPromptDispatch: (prompt: string) => void;
};
export const useZeterBot = (): zeterAiProp => {

    const zeterAi = useSelector((state: RootState) => state.zeterAi);
    const zeterState = useSelector((state: RootState) => state.zeterState);
    const dispatch = useDispatch<AppDispatch>();

    const passPromptDispatch = (prompt: string) => {
        dispatch(passPrompt(prompt));
    }

    return {...zeterAi, ...zeterState, passPromptDispatch}
}