

import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { View } from "lucide-react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { url } from "../../../util/constant";
import axios from "axios";
import axiosInstance from "../../../axiosConfig";

export interface Payment {
  id: number;
  campaignId: number;
  creatorId: number;
  paymentStatus: string;
  amount: string;
  paidDate: string;
  campaign: {
    id: number;
    businessId: number;
    contentDescription: string;
    shortVideoUrl: string;
    startDate: string;
    endDate: string;
    campaignStatus: string;
    estimatedBudget: string;
    duration: null;
    diversify: null;
    totalViews: number;
    createdDate: string;
  };
}

export function getPaymentInfoMonthly(){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/campaign/payment/details',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  
}

export function getColumns(): GridColDef[] {    


 const columns: GridColDef[] = [
    { field: 'campaign.contentDescription', headerName: 'Description', width: 130,
      renderCell: (params: any) => {
        const campaign = params.row.campaign;
        return (
          <span>
            {campaign.contentDescription}
          </span>
        );
      }
    },
    { field: 'amount', headerName: 'Payment Amount', width: 100 },
    { field: 'paymentStatus', headerName: 'status', width: 130,
        renderCell: (params:any) => {
           
            
            return(
                <span className={params.value === "Requested" ? "statusButton-pending" : params.value === "Rejected" ? "statusButton-rejected" : "statusButton-active"} >
                    {params.value + ""}
                </span>
            );
        }
},
   
    {
      field: 'id',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell:  (params:any) => {
       
        
        
        return (
          <div>


            <Link to={"/dashboard/order/"+params.value} className="actionButton" >
              
                Checkout  
                </Link>


           </div>
        );
    },
      width: 160,
     
    },
  ];




return columns;
}


export function getCreatorCampaignDetail(id : number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/detail/'+ id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  
}

export function getStatusOfTheCampaign(campaign_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/is_accepted/'+ campaign_id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data.status);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  

}


export function getRows(): Promise<Payment> {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/campaign/payment/creator/list',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  
  
}



export function manageOrderState(campaignDetail: any, callback: any){

}

export function isVideoUploaded(campaignDetail: any): boolean{
    var isVideoUploaded = false;
    //for(var x = 0 ; x < campaignDetail.length ; x++){
        if(campaignDetail.videoUrl != null){
            isVideoUploaded = true;
        }
   // }
    
    return isVideoUploaded;
}


export function acceptCampaign(column_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axios.patch(url+'/campaign/acceptOrder/'+column_id+'/Accepted', {},{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });
}