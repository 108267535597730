import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../../../store';
import { customBuiltPrompt } from '../../../../../store/zeterAI/zeterAiThunk';
import { RootState } from '../../../../../store/RootState';

interface AIPopupProps {
    open: boolean;
    onClose: () => void;
}

interface ConversationEntry {
    message: string;
    type: string;
}

export default function AIPopup({ open, onClose }: AIPopupProps) {
    const dispatch = useDispatch<AppDispatch>();
    const loading = useSelector((state: RootState) => state.zeterState.loading);
    const socialMedia = useSelector((state: RootState) => state.campaign.creation.socialMedia)
    const [currentQuestion, setCurrentQuestion] = useState(1);
    const [conversation, setConversation] = useState<ConversationEntry[]>([
        {
            "type": "ai",
            "message": "What type of business do you have?"
        }
    ]);
    const [currentInput, setCurrentInput] = useState('');

    const questions = [
        'What type of business do you have?',
        'How much budget do you have?',
    ];

    const handleSend = () => {
        if (currentInput.trim() === '') return;

        const newEntry: ConversationEntry = {
            message: currentInput,
            type: 'user'
        };

        const aiEntry: ConversationEntry = {
            message: questions[currentQuestion],
            type: 'ai'
        };

        let newConversation = [...conversation, newEntry, aiEntry];
        console.log("conversation: ", newConversation)
        setConversation([...conversation, newEntry, aiEntry]);
        setCurrentInput('');

        if (currentQuestion < questions.length  ) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            // Format conversation into a prompt string
            let prompt = [...conversation, newEntry]
                .map(entry => `${entry.type === 'user' ? 'User:' : 'AI:'} ${entry.message}`)
                .join('\n');
            
                let socialMediaUsed = socialMedia;
                prompt += "\n get only  " + socialMediaUsed + " channels";
            // Call the AI endpoint to get creator recommendations
            dispatch(customBuiltPrompt(prompt)).then(() => {
                // Only close the dialog after loading is complete
                onClose();
            });
        }
    };

    const handleClear = () => {
        setConversation([
            {
                "type": "ai",
                "message": "What type of business do you have?"
            }
        ]);
        setCurrentQuestion(1);
        setCurrentInput('');
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '12px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                }
            }}
        >
            {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-80 backdrop-blur-sm z-50 flex items-center justify-center">
                    <div className="flex flex-col items-center justify-center">
                        <div className="w-16 h-16 border-4 border-t-blue-600 border-r-purple-600 border-b-blue-600 border-l-purple-600 border-solid rounded-full animate-spin"></div>
                        <div className="mt-4 text-gray-700 font-medium text-center">Finding the best creators...</div>
                    </div>
                </div>
            )}
            <DialogTitle className="flex justify-between items-center border-b border-gray-200 pb-4">
                <span className="text-xl font-semibold text-gray-800">AI Assistant</span>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={handleClear}
                        className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800 bg-gray-100 hover:bg-gray-200 rounded-lg transition-all duration-200 flex items-center space-x-1"
                    >
                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        <span>Clear</span>
                    </button>
                    <IconButton onClick={onClose} size="small" className="text-gray-500 hover:text-gray-700">
                        <IoClose size={20} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent className="mt-4">
                <div className="space-y-6">

                    {/* Previous Conversations */}
                    {conversation.map((entry, index) => (
                        <div key={index} className="space-y-3">

                            {entry.type === "ai" && <div className="flex items-start space-x-3">
                                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center">
                                    <span className="text-white text-sm font-bold">AI</span>
                                </div>
                                <div className="flex-1 bg-gray-50 rounded-lg p-4 shadow-sm">
                                    <p className="text-gray-800">{entry.message}</p>
                                </div>
                            </div>
                            }
                            {entry.type === 'user' && (
                                <div className="flex items-start space-x-3">
                                    <div className="flex-shrink-0 w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center">
                                        <span className="text-gray-600 text-sm">You</span>
                                    </div>
                                    <div className="flex-1 bg-blue-50 rounded-lg p-4 shadow-sm">
                                        <p className="text-gray-800">{entry.message}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}

                    {/* User Input */}
                    <div className="flex items-end space-x-3">
                        <div className="flex-1">
                            <input
                                type="text"
                                value={currentInput}
                                onChange={(e) => setCurrentInput(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                                placeholder="Type your answer here..."
                                className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:outline-none focus:border-purple-500 focus:ring-1 focus:ring-purple-500 transition-colors duration-200"
                            />
                        </div>
                        <button
                            onClick={handleSend}
                            className="px-4 py-3 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-200 flex items-center space-x-2 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
                        >
                            <span>Send</span>
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                            </svg>
                        </button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}