import { useMemo } from "react";
import "./style.payment.css";
import Table from "../../../component/table/table";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getPaymentInfoMonthly, getRows } from "./controller.payment";
import { CircularProgress } from "@mui/material";
import CountDownTimer from "../orders/accepted_orders/countDownTimer";
import { AiFillYoutube } from "react-icons/ai";
import { FaTelegram, FaTiktok } from "react-icons/fa6";
import ChatBox from "../../../component/chat_box/chatBox";
import CustomChart from "../../../component/custom_chart/customChart";
import axiosInstance from "../../../axiosConfig";
import { url } from "../../../util/constant";

export default function Payments() {

  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentDate, setPaymentDate] = useState<any>(new Date().setDate(new Date().getDate() + 15));
  const [paymentInfo, setPaymentInfo] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    axiosInstance.get(`${url}/campaign/pay_days/get`,{
      headers: {
        Authorization: `Bearer ${userInfo['access_token']}`,
      }
    }).then((res) => {
      setPaymentDate(res.data.current_month.payment_date);
    }).catch((err)=>{

    })
    getPaymentInfoMonthly().then((res: any) => {
      
      setPaymentInfo(res);
      setLoading(false);
    }).catch((err) => {
      
    }
    );
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: 20 }}>

      <div className="hoverCard" >
        <CountDownTimer targetDates={new Date(paymentDate)} />
        <h1>Due Date</h1>
      </div>

      <div className="hoverCard" >
        <h3>{parseInt(paymentInfo.totalPayment).toLocaleString() + " "} Birr</h3>
        <h1>Pending Payment Amount</h1>
      </div>

      <div className="hoverCard" >
        <h3>{parseInt(paymentInfo.completedCampaign).toLocaleString()}</h3>
        <h1>Completed Campaigns This Month</h1>
      </div>

      <div className="hoverCard" style={{ overflowY: 'scroll' }} >
        <h1>Channels</h1>

        {Object.keys(paymentInfo.channelWithEarning || {}).map((key) =>
        (
          <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
            <h1>{key}</h1>
            <h1>{paymentInfo.channelWithEarning[key]}</h1>
          </div>
        )
        )}



      </div>
      <div className="hoverCard" >
        <h1>Payment Source</h1>
        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
          <div style={{ display: 'flex', margin: 5 }}>
            <AiFillYoutube size={30} color={'red'} />
            <h1>Youtube</h1>

          </div>
          <h1>{parseInt(paymentInfo.youtubeTotalEarning).toLocaleString()}</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
          <div style={{ display: 'flex', margin: 5 }}>
            <FaTelegram size={30} color={'#0088CC'} />
            <h1>Telegram</h1>

          </div>
          <h1>{parseInt(paymentInfo.telegramTotalEarning).toLocaleString()}</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
          <div style={{ display: 'flex', margin: 5 }}>
            <FaTiktok size={30} color={'#000'} />
            <h1>Tiktok</h1>

          </div>
          <h1>{parseInt(paymentInfo.tiktokTotalEarning).toLocaleString()}</h1>
        </div>
      </div>
      <div className="hoverCard" >
        <h1>Payment Receipt</h1>


        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
          <h1>Service Fee</h1>
          <h1>{parseInt(paymentInfo.serviceFee).toLocaleString()}</h1>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgray' }}>
          <h1>Total</h1>
          <h1>{parseInt(paymentInfo.totalPayment).toLocaleString()}</h1>
        </div>
      </div>
  
      <ChatBox />
    </div>
  );
}