import { Creator } from '../../../../store/creator/creatorInterface';
import { useCampaignCreation } from '../hooks/useCampaignCreation';
import { useEffect, useState } from 'react';
import MobileCreatorPopup from './MobileCreatorPopup';

export default function CreatorListTableComponent() {
    const { state, updateCreatorListContent, updateSelectedCreator, getCreatorList } = useCampaignCreation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(15);
    const isTelegram = state.socialMedia === 'Telegram';
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showMobilePopup, setShowMobilePopup] = useState(false);
    const [selectedCreator, setSelectedCreator] = useState<Creator | null>(null);

    const handleDaysChange = (creatorId: number, days: number) => {
        const CopyCreatorList = state.creatorSearch.creatorList.map(creator => {
            if (creator.id === creatorId) {
                return { ...creator, campaignDays: days };
            }
            return creator;
        });
        updateCreatorListContent(CopyCreatorList);
    };

    const handleCreatorSelect = (creator: Creator) => {
        if (creator.status === 'false') {
            alert('Creator is inactive');
            return;
        }
        const isCreatorSelected = state.creatorSearch.selectedCreators.some(c => c.id === creator.id);
        let newContent;
        if (isCreatorSelected) {
            newContent = state.creatorSearch.selectedCreators.filter(c => c.id !== creator.id);
        } else {
            // Ensure all necessary properties are included for Telegram creators
            if (isTelegram) {
                const updatedCreator = {
                    ...creator,
                    campaignDays: creator.campaignDays || 1,
                    isPin: creator.isPin || false
                };
                const selectedPlan = updatedCreator.telegramPaymentPlans?.find(plan => plan.days === (updatedCreator.campaignDays || 1));
                updatedCreator.rate = updatedCreator.isPin ? 
                    selectedPlan?.with_pin ?? 0 : 
                    selectedPlan?.with_out_pin ?? 0;
                newContent = [...state.creatorSearch.selectedCreators, updatedCreator];
            } else {
                newContent = [...state.creatorSearch.selectedCreators, creator];
            }
        }
        updateSelectedCreator(newContent);
    };

    const formatNumber = (num: number): string => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        return num.toString();
    }

    const handlePinToggle = (creatorId: number) => {
        const CopyCreatorList = state.creatorSearch.creatorList.map(creator => {
            if (creator.id === creatorId) {
                return { ...creator, isPin: !creator.isPin };
            }
            return creator;
        });
        updateCreatorListContent(CopyCreatorList);
    };

  useEffect(() => {
        getCreatorList(state.creatorSearch.creatorFilter);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Pagination logic
    const totalPages = Math.ceil(state.creatorSearch.creatorList.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = state.creatorSearch.creatorList.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    return (
        <div className="space-y-4">
            {/* Column Headers */}
            <div className={`grid gap-2 px-3 py-3 bg-gray-50 rounded-lg ${isTelegram && !isMobile ? 'grid-cols-7 md:grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr_1fr_1fr_1fr]' : 'grid-cols-5 md:grid-cols-[2fr_1fr_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr_1fr]'}`}>
                <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Influencer</div>
                <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Total Subscribers</div>
                <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Avg view</div>
                {isTelegram && !isMobile ? (
                    <>
                        <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Days</div>
                        <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Pin</div>
                        <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Price</div>
                    </>
                ) : (
                    <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Price per post</div>
                )}
                <div className="text-[8px] md:text-xs font-medium text-gray-500 uppercase truncate">Action</div>
            </div>

            {/* Creator Cards */}
            <div className="space-y-4">
                {currentItems.map((creator) => (
                    <div
                        key={creator.id}
                        className={`grid gap-4 p-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200 items-center ${isTelegram && !isMobile ? 'grid-cols-7 md:grid-cols-[2fr_1fr_1fr_1fr_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr_1fr_1fr_1fr]' : 'grid-cols-5 md:grid-cols-[2fr_1fr_1fr_1fr_1fr] sm:grid-cols-[2.5fr_1fr_1fr_1fr_1fr]'}`}
                    >
                        <div className="flex items-center space-x-2">
                            {!isMobile &&
                                <img
                                    src={creator.creator ? creator.creator.identificationImage || "" : "https:/sdlfkjs.com/sdf"}
                                    alt=""
                                    className="h-8 w-8 rounded-full shadow-sm"
                                />
                            }
                            <div className="min-w-0">
                                <a
                                    href={creator.accountLink}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm font-medium text-gray-900 hover:text-purple-600 block overflow-hidden whitespace-normal break-words"
                                    style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: '2',
                                        WebkitBoxOrient: 'vertical',
                                        wordBreak: 'break-word'
                                    }}
                                >
                                    {creator.accountName}
                                </a>
                            </div>
                        </div>
                        <div className="text-[10px] sm:text-sm text-gray-500">{formatNumber(creator.subCount)}</div>
                        <div className="text-[10px] sm:text-sm text-gray-500">{formatNumber(parseInt(creator.avgView) || 0)}</div>
                        {isTelegram && !isMobile ? (
                            <>
                                <div className="text-sm text-gray-500">
                                    <select
                                        value={creator.campaignDays || 1}
                                        onChange={(e) => handleDaysChange(creator.id, parseInt(e.target.value))}
                                        className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    >
                                        {creator.telegramPaymentPlans?.map((plan) => (
                                            <option key={plan.id} value={plan.days}>{plan.days} days</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="text-sm text-gray-500 flex items-center justify-center">
                                    <button
                                        onClick={() => handlePinToggle(creator.id)}
                                        className={`w-12 h-6 flex items-center rounded-full p-1 ${creator.isPin ? 'bg-purple-600' : 'bg-gray-300'} transition-colors duration-200`}
                                    >
                                        <div className={`w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ${creator.isPin ? 'translate-x-6' : 'translate-x-0'}`} />
                                    </button>
                                </div>
                                <div className="text-sm text-gray-500">
                                    {formatNumber(
                                        creator.isPin ?
                                            creator.telegramPaymentPlans?.find(plan => plan.days === (creator.campaignDays || 1))?.with_pin || 0 :
                                            creator.telegramPaymentPlans?.find(plan => plan.days === (creator.campaignDays || 1))?.with_out_pin || 0
                                    )} br
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="text-sm text-gray-500">{formatNumber(creator.rate || 0)}br</div>
                            </>
                        )}
                        <div>
                            <button
                                onClick={() => {
                                    if (isTelegram && isMobile && !state.creatorSearch.selectedCreators.some(c => c.id === creator.id)) {
                                        setSelectedCreator(creator);
                                        setShowMobilePopup(true);
                                    } else {
                                        handleCreatorSelect(creator);
                                    }
                                }}
                                className={`${isMobile ? 'w-10 h-10 rounded-full flex items-center justify-center' : 'px-3 py-1.5 rounded-lg w-32'} text-sm font-medium transition-colors duration-200 ${state.creatorSearch.selectedCreators.some(c => c.id === creator.id)
                                    ? 'bg-purple-100 text-purple-700 hover:bg-purple-200'
                                    : 'bg-black text-white hover:bg-gray-800'
                                    }`}
                            >
                                {isMobile ? (
                                    state.creatorSearch.selectedCreators.some(c => c.id === creator.id) ? (
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                                        </svg>
                                    )
                                ) : (
                                    state.creatorSearch.selectedCreators.some(c => c.id === creator.id)
                                        ? 'Selected'
                                        : 'Select'
                                )}
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex flex-col sm:flex-row items-center justify-between px-6 py-4 bg-white rounded-lg shadow-sm mb-20">
                <div className="flex items-center space-x-2 mb-4 sm:mb-0">
                    <span className="text-[10px] sm:text-sm text-gray-500">Items per page:</span>
                    <span className="text-[10px] sm:text-sm text-gray-500">
                        Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, state.creatorSearch.creatorList.length)} of {state.creatorSearch.creatorList.length}
                    </span>
                </div>

                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`p-2 rounded-lg ${currentPage === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                    >
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>

                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`px-3 py-1 rounded-lg text-sm font-medium ${currentPage === number ? 'bg-purple-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                        >
                            {number}
                        </button>
                    ))}

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`p-2 rounded-lg ${currentPage === totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                    >
                        <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Mobile Popup for Telegram */}
            <MobileCreatorPopup
                selectedCreator={selectedCreator as Creator || {}}
                showMobilePopup={showMobilePopup}
                onClose={() => setShowMobilePopup(false)}
                onDaysChange={handleDaysChange}
                onPinToggle={handlePinToggle}
                onCreatorSelect={handleCreatorSelect}
                formatNumber={formatNumber}
            />
        </div>
    );
}