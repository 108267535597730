import React, { useEffect, useState } from 'react';
import { handlePlanClick } from '../../../../util/campaign/createCampaign';
import { RiAddLine, RiGitPullRequestFill, RiLineLine, RiMediumLine, RiPulseLine } from 'react-icons/ri';
import { AiOutlineLine } from 'react-icons/ai';

interface Props {
    WebsiteList: any[];
    setWebsiteList: (value: any) => void;
    setPages: any; 
}

const WebsiteCollector: React.FC<Props> = ({ WebsiteList, setWebsiteList, setPages }) => {

    const [linkType, setLinkType] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [websiteListLocalized,setWebsiteListLocalized] = useState<any[]>([{
        "type": "social_media",
        "website": "YouTube",
        "url": ""
      }]);

  useEffect(()=>{
    // setWebsiteList([]);
  },[])

    return (
        <div className="fadeIn">
            <p className='question'> Insert Your SocialMedia Or Website</p>
            <p style={{ textAlign: 'start', color: 'gray', fontSize: 17 }}>
                The information you provide here will be used in a page that will be linked and putted under the campaign videos, please Verify you have put the correct link becuse this data might determine the result of your campaign
            </p>

          {WebsiteList.map((collectedData: any,index: number) => {

            return(
            <div className="website-linker-container" key={index}>  
                <select name="type" value={collectedData.type}  onChange={(e: any) => {
                    // let prevValue = [...websiteListLocalized];
                    // prevValue[index].type = e.target.value;
                    // 
                    // setWebsiteListLocalized(prevValue);

                    setWebsiteListLocalized(prevValue => {
                        const newState = prevValue.map((item, i) =>
                        i === index ? { ...item, type: e.target.value } : item
                    );
                    return newState;
                    
                    })

                    setWebsiteList((prevValue:any) => {
                        const newState = prevValue.map((item:any, i:number) =>
                        i === index ? { ...item, type: e.target.value } : item
                    );
                    return newState;
                    
                    })


                    
                    
                }}>
                    <option value="social_media" >Social Media</option>
                    <option value="website" >Website</option>
                </select>
                {collectedData.type == "social_media" &&
                    <select name="social_media" onChange={(e: any) => {
                        setWebsiteList((prevValue:any) => {
                            const newState = prevValue.map((item:any, i:number) =>
                            i === index ? { ...item, website: e.target.value } : item
                        );
                        return newState;
                        
                        })
                        //setSocialMedia(e.target.value);
                    }}>
                        <option value="YouTube" >YouTube</option>
                        <option value="Tiktok" >Tiktok</option>
                        <option value="Instagram" >Instagram</option>
                        <option value="Facebook" >Facebook</option>
                        <option value="Telegram" >Telegram</option>


                    </select>
                }

                <input onChange={(e) => {
                    
                    setWebsiteList((prevValue:any) => {
                        const newState = prevValue.map((item:any, i:number) =>
                        i === index ? { ...item, url: e.target.value } : item
                    );
                    return newState;
                    
                    })
                }} type='url' name="social_media_url" />


                <button onClick={()=>{
                      setWebsiteList((prevState: any[]) => {
                        const newState = [...prevState];
                        newState.splice(index, 1);
                        return newState;
                    });
                }} className="negative">
                    <AiOutlineLine width={50} height={50} />
                </button>
                <button onClick={() => {
                    let prevValue = [...WebsiteList];
                    // let toBeRepeated = prevValue[index];
                    let toBeRepeated = {
                        "type": "social_media",
                        "website": "YouTube",
                        "url": ""
                    };
                    // toBeRepeated.url = "";
                    prevValue.push(toBeRepeated);
                    setWebsiteList(prevValue);
                
                        
                }}>
                    <RiAddLine width={50} height={50} />
                </button>



            </div>
            )
          })}
            
        </div>
    );
};

export default WebsiteCollector;
