import './App.css';
import MainScreen from './screens/main/mainScreen';
import axiosInstance from './axiosConfig'; 
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store';
import { useEffect } from 'react';
import { lookupThunk } from './store/lookup/lookupThunk';

function App() {


  return (
    <div className="App">
   <MainScreen />
    </div>
  );
}

export default App;
