import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
<Provider store={store}>
    <React.StrictMode>
    <App />
  </React.StrictMode>
 </Provider> 

);
document.querySelector('meta[name="viewport"]')?.setAttribute('content', 'width=device-width, initial-scale=1, shrink-to-fit=no');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
