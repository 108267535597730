

import axios from "axios";
import { url } from "../../../util/constant";
import axiosInstance from "../../../axiosConfig";


var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];

export async function getSocialMedia(): Promise<any>{
    // TODO: Implement API call here
  return new Promise((resolve, reject) => {
    axiosInstance.get(url+'/creator/socialMediaAccounts/get',{headers: {
        "Authorization": "Bearer " +userInfo   
    }
    })
      .then(function (response) {
       
        resolve(response);
      })
      .catch(function (error) {
        
      resolve(error);
      });
    });
}

export async function getTotalView(): Promise<any>{
    return new Promise((resolve,reject) =>{
      axiosInstance.get(url+'/creator/statstic/totalView',{
        headers: {
          "Authorization": "Bearer " + userInfo
        }
      }).then((res) => {
        
        resolve(res.data);
      }).catch((err) => {
        
        reject(err);
      })
    });
}

export async function getCreatorDetails(): Promise<any>{
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];

  return new Promise((resolve,reject) =>{
    axiosInstance.get(url+'/creator/get_creator_detail/profile_page',{
      headers: {
        "authorization": "Bearer " + userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })
  });
}

export async function updateBankInfo(bankInfo: any){
  return new Promise((resolve,reject) =>{
    axiosInstance.post(url+'/creator/update_bank_info',bankInfo,{
      headers: {
        "authorization": "Bearer " + userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })
  });
}


export async function updateProfileInfo(profileInfo: any){
  return new Promise((resolve,reject) =>{
    axiosInstance.post(url+'/creator/update_profile_info',profileInfo,{
      headers: {
        "authorization": "Bearer " + userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })

  });

}


export async function updateTinInfo(tinInfo: any){
  return new Promise((resolve,reject) =>{
    axiosInstance.post(url+'/creator/update_tin_info',tinInfo,{
      headers: {
        "authorization": "Bearer " + userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })

  });

}

export async function updateProduction(tinInfo: any){
  return new Promise((resolve,reject) =>{
    axiosInstance.post(url+'/creator/update_production_info',tinInfo,{
      headers: {
        "authorization": "Bearer " + userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })

  });

}

export async function update_license(licenseInfo: any){
  return new Promise((resolve,reject) =>{
    axiosInstance.post(url+'/creator/update_license',licenseInfo,{
      headers: {
        "bearer": userInfo
      }
    }).then((res) => {
      
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject(err);
    })
  }
  );
}

// const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
//   const files: any = event.target.files;
//   fileUpload(files[0]).then((response: any ) => {
//     
//     setCampaignPhoto(response.data.file);
//   }).catch((err: any) => {
//     console.error(err);
  
//   })
//   const newFileObjects: FileObject[] = [];
//   if (files && files.length > 0) {
//     for (let i = 0; i < files.length; i++) {
//       const file = files[i];
//       const fileObject: FileObject = { name: file.name };
//       if (file.type.startsWith('video/')) {
//         const video = document.createElement('video');
//         video.src = URL.createObjectURL(file);
//         video.addEventListener('loadedmetadata', () => {
//           const duration = video.duration;
//           setVideoLength(duration.toString());
//           fileObject.duration = duration;
//           URL.revokeObjectURL(video.src);
//           setFileObjects([fileObject]);
//           axios
//             .post(`${url}/uploads`, file)
//             .then((res) => {
//               
//             })
//             .catch((err) => {
//               console.error(err);
//             });
//         });

       
//         video.addEventListener('error', (error) => {
//           console.error('Error loading video:', error);
//           URL.revokeObjectURL(video.src);
//         });
//         await video.load();
//       } else {
//         newFileObjects.push(fileObject);
//       }
//     }
//     setFileObjects((prevFileObjects) => [...prevFileObjects, ...newFileObjects]);
//   }
// };
