import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CampaignState } from "./campaignInterface";
import { Creator, CreatorFilter } from "../creator/creatorInterface";
import { stat } from "fs";
import { set } from "react-hook-form";

const initialState: CampaignState = {
    campaignList: [],
    campaignDetail: undefined,
    creation: {
        currentStep: 0,
        totalSteps: 6,
        socialMedia: "Tiktok",
        socialMediaId: 2,
        campaignPhoto: "",
        plan: "",
        niche: "",
        selectedNicheId: undefined,
        megaNiche: "",
        description: "",
        postDescription: "",
        photoUrl: "",
        videoUrl: "",
        nicheList: [],
        expectedViews: undefined,
        startDate: "",
        websiteList: [],
        videoLength: "",
        errorObject: {
            errorTitle: "",
            errorMessage: "",
            posetiveAction: function (): void {
                throw new Error("Function not implemented.");
            },
            negativeAction: function (): void {
                throw new Error("Function not implemented.");
            },
            type: ""
        },
        fileObjects: [],
        isPublic: false,
        creatorSearch: {
            searchTerm: "",
            creatorList: [],
            isSearching: false,
            selectedCreators: [],
            selectedCreatorListPlus: [],
            creatorFilter: {
                name: "",
                nicheId: 0,
                averageViewRange: [],
                subscriberRange: []
            },
        }
    }
}

export const campaignSlice = createSlice({
    name: "campaign",
    initialState,
    reducers: {
        setPhotoUrl: (state, action: PayloadAction<string>) => {
            state.creation.photoUrl = action.payload;
        },
        setVideoUrl: (state, action: PayloadAction<string>) => {
            state.creation.videoUrl = action.payload;
        },
        setCampaignList: (state, action) => {
            state.campaignList = action.payload;
        },
        setCreatorFilter: (state, action: PayloadAction<CreatorFilter>) => {
            state.creation.creatorSearch.creatorFilter = action.payload;
        },
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.creation.currentStep = action.payload;
        },
        setSocialMedia: (state, action: PayloadAction<string>) => {
            state.creation.socialMedia = action.payload;
        },
        setSocialMediaId: (state, action: PayloadAction<number>) => {
            state.creation.socialMediaId = action.payload;
        },
        setPlan: (state, action: PayloadAction<string>) => {
            state.creation.plan = action.payload;
        },
        setNiche: (state, action: PayloadAction<string>) => {
            state.creation.niche = action.payload;
        },
        setSelectedNicheId: (state, action: PayloadAction<number>) => {
            state.creation.selectedNicheId = action.payload;
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.creation.description = action.payload;
        },
        setExpectedViews: (state, action: PayloadAction<number>) => {
            state.creation.expectedViews = action.payload;
        },
        setStartDate: (state, action: PayloadAction<string>) => {
            state.creation.startDate = action.payload;
        },
        setVideoLength: (state, action: PayloadAction<string>) => {
            state.creation.videoLength = action.payload;
        },
        setFileObjects: (state, action: PayloadAction<Array<{ name: string; duration?: number }>>) => {
            state.creation.fileObjects = action.payload;
        },
        updateCreatorSearch: (state, action: PayloadAction<Partial<CampaignState['creation']['creatorSearch']>>) => {
            state.creation.creatorSearch = { ...state.creation.creatorSearch, ...action.payload };
        },
        resetCampaignCreation: (state) => {
            state.creation = initialState.creation;
        },
        setCampaignPhoto: (state, action: PayloadAction<string>) => {
            state.creation.campaignPhoto = action.payload
        },
        setSelectedCreatorList: (state, action: PayloadAction<Creator[]>) => {
            state.creation.creatorSearch.selectedCreators = action.payload;
        },
        updateWebsiteList: (state, action: PayloadAction<string[]>) => {
            state.creation.websiteList = action.payload;
        },
        setErrorObject: (state, action: PayloadAction<CampaignState["creation"]["errorObject"]>) => {
            state.creation.errorObject = action.payload;
        },
        setSelectedCreatorListPlus: (state, action: PayloadAction<Creator[]>) => {
            state.creation.creatorSearch.selectedCreatorListPlus = action.payload;
        },
        setAccessablity: (state, action: PayloadAction<boolean>) => {
            state.creation.isPublic = action.payload;
        },
        setPostDescription: (state, action: PayloadAction<string>) => {
            state.creation.postDescription = action.payload;
        },
        setCreatorList: (state, action: PayloadAction<any[]>) => {
            state.creation.creatorSearch.creatorList = action.payload;
        }
    },
})

export const {
    setCampaignPhoto,
    setCampaignList,
    setCurrentStep,
    setSocialMedia,
    setPlan,
    setNiche,
    setSelectedNicheId,
    setDescription,
    setExpectedViews,
    setStartDate,
    setVideoLength,
    setFileObjects,
    updateCreatorSearch,
    resetCampaignCreation,
    setSelectedCreatorList,
    updateWebsiteList,
    setErrorObject,
    setSelectedCreatorListPlus,
    setAccessablity,
    setPostDescription,
    setCreatorList,
    setCreatorFilter,
    setSocialMediaId,
    setPhotoUrl,
    setVideoUrl
} = campaignSlice.actions;
export default campaignSlice.reducer;