import axios from "axios";
import { url } from "../../../util/constant";



export function getCreatorDetailById(id: string){
    return new Promise((resolve, reject) => {
        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];

        axios.get(url+'/business/creator/getCreatorById/'+id,{
            headers: {
              "Authorization": "Bearer " + userInfo
            }
          }).then((res)=>{
            resolve(res.data);
        }).catch((err)=>{
            
        });

    }).catch((err) => {


    })
    
}