

import { url } from "../../../util/constant";
import axios from "axios";
import axiosInstance from "../../../axiosConfig";


export function getCreatorCampaignDetail(id : number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/detail/'+ id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  
}

export function getStatusOfTheCampaign(campaign_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/creator/campaign/is_accepted/'+ campaign_id, {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response.data);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });  

}

export function isVideoUploaded(campaignDetail: any): boolean{
    var isVideoUploaded = false;
    //for(var x = 0 ; x < campaignDetail.length ; x++){
        if(campaignDetail.videoUrl != null){
            isVideoUploaded = true;
        }
   // }
    
    return isVideoUploaded;
}

export function acceptCampaign(column_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/campaign/acceptOrder/'+column_id+'/Accepted',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });
}

export function rejectCampaign(column_id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axios.get(url+'/campaign/acceptOrder/'+column_id+'/Rejected',{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          
          resolve(response);
        })
        .catch(function (error) {
          
        resolve(error);
        });
      });
}