// src/axiosConfig.js
import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create();


// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403 || error.response.status === 500) {
      
      // Dispatch an event for a 403 error
      const event = new CustomEvent('axios403', { detail: error.response });
      window.dispatchEvent(event);
    }else{
      
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
