import React, { useState } from 'react';
import { AiOutlineMenu, AiFillYoutube } from 'react-icons/ai';
import { RiTelegramFill } from 'react-icons/ri';
import { FaTiktok } from 'react-icons/fa';
import awatar from "../../component/assets/logos/awatarLogo.svg";
import alibo from "../../component/assets/logos/alibo.svg";
import twoOneTwo from "../../component/assets/logos/twoOneTwoLogo.svg";
import esye from "../../component/assets/logos/eseye.svg";
import titkokSample from "../../component/assets/images/tiktokSample.png";
import AIImage from "../../component/assets/AI.png";
import ZeterLogo from "../../component/assets/logos/NewLogo.svg";
import { Link } from 'react-router-dom';
import Footer from '../../component/footer/footer';

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="min-h-screen bg-[linear-gradient(170.13deg,#1C1C33_50.09%,#6968FF_108.09%)]">
      <nav className="px-4 py-3 flex justify-between items-center">
        <div> 
          <img src={ZeterLogo} style={{width: 50, height: 30, margin: 0, padding: 0}} /> 
          </div>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="text-white p-2"
        >
          <AiOutlineMenu size={24} />
        </button>
      </nav>

      {isMenuOpen && (
        <div className="fixed inset-0 bg-[#040612] z-50 p-4">
          <div className="flex justify-end">
            <button
              onClick={() => setIsMenuOpen(false)}
              className="text-white p-2"
            >
              <AiOutlineMenu size={24} />
            </button>
          </div>
          <div className="flex flex-col items-center space-y-6 mt-20">
            <Link className="text-white text-xl" to="/">Home</Link> 
            <Link className="text-white text-xl" to="/about_us">About</Link> 
            <Link className="text-white text-xl" to="/About">Plans</Link> 
            <Link className="text-white text-xl" to="/creator_login">Login As Creator</Link> 
            <Link className="text-white text-xl" to="/business_login">Login As Business</Link> 
            <Link className="text-white text-xl" to="/how_it_works">Learn more..</Link> 
          </div>
        </div>
      )}
       <p style={{color: '#6968FF', fontSize: 13, marginTop: 30}}>
        Ethiopia's first Modern Influencer Marketing Agency
        </p>
      <div className="container mx-auto px-4 py-16 md:py-24 mt-[-20px]">
      

        <div className="max-w-4xl text-left">
          <h1 className="text-4xl md:text-9xl font-bold text-white mb-6 flex flex-col gap-5">
            <span className="text-[49px] md:text-9xl font-bold text-white font-['Raleway']">Boost your</span>
            <span className="text-[49px] md:text-9xl font-bold text-white font-['Raleway']">Sales with</span>
            <span className="text-[49px] text-[#6968FF]">Influencer </span>
            <span className="text-[49px] md:text-9xl font-bold text-white font-['Raleway']">Marketing!</span>
          </h1>
          <p className="text-gray-300 text-lg md:text-xl mb-8 max-w-2xl mx-auto">
            Use the power of <span className="text-[#6968FF]">Ethiopian Influencers</span> on TikTok, Telegram, and YouTube. ZeterAds connects you to the right Influencers to
            <span className="text-[#6968FF]"> grow your brand and boost engagement </span>
          </p>
          <div className="flex justify-center">
            <button 
            onClick={()=>{
              window.location.href = "/NotLoggedInCreator"
            }} 
            className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF] text-white font-semibold px-8 py-3 rounded-lg hover:opacity-90 transition-colors duration-300">
              Start Advertising
            </button>
          </div>
        </div>

        <span className="text-[#6968FF] mt-[40px]">Brands we worked with</span>

        <div className="grid grid-cols-2 md:grid-cols-5 gap-8 mt-4 bg-gray-900/50 p-8 rounded-xl">
          <div className="flex items-center justify-center p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors duration-300">
            <img src={awatar} alt="Autar TV" className="h-12 w-auto" />
          </div>
          <div className="flex items-center justify-center p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors duration-300">
            <img src={alibo} alt="Alibo" className="h-12 w-auto" />
          </div>
          <div className="flex items-center justify-center p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors duration-300">
            <img src={twoOneTwo} alt="Two" className="h-12 w-auto" />
          </div>
          <div className="flex items-center justify-center p-4 bg-white/5 rounded-lg hover:bg-white/10 transition-colors duration-300">
            <img src={esye} alt="Aho" className="h-12 w-auto" />
          </div>

        </div>
      </div>
      <div className="container mx-auto px-4 py-8 mt-[0px]">
        {/* Stats Section */}
        <div className="grid grid-cols-3 gap-4 mb-12">
          <div className="flex flex-col items-center">
            <div className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF]  rounded-2xl py-2 px-4 text-center w-full">
              <div className="text-white text-1xl font-bold">5000+</div>
            </div>
            <div className="text-white text-sm mt-2 text-[12px]">Account signups</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF]  rounded-2xl py-2 px-4 text-center w-full">
              <div className="text-white text-1xl font-bold">700+</div>
            </div>
            <div className="text-white text-[12px] mt-2  ">Business Signups</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF] rounded-2xl py-2 px-4 text-center w-full">
              <div className="text-white text-1xl font-bold">500,000+</div>
            </div>
            <div className="text-white text-[12px] mt-2">Telegram Subscribers</div>
          </div>
        </div>

        {/* Just Imagine Section */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-2 ">
            <span className="text-white text-[54px]">Just </span>
            <span className="text-[#6968FF] text-[54px]">Imagine</span>
          </h2>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
            <img src={titkokSample}
              style={{ width: 180 }}
            />
            <div className="text-white text-3xl font-bold text-right mr-[10px]">
              your <br /> Brand<br />
              on your<br />
              <span className="text-[#6968FF]">Favorite<br />Tiktoker's</span><br />
              Page.
              <button 
              onClick={()=>{
                window.location.href = "/NotLoggedInCreator"
              }}  
              className="bg-gradient-to-b from-[#6968FF] to-[#9A69FF] text-white text-[19px] font-semibold px-2 mt-[10px] py-4 rounded-xl hover:bg-[#5857DB] transition-colors duration-300 w-30">
                Advertise Now!
              </button>
            </div>

          </div>
        </div>


        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 0 }} >
          <div className="text-left">
            <span className="text-white text-[54px]">Make </span>
            <span className="text-[#6968FF] text-[54px]">Money</span>
          </div>
          <div className="text-left">
            <span className="text-white text-[42px]">from </span>
            <span className="text-white text-[42px]">your </span>
            <span className="text-[#6968FF] text-[42px]">Social</span>
          </div>
          <div className="text-left">
            <span className="text-[#6968FF] text-[42px]">media</span>
            <span className="text-white text-[42px]">pages </span>
          </div>
          <span className="text-left text-white">
            Do you have more than 5000 <br />
            followers on Tiktok? <br />
            With ZeterAds, you can get paid to <br />
            post ads from brands looking for <br />
            creators like you. All you have to do is <br />
            sign up, receive campaigns, and start <br />
            earning by sharing content with your audience.


          </span>

        </div>
        <button className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF] text-white font-semibold px-8 py-3 rounded-lg hover:opacity-90 transition-colors duration-300 mt-[20px]">
          Start Advertising
        </button>
      </div>


      <div className="container mx-auto px-4 py-8">
        <span className="text-[34px] text-white text-left ">Social medias you <br /> can grow on</span>
        <div className="grid grid-cols-3 md:grid-cols-3 gap-8 mt-8">
          <div className="flex flex-col items-center p-6  rounded-lg hover:bg-white/10 transition-duration-300">
            <RiTelegramFill size={64} className="text-[#27A5E5] mb-4" />
            <span className="text-white text-xl">Telegram</span>
          </div>
          <div className="flex flex-col items-center p-6  rounded-lg hover:bg-white/10 transition-duration-300">
            <FaTiktok size={64} className="text-white mb-4" />
            <span className="text-white text-xl">TikTok</span>
          </div>
          <div className="flex flex-col items-center p-6  rounded-lg hover:bg-white/10 transition-duration-300">
            <AiFillYoutube size={64} className="text-[#FF0000] mb-4" />
            <span className="text-white text-xl">YouTube</span>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'right' , marginRight: 10 }}>
        <img src={AIImage} style={{ width: 160, height: 150 }} />
        <div className='text-white text-[27px] leading-thight '>
          Find the best <br />
          <span className="text-[#6968FF] text-[42px]">Influencer</span>
          <p>for yoru </p>
          <span className="text-[#6968FF] text-[42px]">Brand</span>
          <br />
          <span> with </span>
          <span className="text-[#6968FF] text-[42px]">ZeterAI</span>
        </div>

      </div>

      <button
      onClick={()=>{
        window.location.href = "/NotLoggedInCreator"
      }} 
      className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF] text-white font-semibold px-8 py-3 rounded-lg hover:opacity-90 transition-colors duration-300">
        Try ZeterAI
      </button>

      <div>
      <div className="container mx-auto px-4 py-16">
        <span className="text-4xl font-bold text-white mb-12 block text-left">Additional Services</span>
        <div className="flex flex-col space-y-4 mb-12 px-4 text-left">
          <div className="flex items-start space-x-3">
            <span className="text-[#6968FF] text-2xl">•</span>
            <div>
              <h3 className="text-xl text-white">Social Media Management</h3>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-[#6968FF] text-2xl">•</span>
            <div>
              <h3 className="text-xl text-white">Logo and Branding</h3>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-[#6968FF] text-2xl">•</span>
            <div>
              <h3 className="text-xl text-white">Website Development</h3>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-[#6968FF] text-2xl">•</span>
            <div>
              <h3 className="text-xl text-white">Content Creation</h3>
            </div>
          </div>
          <div className="flex items-start space-x-3">
            <span className="text-[#6968FF] text-2xl">•</span>
            <div>
              <h3 className="text-xl text-white">Market Planning</h3>
            </div>
          </div>
        </div>
        
        <div className="text-center mb-16">
          <button className="bg-gradient-to-r from-[#6968FF] to-[#9A69FF] text-white font-semibold px-8 py-3 rounded-lg hover:opacity-90 transition-colors duration-300">
            Contact us
          </button>
        </div>

        <div className="text-center mb-16">
          <h2 className="text-6xl font-bold text-white mb-8">WHY??</h2>
          <p className="text-gray-300 text-lg max-w-3xl mx-auto">
            We built ZeterAds to unlock the untapped potential in Ethiopia's influencer marketing industry, solving issues of accessibility and security. Our platform creates a space where both businesses and influencers can connect, collaborate, and grow together.
          </p>
        </div>
      </div>
        </div>
      <Footer />
    </div>

  );
};

export default HomePage;
