import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Slider, Button } from '@mui/material';
import { useCampaignCreation } from '../../hooks/useCampaignCreation';
import { CreatorFilter } from '../../../../../store/creator/creatorInterface';

interface FilterPopupProps {
    open: boolean;
    onClose: () => void;
}

const FilterPopup: React.FC<FilterPopupProps> = ({ open, onClose }) => {
    const [viewRange, setViewRange] = useState<number[]>([0, 1000000]);
    const [subCountRange, setSubCountRange] = useState<number[]>([0, 1000000]);
    const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);

    const { state } = useCampaignCreation();

    const handleViewRangeChange = (event: Event, newValue: number | number[]) => {
        setViewRange(newValue as number[]);
    };

    const handleSubCountRangeChange = (event: Event, newValue: number | number[]) => {
        setSubCountRange(newValue as number[]);
    };

    const handlePriceRangeChange = (event: Event, newValue: number | number[]) => {
        setPriceRange(newValue as number[]);
    };

    const { updateCreatorFilter, getCreatorList } = useCampaignCreation();

    const handleApplyFilter = () => {
        const filterData: CreatorFilter = {
            name: state.creatorSearch.creatorFilter.name,
            nicheId: state.creatorSearch.creatorFilter.nicheId,
            averageViewRange: [viewRange[0], viewRange[1]],
            subscriberRange: [subCountRange[0], subCountRange[1]]
        }

        updateCreatorFilter(filterData);
        getCreatorList(filterData);
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: '16px',
                    padding: '16px',
                },
            }}
        >
            <DialogTitle className="text-2xl font-bold text-[#090E24]">
                Filter Creators
            </DialogTitle>
            <DialogContent>
                <div className="space-y-8 py-4">
                    {/* Views Range Slider */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-gray-700">Average Views</h3>
                        <div className="px-4">
                            <Slider
                                value={viewRange}
                                onChange={handleViewRangeChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={1000000}
                                step={1000}
                                sx={{
                                    color: '#090E24',
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: '#fff',
                                        border: '2px solid currentColor',
                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0 0 0 8px rgba(9, 14, 36, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        height: 4,
                                    },
                                    '& .MuiSlider-rail': {
                                        height: 4,
                                        opacity: 0.5,
                                        backgroundColor: '#bfbfbf',
                                    },
                                }}
                            />
                            <div className="flex justify-between text-sm text-gray-500">
                                <span>{viewRange[0].toLocaleString()} views</span>
                                <span>{viewRange[1].toLocaleString()} views</span>
                            </div>
                        </div>
                    </div>

                    {/* Subscriber Count Range Slider */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-gray-700">Subscriber Count</h3>
                        <div className="px-4">
                            <Slider
                                value={subCountRange}
                                onChange={handleSubCountRangeChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={1000000}
                                step={1000}
                                sx={{
                                    color: '#090E24',
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: '#fff',
                                        border: '2px solid currentColor',
                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0 0 0 8px rgba(9, 14, 36, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        height: 4,
                                    },
                                    '& .MuiSlider-rail': {
                                        height: 4,
                                        opacity: 0.5,
                                        backgroundColor: '#bfbfbf',
                                    },
                                }}
                            />
                            <div className="flex justify-between text-sm text-gray-500">
                                <span>{subCountRange[0].toLocaleString()} subscribers</span>
                                <span>{subCountRange[1].toLocaleString()} subscribers</span>
                            </div>
                        </div>
                    </div>

                    {/* Price Range Slider */}
                    <div className="space-y-4">
                        <h3 className="text-lg font-medium text-gray-700">Price Range</h3>
                        <div className="px-4">
                            <Slider
                                value={priceRange}
                                onChange={handlePriceRangeChange}
                                valueLabelDisplay="auto"
                                min={0}
                                max={10000}
                                step={100}
                                sx={{
                                    color: '#090E24',
                                    '& .MuiSlider-thumb': {
                                        backgroundColor: '#fff',
                                        border: '2px solid currentColor',
                                        '&:hover, &.Mui-focusVisible': {
                                            boxShadow: '0 0 0 8px rgba(9, 14, 36, 0.16)',
                                        },
                                    },
                                    '& .MuiSlider-track': {
                                        height: 4,
                                    },
                                    '& .MuiSlider-rail': {
                                        height: 4,
                                        opacity: 0.5,
                                        backgroundColor: '#bfbfbf',
                                    },
                                }}
                            />
                            <div className="flex justify-between text-sm text-gray-500">
                                <span>{priceRange[0]} ETB</span>
                                <span>{priceRange[1]} ETB</span>
                            </div>
                        </div>
                    </div>

                    {/* Apply Button */}
                    <div className="flex justify-end pt-4">
                        <Button
                            onClick={handleApplyFilter}
                            variant="contained"
                            sx={{
                                backgroundColor: '#090E24',
                                '&:hover': {
                                    backgroundColor: '#1a237e',
                                },
                                borderRadius: '8px',
                                textTransform: 'none',
                                padding: '8px 24px',
                            }}
                        >
                            Apply Filter
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default FilterPopup;