import { DataGrid } from '@mui/x-data-grid';
import { tableProp } from './prop.table';

export default function Table(dataProp: tableProp) {
  return (
    
    <div style={{  width: '100%',height:'100%' , overflowX: 'scroll'}}>
    
         {dataProp.data.length === 0 ? (
        // <div style={{marginTop:"20px"}}>No data available </div>
        <div style={{ width: '100%', overflowX: 'scroll' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #ddd' }}>
          <thead>
            <tr>
              {dataProp.columns.map((column) => (
                <th key={column.field} style={{fontWeight:"normal", padding:5, fontSize:"15px"}} >
                  {column.headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={dataProp.columns.length} style={{ textAlign: 'center', marginTop: '20px', border: '1px solid #ddd', padding: '50px' }}>
                No data available
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      ) : (
    
      <DataGrid
        rows={dataProp.data}
        
      
        columns={dataProp.columns.map((column) => ({
          ...column,
          headerClassName: 'customHeaderCell',
          headerAlign: 'center', // Add headerAlign property
          align: 'center' // Add align property for consistent alignment
        }))}        
        sx={{
          // '& .MuiDataGrid-cell': {
          //   textAlign:'flex-start',
          //   marginLeft: 5
           
          // },
          // '& .MuiDataGrid-columnHeaders': {
          //   backgroundColor: 'white',
          //   color: '#090d25',
          //   fontSize: '1rem',
          //   textAlign:'center',
          //   display:'flex',
          //   // justifyContent:'center',
          //   flexDirection:'row',
          //   paddingLeft: '20px',
          // },
          // make the height of the cell
          '& .headerCell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center', // Ensure the text inside the header cell is centered
          },
          '& .MuiDataGrid-columnHeaders': {
            display: 'flex',
            // justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: 0, // Ensure no extra padding
          },
          '& .MuiDataGrid-cell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: 0, // Ensure no extra padding
          },
          '& .customHeaderCell': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center', // Center text within the header cell
          },
        }}
        initialState={{
         
          pagination: {
            paginationModel: { page: 0, pageSize: 7 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection={false}
        density='standard'
        rowHeight={65}
      />
      )}
    </div>
  );
}