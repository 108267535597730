import { GridColDef } from "@mui/x-data-grid";
import Table from "../../../component/table/table";
import { tiktok } from "../../../store/tiktok/tiktokInterface";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { updateTiktokDetail } from "../../../store/tiktok/tiktokSlice";

export const TiktokTable  = ({tiktokAccountList }: {tiktokAccountList: tiktok[], columns: GridColDef[]}) => {
    const navigation = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const columns : GridColDef[] = [
        {
            field: 'status', headerName: 'status', flex: 1,
            renderCell: (params: any) => {
              return (
      
                <span style={{ background: '#0A0D25', borderRadius: 10, padding: 5, color: 'white' }}>
                  {params.row.status}
                </span>
              )
            }
          },
          { field: 'accountName', headerName: 'Account Name', flex: 1 }, 
          { field: 'avgView', headerName: 'Average View', flex: 1 },
          { field: 'subCount', headerName: 'Sub Count', flex: 1 },
          { field: 'niche', headerName: 'Niche', flex: 1,
            valueGetter: (params) => params.row.niche?.name
          },
//           { field: 'audienceAge', headerName: 'Age Group', flex: 1 },
         { field: 'rate', headerName: 'Per Post', flex: 1 },
        {
            field: '', headerName: 'action', flex: 1,
            renderCell: (params: any) => {
              return (
                <button style={{ background: '#0A0D25', borderRadius: 10, padding: 5, color: 'white' }}
               onClick={() =>{
            dispatch(updateTiktokDetail(params.row));
               navigation("/dashboard/tiktok/update-tiktok");
               }} 
                >
                  {"Edit / View"}
                </button>
              )
            }
          }
    ];

    return (
        <Table columns={columns} data={tiktokAccountList} /> 
    )
}