import { Creator, telegramPaymentPlans } from '../../../../store/creator/creatorInterface';
import { useCampaignCreation } from '../hooks/useCampaignCreation';

const formatNumber = (num: number): string => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
}

export default function SelectedCreatorsSummary() {
    const { state, nextStep, updateSelectedCreator } = useCampaignCreation();

    const handleCreatorSelect = (creator: Creator) => {
        if (creator.status === 'false') {
            alert('Creator is inactive');
            return;
        }
        const isCreatorSelected = state.creatorSearch.selectedCreators.some(c => c.id === creator.id);
        let newContent;
        if (isCreatorSelected) {
            newContent = state.creatorSearch.selectedCreators.filter(c => c.id !== creator.id);
        } else {
            newContent = [...state.creatorSearch.selectedCreators, creator];
        }
        updateSelectedCreator(newContent);
    };

    if (state.creatorSearch.selectedCreators.length > 0) {
        return (
            <div className="mb-6 p-4 bg-white rounded-xl shadow-sm border border-gray-100 transition-all duration-300 hover:shadow-md">
                <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-4 space-y-4 md:space-y-0">
                    <h2 className="text-lg font-medium text-gray-900 text-left">Selected Creators</h2>
                    <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
                        <div className="flex-1 md:flex-none px-4 py-2 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-lg">
                            <p className="text-xs sm:text-sm text-gray-100">Total Subscribers</p>
                            <p className="text-lg sm:text-xl font-bold text-white">
                                {formatNumber(state.creatorSearch.selectedCreators.reduce((total, creator) => total + creator.subCount, 0))}
                            </p>
                        </div>
                        <div className="flex-1 md:flex-none px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg shadow-lg">
                            <p className="text-xs sm:text-sm text-gray-100">Average Views</p>
                            <p className="text-lg sm:text-xl font-bold text-white">
                                {formatNumber(state.creatorSearch.selectedCreators.reduce((total, creator) => total + (parseInt(creator.avgView) || 0), 0) / (state.creatorSearch.selectedCreators.length || 1))}
                            </p>
                        </div>
                        <div className="flex-1 md:flex-none px-4 py-2 bg-gradient-to-r from-pink-600 to-red-600 rounded-lg shadow-lg">
                            <p className="text-xs sm:text-sm text-gray-100">Total Campaign Cost</p>
                            <p className="text-lg sm:text-xl font-bold text-white">
                                {formatNumber(state.creatorSearch.selectedCreators.reduce((total, creator: Creator) => {
                                    if (creator.telegramPaymentPlans) {
                                        // For Telegram creators, use pin-based pricing
                                        const selectedPlan = creator.telegramPaymentPlans.find((plan: telegramPaymentPlans) => plan.days === (creator?.campaignDays || 1));
                                        return total + (creator.isPin ? (selectedPlan?.with_pin || 0) : (selectedPlan?.with_out_pin || 0));
                                    }
                                    // For non-Telegram creators, use regular rate
                                    return total + (creator.rate || 0);
                                }, 0))} br
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap gap-2 text-left">
                    {state.creatorSearch.selectedCreators.map((creator: Creator) => (
                        <div
                            key={creator.id}
                            className="flex items-center space-x-2 bg-gray-50 px-3 py-2 rounded-full shadow-sm hover:bg-gray-100 transition-colors duration-200"
                        >
                            <img
                                src={creator.creator ? creator.creator.identificationImage || "" : "https:/sdlfkjs.com/sdf"}
                                alt=""
                                className="h-6 w-6 rounded-full"
                            />
                            <span className="text-sm text-gray-700">{creator.accountName}</span>
                            <div className="flex items-center space-x-2">
                                <span className="text-xs text-purple-600 font-medium">{creator.rate} br</span>
                                <button
                                    onClick={() => handleCreatorSelect(creator)}
                                    className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                                >
                                    ×
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                {state.creatorSearch.selectedCreators.length > 0 && (
                    <div className="mt-6 flex justify-center w-full">
                        <button
                            className="w-full md:w-auto px-8 py-3 text-white font-semibold rounded-lg
                        bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600
                        hover:from-indigo-700 hover:via-purple-700 hover:to-pink-700
                        transform hover:scale-105 transition-all duration-300
                        shadow-lg hover:shadow-xl
                        focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-opacity-50"
                            onClick={() => {
                                nextStep();
                            }}
                        >
                            Start Campaign
                        </button>
                    </div>
                )}
            </div>
        );
    } else {
        return <></>;
    }
}