import { configureStore } from "@reduxjs/toolkit";
import dashboard from "./dashboard/dashboardSlice";
import creator from "./creator/creator";
import campaign from "./campaigns/campaignSlice";
import zeterState from "./zeterState/zeterStateSlice";
import tiktok from "./tiktok/tiktokSlice";
import lookUp from "./lookup/lookupSlice";
import zeterAi from "./zeterAI/zeterAiSlice";

export const store = configureStore({
    reducer: {
        zeterState,        
        dashboard,
        creator,
        campaign,
        tiktok,
        lookUp,
        zeterAi
    },
});

export type AppDispatch = typeof store.dispatch;