import { createSlice } from "@reduxjs/toolkit";
import { zeterAiState } from "./interfaceZeterAi";


const initialState : zeterAiState = {
    context: [],
    response: []
}

const zeterAiSlice = createSlice({
    name:"zeterAi",
    initialState: initialState,
    reducers:{
        setContext:(state, action)=>{
            state.context = action.payload
        },
        setResponse:(state, action)=>{
            state.response = action.payload
        }
    }
})

export const { setContext , setResponse } = zeterAiSlice.actions;
export default zeterAiSlice.reducer;