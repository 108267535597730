import { MdCampaign, MdOutlineAttachMoney } from "react-icons/md";
import SingleCardContent from "../../../../component/singleCardContent/SingleCardContent";
import { orderProp } from "../prop.order";
import { GiForwardField, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { GrFormView } from "react-icons/gr";
import "./acceptedOrder.style.css";

import { useState, useEffect } from "react";
import { getCampaignDetail } from "../../../dashboard_business/campaign/controller.campaign";
import { getCreatorCampaignDetail } from "../controller.orders";
import { Timer, UploadCloud } from "lucide-react";
import { addCommas, getCampaignTelgramDetail, getVideoBugdget, getVideoDetail, percentageCalcualtor } from "./AcceptedOrder.controller";
import { CircularProgress } from "@mui/material";
import Orders from "../orders";
import OrderStatus from "../order.status";
import ProgressBar from "../../../../component/progressBar/progressBar";
import { AiFillDollarCircle, AiOutlineFundView } from "react-icons/ai";
import { RiLuggageDepositFill } from "react-icons/ri";
import ChatBox from "../../../../component/chat_box/chatBox";
import progressStatus from "../../../../component/assets/images/progressStatus.png";
import { getNextPaymentDate } from "../video_uploader/videoUploader.controller";
import CountDownTimer from "./countDownTimer";

export default function AcceptedOrder(order: any) {

    const [campaignDetail, setCampaignDetail] = useState<any>({});
    const [videoDetail, setVideoDetails] = useState<any>({});
    const [loadingVideoDetail, setLoadingVideoDetail] = useState(false);
    const [progress, setProgress] = useState(0);
    const [actualView, setAcutalView] = useState(0);
    const [shareCount, setShareCount] = useState(0);

    const [userId, setUserId] = useState<number>(0);
    const [targetDate, setTargetDate] = useState<any>();







    useEffect(() => {
        // alert(JSON.stringify(order))
        
        // get user id from local storage
        let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
        setUserId(userInfo.body.id);
        let actual_view = 0;
        if (order.order.campaign.socialMediaId === 1) {

            setAcutalView(order.order.campaign.creatorcontentforacampaigns[0]?.viewCount);


        } else if (order.order.campaign.socialMediaId == 3) {
            setLoadingVideoDetail(true);

            getCampaignTelgramDetail(order.order.campaign.id).then((res: any) => {
                
                setVideoDetails(res);
                setLoadingVideoDetail(false);
                actual_view = res.data[0].views;
                setShareCount(res.data[0].forwards);
                setAcutalView(actual_view);
            }
            ).catch((err: any) => {
                
            })
        } else {
        }
        
        //  getVideoDetail("https://www.youtube.com/watch?v=OS1yRYsXddU").then((res:any)=>{
        //         
        //         setVideoDetails(res);
        //         setLoadingVideoDetail(false);
        //     }
        //     ).catch((err: any)=>{
        //         
        //     })
        getNextPaymentDate().then((res: any) => {
            
            setTargetDate(res.current_month.payment_date)
        }
        ).catch((err: any) => {
           
        });

    }, [])

    useEffect(() => {
        setProgress(percentageCalcualtor(order?.order.totalView, actualView));

    }, [actualView])


    return (
        <div className="singleOrder" style={{ zIndex: 0 }} >
            <div style={{ width: '80%', marginLeft: 15, height: 150, backgroundColor: 'transparent' }}>
                <OrderStatus status={"Progress"} />
            </div>
            <span style={{ height: 5 }}>

            </span>
            <div className="hoverCard" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'green' }}>
                    <h3 style={{ color: 'green' }}>{order?.order.totalView.toLocaleString()}</h3>
                    <AiOutlineFundView size={50} />
                </div>
                <p>Expected Views</p>
            </div>

            <div className="hoverCard">

                {/* <h3>{addCommas(parseInt(videoDetail?.data?.items[0].statistics.viewCount))}</h3> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'green' }}>

                    <h3 style={{ color: 'green' }}>{addCommas(actualView || 0)}</h3>
                    <AiOutlineFundView size={50} />
                </div>
                <p>Current View</p>

            </div>

            <div className="hoverCard">
                <h3 style={{ color: '#5e0000' }}>{order.order.totalPrice.toLocaleString()} Birr</h3>
                <p>Estimated Earnings </p>
            </div>


            <div className="hoverCard" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                {order.order.status == "progress" && progress < 80 ?
                    <img src={progressStatus} alt="status" width={200} style={{ marginBottom: 15, borderRadius: 5 }} />
                    : <h3>Completed</h3>
                }

                <p>Status</p>
            </div>


            <div className="hoverCard">
                <h3>{progress >= 80 ? "Eligible" : "Ineligible"}</h3>
                <p>Payment Status</p>
            </div>


            <div className="hoverCard" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', color: 'green' }}>

                {targetDate &&
                
                    <CountDownTimer targetDates={new Date(targetDate)} />
                }



                </div>
                <p>Time Left For Payment</p>

            </div>


            {/* <div className="hoverCard">
        {loadingVideoDetail? <CircularProgress /> :
        <>
        <h3>{addCommas(parseInt(videoDetail?.data?.items[0].statistics.likeCount))}</h3>
        <p>Like Count</p>
        </>
        }
        
       </div>
       <div className="hoverCard">
        {loadingVideoDetail? <CircularProgress /> :
        <>
        <h3>{addCommas(parseInt(videoDetail?.data?.items[0].statistics.commentCount))}</h3>
        <p>Comment Count</p>
        </>
        }
        
       </div> */}
            <div style={{ width: '63%', marginLeft: 15, height: 100, backgroundColor: 'transparent' }}>
                <ProgressBar progress={progress} progressBarWidth={850} progressBarHeight={60} />
            </div>
            {/* <div className="progressBarWrapper">
       <div className="progressBar">
     <h3>{percentageCalcualtor(order?.order.ExpectedView,videoDetail?.data?.items[0].statistics.viewCount) +"%"}</h3>
       </div>
</div> */}
            <ChatBox campaignId={order.order.campaign.id} userId={userId} />
        </div>

    );
}