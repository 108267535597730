import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store/RootState";
import { useEffect } from "react";
import { CampaignState } from "../../../../store/campaigns/campaignInterface";
import { getCampaignInfoForCreator } from "../../../../store/campaigns/campaignThunk";
import { AppDispatch } from "../../../../store";
import { ZeterState } from "../../../../store/zeterState/zeterStateSlice";
import type { Campaign } from "../../../../store/campaigns/campaignInterface";

interface useOrdersReturnType extends CampaignState, ZeterState {
    filteredCampaignList: (filterKey: string) => Campaign[]
}

export const useOrders = (): useOrdersReturnType => {
    const campaign = useSelector((state: RootState) => state.campaign);
    const status = useSelector((state: RootState) => state.zeterState);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(getCampaignInfoForCreator());
    }, [dispatch])

    const filteredCampaignList = (filterKey: string): Campaign[] => {
        if (!campaign.campaignList) return [];
        if (filterKey === "All") return campaign.campaignList;
        const stuff = campaign.campaignList.filter((campaign: Campaign) => {
            if (campaign.creatorinacampaigns[0].status.toLowerCase() === filterKey.toLowerCase()) {
                return campaign;
            }
        }
        );
        return stuff;
    };

    return {
        ...campaign,
        ...status,
        filteredCampaignList,
    };
}