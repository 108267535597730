import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getAgeGroup, GetColumns , getNicheList } from "./controller.tiktok";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTiktok } from "./hooks/useTiktok";
import { TiktokTable } from "./TiktokTable";

export default function Tiktok() {

  const { tiktokAccountList, loading } = useTiktok();
  const isMobile = window.innerWidth < 768;
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [refershAccount, setRefershAccount] = useState<boolean>(false);
  const [updatedContent, setUpdateContent] = useState<any>({});
  const [nicheList, setNicheList] = useState<any[]>([]);
  const [ageGroup, setAgeGroup] = useState<any[]>([]);

  const navigation = useNavigate();
  
   let tiktok_list = {}; 

  useEffect(() => {
  }, [refershAccount, updatedContent, nicheList, ageGroup])

  useEffect(() => {
    async function getLookup() {
      let nicheList: any[] = await getNicheList() as any[];
      let ageGroup: any[] = await getAgeGroup() as any[];
      //setNicheList(nicheList);
      //setAgeGroup(ageGroup);
    }
    getLookup();
  }, [])

  return (
    <div className="orderScreen">
      <div className="campaignList-header" style={{ marginBottom: -14 }}>
        <h1 style={{ color: 'white', fontWeight: 'bolder' }}>Connected Accounts</h1>
        <button onClick={() => {
          navigation("/dashboard/tiktok/add-tiktok-channel")
        }} style={{ background: 'black', color:'white', padding: 5, borderRadius: 10 }}>{"Add New Channel"}</button>
      </div>
        <div data-testid="table-container" style={{...styles.tableContainer, width: isMobile ? '100%' : '110%'}}>
        {loading ?  <CircularProgress /> :
          <TiktokTable tiktokAccountList={tiktokAccountList} columns={columns} />
        }
      </div>
    </div>
  );
}

const styles = {
  orderScreen: {
    height: 900,
    padding: '20px 30px'
  },
  header: {
    borderRadius: 12,
    width: '100%',
    justifyContent: 'space-between',
    gap: '20px',
    display: 'flex'
  },
  optionText: {
    fontWeight: 500
  },
  tableContainer: {
    margin: 0,
    height: 620,
    background: '#fff',
    borderRadius: 12,
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    padding: '24px 20px',
    overflow: 'scroll'
  }
}