import { useEffect, useState } from 'react';
import { RiTelegramFill } from 'react-icons/ri';
import { MdBook, MdOutlineLocationCity } from 'react-icons/md';
import { GiModernCity } from 'react-icons/gi';
import { AiOutlineMail, AiOutlineNumber } from 'react-icons/ai';
import { getBusinessDetails, getConnectionTokenBus } from './controller.profile';
import axiosInstance from '../../../axiosConfig';
import { url } from '../../../util/constant';
import { photoUpload } from '../../create_account/controller.create_account';
import ConnectTelegram from "../../dashboard/telegram/connetTelegram/connectTelegram";

export default function BusinessProfileNew() {
    const [businessDetail, setBusinessDetail] = useState<any>({});
    const [telegramConnectToken, setTelegramConnectToken] = useState<string>("");

    useEffect(() => {
        getBusinessDetails().then((res: any) => {
            setBusinessDetail(res);
        }).catch((err) => {
            console.error(err);
        })
    }, []);

    const handelChange = (name: string, e: any) => {
        setBusinessDetail((prevState: any) => ({
            ...prevState,
            [name]: e.target.value,
        }));
    }

    return (
        <div className="w-full min-h-screen bg-gray-50 pb-16 md:pb-8">
            <div className="w-full p-4 md:p-8">
                {telegramConnectToken && 
                    <ConnectTelegram 
                        closeTelegram={() => setTelegramConnectToken("")}
                        token={telegramConnectToken}
                    />
                }

                <div className="w-full grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-8">
                    {/* Profile Card */}
                    <div className="lg:col-span-1 bg-white rounded-xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl h-fit">
                        <div className="h-48 bg-indigo-900 rounded-lg flex items-center justify-center mb-4">
                            <span className="text-7xl text-white">
                                {businessDetail.name ? businessDetail.name[0] : ""}
                            </span>
                        </div>
                        <h1 className="text-xl font-bold text-gray-800">{businessDetail.name}</h1>
                        <p className="text-sm text-gray-500 mb-4">{businessDetail.email}</p>
                        
                        {/* Telegram Connection */}
                        <div className="bg-gray-100 p-4 rounded-lg flex items-center justify-between">
                            <div className="flex items-center space-x-2 text-blue-500">
                                <RiTelegramFill className="text-lg" />
                                <span className="text-sm">Telegram</span>
                            </div>
                            <button
                                onClick={() => {
                                    if (!businessDetail.telegramId) {
                                        getConnectionTokenBus().then((res: any) => {
                                            setTelegramConnectToken(res.token);
                                        }).catch(console.error);
                                    }
                                }}
                                className="px-4 py-2 bg-white rounded-md text-blue-500 hover:bg-gray-50 transition-colors"
                            >
                                {businessDetail.telegramId ? "Connected" : "Connect"}
                            </button>
                        </div>
                    </div>

                    {/* Main Content Area */}
                    <div className="lg:col-span-2 2xl:col-span-3 space-y-8">
                        {/* Location Information */}
                        <div className="w-full bg-white rounded-xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
                            <h2 className="text-lg font-bold text-gray-800 mb-6">Location Information</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <GiModernCity className="text-xl text-indigo-900" />
                                        <span className="text-sm text-gray-600">Location</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={businessDetail.address || ''}
                                        onChange={(e) => handelChange("address", e)}
                                        className="border-none bg-gray-50 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none w-64"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <AiOutlineMail className="text-xl text-indigo-900" />
                                        <span className="text-sm text-gray-600">Email</span>
                                    </div>
                                    <input
                                        type="email"
                                        value={businessDetail.email || ''}
                                        onChange={(e) => handelChange("email", e)}
                                        className="border-none bg-gray-50 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none w-64"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Legal Information */}
                        <div className="w-full bg-white rounded-xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
                            <h2 className="text-lg font-bold text-gray-800 mb-6">Legal Information</h2>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <AiOutlineNumber className="text-xl text-indigo-900" />
                                        <span className="text-sm text-gray-600">TIN Number</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={businessDetail.tinNumber || ''}
                                        onChange={(e) => handelChange("tinNumber", e)}
                                        className="border-none bg-gray-50 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none w-64"
                                    />
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <AiOutlineNumber className="text-xl text-indigo-900" />
                                        <span className="text-sm text-gray-600">VAT Number</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={businessDetail.vatNumber || ''}
                                        onChange={(e) => handelChange("vatNumber", e)}
                                        className="border-none bg-gray-50 rounded-lg px-4 py-2 focus:ring-2 focus:ring-blue-500 outline-none w-64"
                                    />
                                </div>
                                <div className="col-span-full">
                                    <div className="flex items-center space-x-2 mb-2">
                                        <MdBook className="text-xl text-indigo-900" />
                                        <span 
                                            className="text-sm text-gray-600 cursor-pointer hover:text-blue-500"
                                            onClick={() => businessDetail.businessLicense && window.open(businessDetail.businessLicense, '_blank')}
                                        >
                                            License
                                        </span>
                                    </div>
                                    <input
                                        type="file"
                                        onChange={(e: any) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                photoUpload(file).then((res: any) => {
                                                    setBusinessDetail((prev:any) => ({
                                                        ...prev,
                                                        businessLicense: res.data.file
                                                    }));
                                                }).catch(console.error);
                                            }
                                        }}
                                        className="w-full text-gray-600 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Save Button */}
                        <button
                            onClick={() => {
                                const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
                                axiosInstance.put(
                                    `${url}/business/account/update_account`,
                                    businessDetail,
                                    {
                                        headers: { Authorization: `Bearer ${userInfo}` }
                                    }
                                ).then((res) => {
                                    if(res.data.status === "success"){
                                        alert("Updated Successfully");
                                    } else {
                                        alert(res.data.message);
                                    }
                                }).catch(() => {
                                    alert("Failed to update");
                                });
                            }}
                            className="w-full md:w-auto px-8 py-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300 font-medium focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 mb-8 md:mb-0"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}