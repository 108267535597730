import React from 'react';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaTelegramPlane, FaTiktok } from 'react-icons/fa';
import { useCampaignCreation } from '../hooks/useCampaignCreation';

const SocialMediaSelection: React.FC = () => {
  const { updateSocialMedia } = useCampaignCreation();

  return (
    <div className="fadeIn">
      <p className='question'> Select Social Media</p>
      <div className='youTubeTelegram'>
        <button 
          className="youTubeButton" 
          onClick={() => updateSocialMedia("youtube")} 
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <IoLogoYoutube size={50} style={{ marginRight: "5px" }} />
          </div>
          <div>YouTube</div>
        </button>
        <button 
          className="telegramButton" 
          onClick={() => updateSocialMedia("telegram")} 
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaTelegramPlane size={50} style={{ marginRight: "5px" }} />
          </div>
          <div>Telegram</div>
        </button>
      </div>
      <button 
        className="tikTokButton" 
        onClick={() => updateSocialMedia("tiktok")} 
        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <FaTiktok size={50} style={{ marginRight: "5px" }} />
        </div>
        <div>TikTok</div>
      </button>
    </div>
  );
};

export default SocialMediaSelection;