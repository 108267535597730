import styled from 'styled-components';

const MobilePage = styled.div`
  background: linear-gradient(180deg,rgb(47, 57, 155) 0%, #019267 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
`;

const HeaderTitle = styled.h1`
  color: white;
  font-size: 24px;
  margin-bottom: 30px;
`;

const MobileForm = styled.form`
  background: white;
  border-radius: 20px;
  padding: 30px 20px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;

  label {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    align-self: flex-start;
  }
`;

const CustomInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
    align-self: flex-start;
  &::placeholder {
    color: #999;
  }

  &:focus {
    border-color: #00C897;
  }
`;

const LoginButton = styled.button`
  background:rgba(0, 43, 200, 0.58);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
    width: 98%;
  &:hover {
    background: #019267;
  }
`;

export default function MobileLogin(){
  return(
    <MobilePage>
      <div style={{textAlign: 'center', marginBottom: '40px'}}>
        <h1 style={{color: 'white', fontSize: '28px', marginBottom: '10px'}}>Hello.</h1>
        {/* <h2 style={{color: 'white', fontSize: '24px'}}>Login to your account</h2> */}
      </div>
      
      <MobileForm>
        <label>Email</label>
        <CustomInput type="email" placeholder="Enter your email" />
        <label>Password</label>
        <CustomInput type="password" placeholder="Enter your password" />
        
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <a href="/forget-password" style={{color: '#00C897', fontSize: '14px', textDecoration: 'none'}}>Forgot Password?</a>
          <a href="/signup" style={{color: '#00C897', fontSize: '14px', textDecoration: 'none'}}>Sign Up</a>
        </div>
        
        <LoginButton>Login</LoginButton>
      </MobileForm>
    </MobilePage>
  );
}

