import CampaignDescription from "../components/campaignDescription";
import MediaUpload from "../components/mediaUpload";
import { useCampaignCreation } from "../hooks/useCampaignCreation"

export default function PrivateCampaignFlow() {
    const { state, nextStep, previousStep, buildFinalJson, CreateCampaign } = useCampaignCreation();
    return (
        <div>

            {
                state.currentStep === 1 && !state.isPublic &&
                <div style={style.centeritation}>
                    <CampaignDescription />
                </div>
            }

            {
                state.currentStep === 2 && !state.isPublic &&
                <div style={style.centeritation}>
                    <MediaUpload />
                </div>
            }

            {
                state.currentStep !== 0 && state.currentStep !== 5 && !state.isPublic &&
                <div className="flex gap-4 mt-6 mb-[100px] justify-center">
                    <button
                        onClick={() => previousStep()}
                        className="px-6 py-3 bg-gray-50 text-gray-700 rounded-lg font-medium transition-all hover:bg-gray-100 hover:shadow-md active:transform active:scale-95"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => {
                            if (state.currentStep === 2) {
                                CreateCampaign();
                            } else {
                                nextStep();
                            }

                        }}
                        className="px-6 py-3 bg-[#090D25] text-white rounded-lg font-medium transition-all hover:bg-[#1a1f3d] hover:shadow-md active:transform active:scale-95"
                    >
                        {state.currentStep === 2 ? "Create Campaign" : "Next"}
                    </button>
                </div>
            }
        </div>
    )
}

const style = {
    centeritation: {
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: '65vh',
        overflow: 'scroll'
    }
};