import styled from 'styled-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface BuildingCard {
  id: string;
  title: string;
  location: string;
  description: string;
  imageUrl: string;
  stats: {
    footTraffic: number;
    carTraffic: number;
    areaPopulation: number;
  };
}

export default function Building() {
  const navigate = useNavigate();
  const [buildings] = useState<BuildingCard[]>([
    {
      id: '1',
      title: 'Downtown Business Center',
      location: 'Bole, Addis Ababa',
      description: 'Prime location in the heart of the business district with high visibility.',
      imageUrl: 'https://images.unsplash.com/photo-1486406146926-c627a92ad1ab',
      stats: {
        footTraffic: 5000,
        carTraffic: 3000,
        areaPopulation: 25000
      }
    },
    {
      id: '2',
      title: 'Millennium Plaza',
      location: 'Kazanchis, Addis Ababa',
      description: 'Modern commercial complex with premium advertising spaces.',
      imageUrl: 'https://images.unsplash.com/photo-1554469384-e58fac16e23a',
      stats: {
        footTraffic: 4000,
        carTraffic: 2500,
        areaPopulation: 20000
      }
    },
    {
      id: '3',
      title: 'Unity Park Tower',
      location: '4 Kilo, Addis Ababa',
      description: 'Iconic building with high-impact advertising opportunities.',
      imageUrl: 'https://images.unsplash.com/photo-1577985043696-8bd54d9f093f',
      stats: {
        footTraffic: 3500,
        carTraffic: 2000,
        areaPopulation: 18000
      }
    },
  {
      id: '3',
      title: 'Unity Park Tower',
      location: '4 Kilo, Addis Ababa',
      description: 'Iconic building with high-impact advertising opportunities.',
      imageUrl: 'https://images.unsplash.com/photo-1577985043696-8bd54d9f093f',
      stats: {
        footTraffic: 3500,
        carTraffic: 2000,
        areaPopulation: 18000
      }
    }
  ]);

  const handleBuildingClick = (buildingId: string) => {
    navigate(`/dashboard/business/buildingDetail`);
  };

  return (
    <BuildingContainer>
      <BuildingGrid>
        {buildings.map((building) => (
          <BuildingCardComponent 
            key={building.id}
            onClick={() => handleBuildingClick(building.id)}
          >
            <CardImage src={building.imageUrl} alt={building.title} />
            <CardContent>
              <CardTitle>{building.title}</CardTitle>
              <CardLocation>{building.location}</CardLocation>
              <CardDescription>{building.description}</CardDescription>
              <StatsContainer>
                <StatItem>
                  <StatLabel>Foot Traffic</StatLabel>
                  <StatValue>{building.stats.footTraffic}/day</StatValue>
                </StatItem>
                <StatItem>
                  <StatLabel>Car Traffic</StatLabel>
                  <StatValue>{building.stats.carTraffic}/day</StatValue>
                </StatItem>
                <StatItem>
                  <StatLabel>Area Population</StatLabel>
                  <StatValue>{building.stats.areaPopulation}</StatValue>
                </StatItem>
              </StatsContainer>
            </CardContent>
          </BuildingCardComponent>
        ))}
      </BuildingGrid>
    </BuildingContainer>
  );
}

const BuildingContainer = styled.div`
  padding: 2rem;
  width: 100%;
`;

const BuildingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
`;

const BuildingCardComponent = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 1.5rem;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  color: #2B3674;
`;

const CardLocation = styled.p`
  font-size: 1rem;
  color: #707EAE;
  margin: 0 0 1rem 0;
`;

const CardDescription = styled.p`
  font-size: 0.9rem;
  color: #707EAE;
  margin: 0 0 1.5rem 0;
  line-height: 1.5;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #E0E5F2;
`;

const StatItem = styled.div`
  text-align: center;
`;

const StatLabel = styled.p`
  font-size: 0.8rem;
  color: #707EAE;
  margin: 0 0 0.25rem 0;
`;

const StatValue = styled.p`
  font-size: 1rem;
  color: #2B3674;
  font-weight: 600;
  margin: 0;
`;