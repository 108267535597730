import { MdCampaign, MdOutlineAttachMoney } from "react-icons/md";
import SingleCardContent from "../../../component/singleCardContent/SingleCardContent";
import { orderProp } from "./prop/prop.order";
import { GiTakeMyMoney } from "react-icons/gi";
import { GrFormView } from "react-icons/gr";

import "./styles/style.mobile.orders.css";
import { useEffect, useState } from "react";
import { addPayment, calculateBudget, calculateTotalProgress, getCampaignDetail } from "./controller.campaign";
import { Button, Tab } from "@mui/material";
import Table from "../../../component/table/table";
import { creatorColumnData, creatorColumnDataTelegram, creatorColumnDataTelegramPublic, creatorColumnDataYoutubePublic } from "./data/column.creators";
import { creatorRowData, getCreatorRowData } from "./data/data.creators";
import CampaignDetailStatus from './campaignDetailStatusHeader';
import { RiWalletFill } from "react-icons/ri";
import ProgressBar from "../../../component/progressBar/progressBar";
import axios from "axios";
import { url } from "../../../util/constant";
import PaymentSection from "../createCampaign/components/PaymentSection";
import TotalPaymentSection from "./payment_section/payment_section";
import axiosInstance from "../../../axiosConfig";
import ChatBox from "../../../component/chat_box/chatBox";
import CountDownTimer from "../../dashboard/orders/accepted_orders/countDownTimer";
import { SingleCardContentProps } from "../../../component/singleCardContent/prop.singleCardContent";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function DetailCampaign(order: orderProp) {
  const [campaignDetail, setCampaignDetail] = useState<any>({});
  const [creatorData, setCreatorData] = useState<any>([]);
  const [status, setStatus] = useState<string>("");
  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [paymentInfo, setPaymentInfo] = useState<any>({});
  const [userId, setUserId] = useState<number>(0);
  const [listOfAcceptedCreators, setListOfAcceptedCreators] = useState<any>([]);
  const [refersh,setRefersh] = useState<boolean>(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    // alert("THIS IS A TEST: " + JSON.stringify(listOfAcceptedCreators));
  }, [listOfAcceptedCreators]);

  useEffect(() => {

    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    setUserId(userInfo.body.id);

    getCampaignDetail(order.id)
      .then((res: any) => {
        setCampaignDetail(res[0]);
        
      })
      .catch((err) => {
        
      });

    function getCampaignStatus() {
      var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

      return new Promise((resolve, reject) => {
        axiosInstance.get(`${url}/campaign/campaign/status/${order.id}`, {
          headers: {
            "Authorization": "Bearer " + userInfo
          }
        })
          .then(function (response) {

            resolve(response);
          })
          .catch(function (error) {
            
            resolve(error);
          });
      });

    }

    getCampaignStatus().then((res: any) => {
      

      setStatus(res.data.status);
    }
    );


    async function getCreatorList() {
      var creatorD: any[] = await getCreatorRowData(order.id) as any[];
      // add an id to the data using index of the array
      creatorD.map((data: any, index: number) => {
        data["id"] = index + 1;
        data["Detail"] =
          data.Status === "Requested"
            ? `${index + 1}.false`
            : `${index + 1}.true  `;
      });

      
      let totalPrice = creatorD?.reduce((a: number, b: any) => (a||0) + (b?.TotalPrice || 0)) || 0;
      
      setTotalBudget(totalPrice.TotalPrice)
      creatorD.forEach((data: any, index: number) => {
        creatorD[index]["CampaignId"] = order.id;
      })
      setCreatorData(creatorD);
    }

    calculateBudget(order.id).then((res: any) => {
      
      // setTotalBudget(res[0].Budget);
    }).catch((err) => {

    })

    getCreatorList();
  }, []);

  useEffect(() => {

    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");
    setUserId(userInfo.body.id);

    getCampaignDetail(order.id)
      .then((res: any) => {
        setCampaignDetail(res[0]);
        
      })
      .catch((err) => {
        
      });

    function getCampaignStatus() {
      var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];;

      return new Promise((resolve, reject) => {
        axiosInstance.get(`${url}/campaign/campaign/status/${order.id}`, {
          headers: {
            "Authorization": "Bearer " + userInfo
          }
        })
          .then(function (response) {

            resolve(response);
          })
          .catch(function (error) {
            
            resolve(error);
          });
      });

    }

    getCampaignStatus().then((res: any) => {
      

      setStatus(res.data.status);
    }
    );


    async function getCreatorList() {
      var creatorD: any[] = await getCreatorRowData(order.id) as any[];
      // add an id to the data using index of the array
      creatorD.map((data: any, index: number) => {
        data["id"] = index + 1;
        data["Detail"] =
          data.Status === "Requested"
            ? `${index + 1}.false`
            : `${index + 1}.true  `;
      });

      
      let totalPrice = creatorD?.reduce((a:number, b: any) => (a || 0 )+ (b?.TotalPrice || 0)) || 0;
      
      setTotalBudget(totalPrice.TotalPrice)
      creatorD.forEach((data: any, index: number) => {
        creatorD[index]["CampaignId"] = order.id;
      })
      setCreatorData(creatorD);
    }

    calculateBudget(order.id).then((res: any) => {
      
      // setTotalBudget(res[0].Budget);
    }).catch((err) => {

    })

    getCreatorList();
  }, [refersh]);


  return (
    <div className={`flex flex-col mb-10 w-full bg-[#F8F8F8] flex-wrap ${isMobile ? 'px-4' : ''}`}>
      <CampaignDetailStatus status={status} />
      <span className={`${status == "Payment_Verification" ? 'h-[50px]' : 'h-[130px]'} w-full flex flex-row justify-center`}></span>
      {status == "Payment_Verification" ? (
        <div className={`flex flex-col ${isMobile ? 'w-full' : 'w-[85%]'} mx-auto`}>
          <TotalPaymentSection setPaymentInfo={setPaymentInfo} campaignId={3} totalPrice={totalBudget} />
          <Button
            variant="contained"
            color="primary"
            className={`${isMobile ? 'w-full' : 'w-[200px]'} mt-5 bg-[#090D25]`}
            onClick={() => {
              let paymentInfoBody: any = {
                campaignId: order.id,
                paymentStatus: "Payment_Verified",
                paymentAmount: totalBudget,
                transactionNumber: paymentInfo.transactionNumber,
                paymentDate: new Date().toISOString().slice(0, 10)
              };
              addPayment(paymentInfoBody).then((res) => {
                window.location.reload();
              }).catch((err) => {});
            }}
          >
            Verify Payment
          </Button>
        </div>
      ) : (
        <>
          {status == "Payment_Pending" && (
            <h1 className={`${isMobile ? 'text-[32px] text-center' : 'text-[55px]'}`}>Your Payment is being Verified</h1>
          )}
          <div className="campaignDetails bg-transparent">
            <div className={`order_video_section ${isMobile ? 'w-full px-4' : ''}`}>
              {campaignDetail?.shortVideoUrl ? (
                campaignDetail?.shortVideoUrl.includes("youtube.com") ? (
                  <iframe
                    className={`${isMobile ? 'w-full h-[300px]' : 'w-[83%] h-[615px]'} border-none`}
                    src={campaignDetail?.shortVideoUrl
                      .replace("http://", "https://")
                      .replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                ) : (
                  <img
                    src={campaignDetail?.shortVideoUrl}
                    alt="Campaign Detail"
                    className="w-full h-auto block"
                  />
                )
              ) : null}
              <div className="h-[10px]"></div>
              <h2 className={`${isMobile ? 'text-xl mb-2' : ''}`}>Campaign Description</h2>
              <h3 className={`text-start ${isMobile ? 'text-sm' : 'text-[15px]'}`}>{campaignDetail?.contentDescription}</h3>
              <h2 className={`${isMobile ? 'text-xl mb-2 mt-4' : ''}`}>Post Description</h2>
              <h3 className={`text-start ${isMobile ? 'text-sm' : 'text-[15px]'}`}>{campaignDetail?.postDescription}</h3>
            </div>
            <div className={`video_detail ${isMobile ? 'flex-col gap-4' : ''}`}>
              <div className="hoverCard card-campaign-detail flex justify-center items-center">
                <RiWalletFill className={`text-[#090D25] ${isMobile ? 'text-[40px]' : 'text-[60px]'}`} />
                <h1 className={isMobile ? 'text-lg' : ''}>{status == "Payment_Verification" || status == "production" ? parseInt(totalBudget ? totalBudget.toString() : "0").toLocaleString() + " Birr" : "Required Budget will be available"}</h1>
              </div>
              <div className="hoverCard card-campaign-detail">
                <ProgressBar progress={calculateTotalProgress(200, 0)} progressBarWidth={isMobile ? 150 : 190} progressBarHeight={isMobile ? 40 : 50} />
                <h1 className={isMobile ? 'text-lg' : ''}>Campaign Progress</h1>
              </div>
              <div className="hoverCard card-campaign-detail">
                <h1 className={isMobile ? 'text-lg' : ''}>Links</h1>
                <div className="flex flex-col items-start">
                  {campaignDetail?.socialMediaLinks && campaignDetail?.socialMediaLinks.length > 0 && campaignDetail?.socialMediaLinks.map((website: any, index: number) => (
                    <div key={index} className={`flex justify-around ${isMobile ? 'text-sm' : ''}`}>
                      <p>{website.website}: </p>
                      <a href={website.url} className="text-lightblue ml-[10px]" target="_blank">{website.url}</a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`${isMobile ? 'w-full px-4' : 'w-[85%]'} self-center`}>
            <div className="h-[50px] flex flex-row items-start justify-start overflow-y-scroll rounded-[5px]">
              {listOfAcceptedCreators.length > 0 && listOfAcceptedCreators.map((creator: any, index: number) => (
                <div key={index} className={`bg-white rounded-[10px] h-[40px] p-[5px] ${isMobile ? 'min-w-[150px]' : 'min-w-[180px]'} m-[5px] flex justify-center items-center`}>
                  <h1 className={isMobile ? 'text-sm' : ''}>{creator.AccountName}</h1>
                  <AiOutlineCloseCircle
                    className="text-red-500 text-[20px] ml-[5px] cursor-pointer"
                    onClick={() => {
                      let newList = listOfAcceptedCreators.filter((creator: any) => creator.AccountName != creator.AccountName);
                      setListOfAcceptedCreators(newList);
                      setCreatorData((prev: any) => {
                        return prev.map((data: any) => {
                          if (data.id == creator.id) {
                            data.Status = "CREATOR_REQUESTED";
                            return data;
                          }
                          return data;
                        });
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            {listOfAcceptedCreators.length > 0 && (
              <div className={`bg-white rounded-[10px] ${isMobile ? 'h-auto p-4' : 'h-[60px] p-[5px]'} min-w-[180px] m-[5px] flex ${isMobile ? 'flex-col gap-3' : 'justify-between'} items-center`}>
                <h1 className={isMobile ? 'text-lg' : ''}>Total Money: {listOfAcceptedCreators.reduce((a: number, b: any) => a + (b?.TotalPrice || 0), 0)}</h1>
                <button
                  onClick={() => {
                    let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "")["access_token"];
                    axiosInstance.put(`${url}/business/accept/creator_request/${order.id}`, {
                      campaignId: order.id,
                      creatorList: listOfAcceptedCreators
                    }, {
                      headers: {
                        "Authorization": "Bearer " + userInfo
                      }
                    }).then((res) => {
                      setRefersh(!refersh);
                    }).catch((err) => {});
                  }}
                  className={`bg-[#090D25] text-white rounded p-[10px] ${isMobile ? 'w-full' : ''}`}
                >
                  Accept Selected Creators
                </button>
              </div>
            )}
            <Table
              columns={campaignDetail.isPublic == "true" ?
                (campaignDetail.socialMediaId == 3 ?
                  creatorColumnDataTelegramPublic(setListOfAcceptedCreators, ((updateValue: any) => {
                    setCreatorData((prev: any) => {
                      return prev.map((data: any) => {
                        if (data.id == updateValue.id) {
                          updateValue.Status = "TEMPORARY_ACCEPTED";
                          return updateValue;
                        }
                        return data;
                      });
                    });
                  })) :
                  creatorColumnDataYoutubePublic(setListOfAcceptedCreators, ((updateValue: any) => {
                    setCreatorData((prev: any) => {
                      return prev.map((data: any) => {
                        if (data.id == updateValue.id) {
                          updateValue.Status = "TEMPORARY_ACCEPTED";
                          return updateValue;
                        }
                        return data;
                      });
                    });
                  }))) :
                (campaignDetail.socialMediaId == 3 ? creatorColumnDataTelegram : creatorColumnData)}
              data={creatorData}
            />
          </div>
          {status == "production" && <ChatBox campaignId={campaignDetail.id} userId={userId} />}
        </>
      )}
    </div>
  );
}
