

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Switch, FormControl, FormLabel, Box, Stack, Container, Typography, Button, Grid, Paper } from '@mui/material';
import { getAgeGroup, getNicheList } from '../controller.tiktok';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/RootState';
import { lookupThunk } from '../../../../store/lookup/lookupThunk';
import { AppDispatch } from '../../../../store';
import { updateTiktokThunk } from '../../../../store/tiktok/tiktokThunk';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: '100%',
  maxWidth: '400px',
}));

const StyledSelect = styled('select')(({ theme }) => ({
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  backgroundColor: '#fff',
  fontSize: '16px',
  '&:focus': {
    outline: 'none',
    borderColor: '#0A0D25',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#0A0D25',
  color: 'white',
  padding: '12px 24px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#1a1f4d',
  },
}));

const ReadOnlyField = styled(Box)(({ theme }) => ({
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid #e0e0e0',
  backgroundColor: '#f5f5f5',
  fontSize: '16px',
  marginBottom: theme.spacing(2),
}));

export default function TiktokEdit() {
  const { tiktokDetail, tiktokAccountList   } = useSelector((state: RootState) => state.tiktok);
    const { niche, audienceAge } = useSelector((state: RootState) => state.lookUp); 

  const [formData, setFormData] = useState({
    nicheId: tiktokDetail?.nicheId || '',
    audienceAge: 'ef',
    status: tiktokDetail?.status || false,
    rate: tiktokDetail?.rate || ''
  });

  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigate();

  useEffect(() => {
    //dispatch(lookupThunk());
  }, [dispatch]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: 'transparent', py: 5, minHeight: '100vh' }}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '16px',
          padding: '32px',
        }}
      >
 <Typography variant="h6" sx={{ mb: 3, color: '#0A0D25', fontWeight: 'bold' , textAlign:'left' }}>
           Channel Detail 
          </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            {/* Channel Stats Section */}
            <Grid item xs={12} md={4}>
              <Paper elevation={0} sx={{ p: 3, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
                <Stack spacing={3}>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Channel Name
                    </Typography>
                    <Typography variant="h6">{tiktokDetail?.accountName || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Subscriber Count
                    </Typography>
                    <Typography variant="h6">{tiktokDetail?.subCount?.toLocaleString() || 'N/A'}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                      Average Views
                    </Typography>
                    <Typography variant="h6">{tiktokDetail?.avgView?.toLocaleString() || 'N/A'}</Typography>
                  </Box>
                </Stack>
              </Paper>
            </Grid>

            {/* Editable Fields Section */}
            <Grid item xs={12} md={8}>
              <Paper elevation={0} sx={{ p: 3, borderRadius: 2, border: '1px solid #e0e0e0' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledFormControl>
                      <FormLabel sx={{ mb: 1, color: '#0A0D25' , textAlign: 'left' }}>Niche</FormLabel>
                      <StyledSelect
                        value={formData.nicheId}
                        onChange={(e) => setFormData({ ...formData, nicheId: e.target.value })}
                      >
                        <option value="">Select Niche</option>
                        {niche && niche.map((nicheItem: any) => (
                          <option key={nicheItem.id} value={nicheItem.id}>
                            {nicheItem.name}
                          </option>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>
                  </Grid>
 
                  <Grid item xs={12} md={6}>
                    <StyledFormControl>
                      <FormLabel sx={{ mb: 1, color: '#0A0D25' ,textAlign: 'left' }}>Age Group</FormLabel>
                      <StyledSelect
                        value={formData.audienceAge}
                        onChange={(e) => setFormData({ ...formData, audienceAge: e.target.value })}
                      >
                        <option value="">Select Age Group</option>
                        {audienceAge && audienceAge.map((age: string) => (
                          <option key={age} value={age}>
                            {age}
                          </option>
                        ))}
                      </StyledSelect>
                    </StyledFormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <StyledFormControl>
                      <FormLabel sx={{ mb: 1, color: '#0A0D25', textAlign: 'left' }}>Rate</FormLabel>
                      <input
                        type="number"
                        value={formData.rate}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === '' || /^\d+$/.test(value)) {
                            setFormData({ ...formData, rate: value });
                          }
                        }}
                        style={{
                          width: '100%',
                          padding: '12px',
                          borderRadius: '8px',
                          border: '1px solid #e0e0e0',
                          backgroundColor: '#fff',
                          fontSize: '16px',
                          outline: 'none',
                        }}
                        placeholder="Enter rate"
                      />
                    </StyledFormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <FormLabel sx={{ color: '#0A0D25' }}>Status</FormLabel>
                      <Switch
                        checked={formData.status === "true"}
                        onChange={(e) => setFormData({ ...formData, status: e.target.checked })}
                        color="primary"
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                  <StyledButton type="submit" variant="contained"
                    onClick={()=>{
                        dispatch(updateTiktokThunk({
                            ...formData,
                            status: formData.status ? "true" : "false",
                            nicheId: Number(formData.nicheId) || 1,
                            rate: formData.rate || 0,
                            id: tiktokDetail?.id,
                            creatorId: 0,
                            socialMediaId: 0,
                            accountName: '',
                            accountLink: '',
                            subCount: 0,
                            avgView: '',
                            avgPostPerMonth: undefined,
                            refershToken: undefined,
                            quota_point: undefined,
                            channelOwnerId: '',
                            channelId: ''
                        }));
                        navigation("/dashboard/tiktok");
                    }} 
                  >
                    Save Changes
                  </StyledButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>

        {/* Campaign History Section */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 3, color: '#0A0D25', fontWeight: 'bold' , textAlign:'left' }}>
            Campaign History
          </Typography>
          <Grid container spacing={3}>
            {([]).map((campaign: any) => (
              <Grid item xs={12} md={6} lg={4} key={campaign.id}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid #e0e0e0',
                    height: '100%',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                    }
                  }}
                >
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#0A0D25' }}>
                      {campaign.name}
                    </Typography>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Status
                      </Typography>
                      <Typography variant="body1">
                        {campaign.status}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Duration
                      </Typography>
                      <Typography variant="body1">
                        {new Date(campaign.startDate).toLocaleDateString()} - {new Date(campaign.endDate).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Performance
                      </Typography>
                      <Typography variant="body1">
                        {campaign.views?.toLocaleString() || 0} views
                      </Typography>
                    </Box>
                  </Stack>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );

}