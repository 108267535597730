import { MdOutlineAttachMoney, MdCampaign, MdPending } from "react-icons/md";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import SingleCardContent from "../../../component/singleCardContent/SingleCardContent";
import { useState } from "react";
import CustomChart from "../../../component/custom_chart/customChart";
import { Campaign } from "./controller.home";
import { CircularProgress } from "@mui/material";
import CampaignComponent from "./campaginComponent";
import SocialMediaPopup from "./component/socialMediaPopup.";
import { useNavigate } from "react-router-dom";
import { useDashboardData } from './hooks/useDashboardData';

export default function DashboardHome() {
  const {
    fhfData,
    weeklyOrder,
    averageMonthlyEarning,
    campaignDetail,
    earningData,
    allCampaignDetail,
    loading,
    } = useDashboardData();

  let isMobile = window.innerWidth < 768;

  const [requestedPublicCampaign, setRequestedPublicCampaign] = useState<Campaign>();
  const navigation = useNavigate();

  return (
    <div className="homeMain">
      {requestedPublicCampaign && (
        <SocialMediaPopup 
          onClosed={() => { setRequestedPublicCampaign(undefined) }} 
          campaignId={requestedPublicCampaign} 
          navigation={navigation} 
        />
      )}

      <div className="topContent" style={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr' : 'repeat(5, 1fr)',
        width: isMobile ? '99%' : '93.6%',
        alignSelf: 'center',
        marginTop: isMobile ? '-30px' : '0px',
        padding: '0 10px',
        minWidth: isMobile ? 'auto' : 'min-content',
        overflowX: 'auto'
      }}>
        {loading ? (
          <>
            <CircularProgress />
            <CircularProgress />
            <CircularProgress />
            <CircularProgress />
            <CircularProgress />
          </>
        ) : (
          <>
            <SingleCardContent
              title="Predicted Earnings"
              amount={(earningData?.totalEarnings?.TotalEarnings || 0).toLocaleString()}
              icon={<GiReceiveMoney />}
            />
            <SingleCardContent
              title="Monthly Earnings"
              amount={(averageMonthlyEarning || 0).toLocaleString()}
              icon={<MdOutlineAttachMoney />}
            />
            <SingleCardContent
              title="Total Campaigns"
              amount={(campaignDetail?.TotalCampaigns || 0).toLocaleString()}
              icon={<MdCampaign />}
            />
            <SingleCardContent
              title="Available Earnings"
              amount={(earningData?.availableEarningsForCashOut || 0).toLocaleString()}
              icon={<GiTakeMyMoney />}
            />
            <SingleCardContent
              title="Pending campaign"
              amount={(campaignDetail?.PendingCampaigns || 0).toString()}
              icon={<MdPending />}
            />
          </>
        )}
      </div>

      {allCampaignDetail.length > 0 && (
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '93.8%',
          alignSelf: 'center',
          borderRadius: 5,
          padding: 5,
          height: 800
        }}>
          <h2 style={{ marginTop: 20, marginBottom: 10 }}>Public Campaigns</h2>
          <div className="topContent" style={{ 
            overflowY: 'scroll', 
            width: '106%', 
            alignItems: 'flex-start', 
            justifyContent: 'flex-start', 
            background: 'white', 
            borderRadius: 5, 
            gap: 40 
          }}>
            {allCampaignDetail.map((item: any, index: number) => (
              <CampaignComponent
                key={index}
                campaignData={item}
                onRequest={() => { setRequestedPublicCampaign(item) }}
              />
            ))}
          </div>
        </div>
      )}

      <div className="graphContent" style={{ width: '93.8%', alignSelf: 'center', display: 'grid', gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)'}}>
        <div className="singleGraph" style={{ width: '98.5%', maxWidth: isMobile ? 'auto' : '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
             <CustomChart props={weeklyOrder} type="bar" />
          )}
        </div>
        <div className="singleGraph" style={{ width: '98.5%', maxWidth:isMobile ? 'auto' : '100%' }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomChart props={fhfData} type="line" />
          )}
        </div>
 <div className="singleGraph" style={{width: '100%'}}>
          {loading ? (
            <CircularProgress />
          ) : (
             <CustomChart props={weeklyOrder} type="polarArea" />
          )}
        </div>
      </div>
    </div>
  );
}
