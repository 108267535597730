import { useEffect, useState } from "react";
import axiosInstance from "../../../axiosConfig";
import { url } from "../../../util/constant";
import { ErrorMessages, ForgetPasswordButton, ForgetPasswordContainer, ForgetPasswordInput, ForgetPasswordTitle, ForgetPasswordWrapper } from "./components";
import { useParams } from "react-router-dom";


export default function ResetPassword(){

    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpSent,setOtpSent] = useState(false);
    const [email,setEmail] = useState('');
    const [passwordChanged,setPasswordChanged] = useState(false);
    const [error,setError] = useState('');
    const [passwordDoesNotMatch,setPasswordDoesNotMatch] = useState(false);

    const params = useParams();

    useEffect(() => {
        // check if password confirmation is correct
        if(newPassword !== confirmPassword){
            setError('passwords do not match');
            setPasswordDoesNotMatch(false);

        }else{
            setError('');
            setPasswordDoesNotMatch(true);
        }
    },[confirmPassword,newPassword])

    const handelChangePassword = () => {
        axiosInstance.post(url+'/auth/reset-password', {
            token: params.token,
            password: newPassword
        }).then((res) => {
            if(res.data.status === 'success'){
                setPasswordChanged(true);
            }else{
                setError(res.data.message);
            }
            
        }).catch((err) => {
            
        });
    }

    return(
        <ForgetPasswordContainer>
            {passwordChanged && <p>Password changed successfully</p>}
            {passwordChanged && <button onClick={()=>{ document.location.href = "https://zeterads.com"}} style={{background:'#12144C',padding:10,borderRadius:10,margin:5,color:'white'}}>Home Page</button>}

         
            {!passwordChanged && 
            <ForgetPasswordWrapper>
            <ForgetPasswordTitle>Reset your password?</ForgetPasswordTitle>
            <p style={{textAlign:'start',width:'80%',color:'gray'}}>Please add your new password twice </p>
            <ForgetPasswordInput onChange={(e) => { setNewPassword(e.target.value)}
            } type="password" placeholder="New Password"/>
            <ForgetPasswordInput onChange={(e) => {setConfirmPassword(e.target.value)}} type="password" placeholder="Confirm Password"/>
            {passwordDoesNotMatch && 
            <ForgetPasswordButton onClick={() => {handelChangePassword()}}>Change Password</ForgetPasswordButton>
}
            {error !== '' && <ErrorMessages>{error}</ErrorMessages>}

            </ForgetPasswordWrapper>
            }
        </ForgetPasswordContainer>
    );
}

        