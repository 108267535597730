import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axiosConfig";
import { CreatorData } from "../../screens/admin_dashboard/creators/controller.creators";
import { url, version } from "../../util/constant";
import { setCreatorList } from "../campaigns/campaignSlice";
import { CreatorFilter } from "./creatorInterface";
import { useSelector } from "react-redux";
import { RootState } from "../RootState";

export const updateCreatorThunk = (creator: CreatorData): Promise<CreatorData> => {
    return new Promise((resolve, reject) => {
        axiosInstance.put(url + '/creator/update', creator)
            .then(function (response) {

                resolve(response.data);
            })
            .catch(function (error) {

                resolve(error);
            });
    });
}

export const createCreatorAccountThunk = (creator: CreatorData): Promise<CreatorData> => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(url + '/creator/create', creator)
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                resolve(error);
            });
    });
}

export const getCreatorListForBussinessThunk = createAsyncThunk(
    "creator/getCreatorListForBussinessThunk",
    async ({socialMediaId,CreatorFilter}: {socialMediaId: number, CreatorFilter:CreatorFilter }, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };

        axiosInstance.post(`${url}/${version}/business/getCreatorList/socialMediaId/${socialMediaId}/skip/0/take/20`, {filterContent: CreatorFilter}, { headers })
            .then(function (response) {
                dispatch(setCreatorList(response.data.data.creators));
            }).catch(function (error) {
                console.log(error);
            });
    }
)

export const getCreatorListForBussinessLoggedOUtThunk = createAsyncThunk(
    "creator/getCreatorListForBussinessThunk",
    async ({socialMediaId,CreatorFilter}: {socialMediaId: number, CreatorFilter:CreatorFilter }, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        //const headers = { Authorization: `Bearer ${userToken}` };

        axiosInstance.post(`${url}/${version}/business/getCreatorListLoggedOut/socialMediaId/${socialMediaId}/skip/0/take/20`, {filterContent: CreatorFilter} )
            .then(function (response) {
                dispatch(setCreatorList(response.data.data.creators));
            }).catch(function (error) {
                console.log(error);
            });
    }
)


