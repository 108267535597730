import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../../../component/progressBar/progressBar";
import { useState } from "react";

export const creatorColumnData = [
  { field: "id", headerName: "id", width: 50 },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
 
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "FirstName", headerName: "Name", width: 200 },
  { field: "ExpectedView", headerName: "Expected View", width: 180 },
  { field: "viewCount", headerName: "Actual View", width: 180 },
  {
    field: "",
    headerName: "Progress",
    width: 120,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 100,
          height: 42,
          borderRadius: 21,
        }}
      >
        <ProgressBar progress={calculateProgress(params.row.ExpectedView, params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
      </div>
    ),
  },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <Button
        disabled={params.value}
        title="Detail"
        variant="contained"
        type="button"
       // color={params.value === "Requested" ? ("primary") : ( params.value === "Rejected" ? ("red") : ("success"))}
       style={{ backgroundColor: params.value === "Requested" ? ("primary") : ( params.value === "Rejected" ? ("#ff0000") : ("success")),color:'white'}}
       size="medium"
      >
        {params.value}
      </Button>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 200,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex' }}>
        {params.row.Status != "Rejected" && <Button
          disabled={(params.value.split(".")[1] == "false")}
          title="Detail"
          variant="contained"
          type="button"
          color="success"
          onClick={() => {
            
            navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId, socialMediaAccountId: params.row.socialMediaAccountId } });
          }}
        >
          Detail

        </Button>}
        {params.row.Status == "Rejected" &&
          <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
            
            navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });

          }}>

            Replace
          </Button>
        }
      </div>
      );
    },
  },
];


export const creatorColumnDataTelegram = [
  { field: "id", headerName: "id" },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
    width: 120,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "FirstName", headerName: "Name", width: 200 },
  // {
  //   field: "",
  //   headerName: "Progress",
  //   width: 120,
  //   renderCell: (params: any) => (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         width: 100,
  //         height: 42,
  //         borderRadius: 21,
  //       }}
  //     >
  //       <ProgressBar progress={calculateProgress(params.row.ExpectedView,params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
  //     </div>
  //   ),
  // },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <div style={{ display: 'flex' }}>
        <Button
          disabled={params.value}
          title="Detail"
          variant="contained"
          type="button"
          // color={params.value === "Requested" ? "primary" : "success"}
          style={{ backgroundColor: params.value === "Requested" ? ("primary") : ( params.value === "Rejected" ? ("#ff0000") : ("success")),color:'white'}}

          size="medium"
        >
          {params.value}
        </Button>

      </div>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 300,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex' }}>
          {params.row.Status != "Rejected" && <Button
            disabled={(params.value.split(".")[1] == "false")}
            title="Detail"
            variant="contained"
            type="button"
            color="success"
            onClick={() => {
              
              navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId, socialMediaAccountId: params.row.socialMediaAccountId } });
            }}
          >
            Detail

          </Button>}
          {params.row.Status == "Rejected" &&
            <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
              navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });

            }}>

              Replace
            </Button>
          }
        </div>
      );
    },
  },
];


export const creatorColumnDataTelegramPublic = (setListOfAcceptedCreators:any,updateState:any): any[] => {
  
  // const [listOfAcceptedCreatorsLocal, setListOfAcceptedCreatorsLocal] = useState<any[]>([]);
  let listOfAcceptedCreatorsLocal:any[] = [];

  return[
  { field: "id", headerName: "id" },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
     flex: 1,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "AccountName", headerName: "Channel Name",  flex: 1, align:'center' , 
    renderCell: (params: any ) =>(
      <a href={params.row.channel_id} target="_blank" style={{color:'blue'}} rel="noreferrer"> 
        {params.value}
      </a>
    )
   },
  { field: "days", headerName: "Number of days", flex: 1, align:'center' },
  { field: "TotalPrice", headerName: "Budget", flex: 1, align:'center' },
  // { 
  //   field: "",
  //   headerName: "Progress",
  //   width: 120,
  //   renderCell: (params: any) => (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         width: 100,
  //         height: 42,
  //         borderRadius: 21,
  //       }}
  //     >
  //       <ProgressBar progress={calculateProgress(params.row.ExpectedView,params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
  //     </div>
  //   ),
  // },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <div style={{ display: 'flex' }}>
       
        <Button
          disabled={params.value}
          title="Detail"
          variant="contained"
          type="button"
          // color={params.value === "Requested" ? "primary" : "success"}
          style={{ backgroundColor: params.value === "Requested" ? ("primary") : ( params.value === "Rejected" ? ("#ff0000") : ("success")),color:'white'}}

          size="medium"
        >
          {params.value}
        </Button>

      </div>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 300,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex',justifyContent:'space-evenly' }}>
          {params.row.Status != "Rejected" && params.row.Status != "CREATOR_REQUESTED"  && <Button
            disabled={(params.value.split(".")[1] == "false")}
            title="Detail"
            variant="contained"
            type="button"
            color="success"
            onClick={() => {
              
              navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId, socialMediaAccountId: params.row.socialMediaAccountId } });
            }}
          >
            Detail

          </Button>}
{/* //CREATOR_REQUESTED */}
            {params.row.Status == "CREATOR_REQUESTED" && !listOfAcceptedCreatorsLocal.includes(params.row.AccountName) &&
            <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
              // navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });
              setListOfAcceptedCreators((prev:any)=>[...prev,params.row]);
              updateState(params.row);
              // listOfAcceptedCreatorsLocal.push(params.row.AccountName);
              // alert("JJJ: "+params.row.AccountName)
            }
            }>
              Accept
            </Button>
    }

          {params.row.Status == "Rejected" &&
            <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
              navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });

            }}>

              Replace
            </Button>
          }
        </div>
      );
    },
  },
];
}


export const creatorColumnDataYoutubePublic = (setListOfAcceptedCreators:any,updateState:any): any[] => {
  
  // const [listOfAcceptedCreatorsLocal, setListOfAcceptedCreatorsLocal] = useState<any[]>([]);
  let listOfAcceptedCreatorsLocal:any[] = [];

  return[
  { field: "id", headerName: "id" },
  {
    field: "identificationImage",
    headerName: "Profile Pic",
     flex: 1,
    renderCell: (params: any) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: 42,
          height: 42,
          borderRadius: 21,
        }}
      >
        <img
          src={params.value}
          alt="profile"
          style={{ width: 40, height: 40, borderRadius: 20 }}
        />
      </div>
    ),
  },
  { field: "AccountName", headerName: "Channel Name",  flex: 1, align:'center' , 
    renderCell: (params: any ) =>(
      <a href={params.row.AccountLink} target="_blank" style={{color:'blue'}} rel="noreferrer"> 
        {params.value}
      </a>
    )
   },
  { field: "ExpectedView", headerName: "Expected Views", flex: 1, align:'center' },
  { field: "TotalPrice", headerName: "Budget", flex: 1, align:'center' },
  // { 
  //   field: "",
  //   headerName: "Progress",
  //   width: 120,
  //   renderCell: (params: any) => (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         width: 100,
  //         height: 42,
  //         borderRadius: 21,
  //       }}
  //     >
  //       <ProgressBar progress={calculateProgress(params.row.ExpectedView,params.row.viewCount)} progressBarWidth={100} progressBarHeight={20} />
  //     </div>
  //   ),
  // },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
    renderCell: (params: any) => (
      <div style={{ display: 'flex' }}>
       
        <Button
          disabled={params.value}
          title="Detail"
          variant="contained"
          type="button"
          // color={params.value === "Requested" ? "primary" : "success"}
          style={{ backgroundColor: params.value === "Requested" ? ("primary") : ( params.value === "Rejected" ? ("#ff0000") : ("success")),color:'white'}}

          size="medium"
        >
          {params.value}
        </Button>

      </div>
      // <span style={{padding:10,borderRadius:5, background: params.value === 'Requested'? 'gray' : 'green',color:'white'}}>{params.value}</span>
    ),
  },
  {
    field: "Detail",
    headerName: "",
    width: 300,
    renderCell: (params: any) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate();
      return (
        <div style={{ display: 'flex',justifyContent:'space-evenly' }}>
          {params.row.Status != "Rejected" && params.row.Status != "CREATOR_REQUESTED"  && <Button
            disabled={(params.value.split(".")[1] == "false")}
            title="Detail"
            variant="contained"
            type="button"
            color="success"
            onClick={() => {
              
              navigate(`/dashboard/business/orderDetail/${params.row.CampaignId}`, { state: { creatorId: params.row.CreatorId, socialMediaAccountId: params.row.socialMediaAccountId } });
            }}
          >
            Detail

          </Button>}
{/* //CREATOR_REQUESTED */}
            {params.row.Status == "CREATOR_REQUESTED" && !listOfAcceptedCreatorsLocal.includes(params.row.AccountName) &&
            <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
              // navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });
              setListOfAcceptedCreators((prev:any)=>[...prev,params.row]);
              updateState(params.row);
              // listOfAcceptedCreatorsLocal.push(params.row.AccountName);
              // alert("JJJ: "+params.row.AccountName)
            }
            }>
              Accept
            </Button>
    }

          {params.row.Status == "Rejected" &&
            <Button style={{backgroundColor:'#090D25',color:'white'}} onClick={() => {
              navigate(`/dashboard/orderDetail/updateCreator-list`, { state: { data: params.row } });

            }}>

              Replace
            </Button>
          }
        </div>
      );
    },
  },
];
}


function calculateProgress(expected: number, actual: number) {
  return parseInt(((actual / expected) * 100).toFixed(2));
}