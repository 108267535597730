import React from 'react';
import WebsiteCollector from './WebsiteCollector';
import { useCampaignCreation } from '../hooks/useCampaignCreation';

const CampaignDescription: React.FC = () => {
  const { state, updateDescription, updatePostDescription, updateWebsiteListt, updateStartDate } = useCampaignCreation();

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateStartDate(event.target.value);
  };

  return (
    <div className="max-w-4xl mx-auto py-8 px-4 space-y-6 text-left">
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
        <h2 className="text-[20px] font-semibold text-gray-800 mb-2 text-left">Campaign Description</h2>
        <p className="text-gray-600 text-[14px] mb-4 text-left">
          Summarize your ad, key goals (e.g., brand awareness, sales), and any critical details influencers need to know, such as messaging or target audience.
        </p>
        <div className="w-full">
          <textarea
            className="w-full h-32 p-4 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 text-left"
            placeholder="Enter campaign description here..."
            onChange={(event) => updateDescription(event.target.value)}
          />
        </div>
      </div>
    
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
        <h2 className="text-[20px] font-semibold text-gray-800 mb-2 text-left">Video Description</h2>
        <p className="text-gray-600 text-[14px]  mb-4 text-left">
          Provide key info for the video description, promo codes, or hashtags.
        </p>
        <div className="w-full">
          <textarea
            className="w-full h-32 p-4 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 text-left"
            placeholder="This will be added under the campaign video / image"
            onChange={(event) => updatePostDescription(event.target.value)}
          />
        </div>
      </div>
    
      {state.isPublic === false && (
        <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
          <h2 className="text-[20px] font-semibold text-gray-800 mb-2 text-left">Select start Date</h2>
          <div className="mt-4">
            <input
              type="date"
              id="datepicker"
              min={new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]}
              className="w-64 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 text-left"
              value={state.startDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
      )}
    
      <div className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-6">
        <WebsiteCollector
          WebsiteList={state.websiteList}
          setWebsiteList={updateWebsiteListt}
          setPages={() => {}}
        />
      </div>
    </div>
  );
};

export default CampaignDescription;
