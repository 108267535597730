import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CreatorInterface } from './creatorInterface';

let creatorList: CreatorInterface = {
    creatorListForAdmin: [],
    creatorListForBussiness: [],
    isCreator: false,
    creatorData: undefined
};

export const creatorSlice = createSlice({
    name: "creator",
    initialState: creatorList,
    reducers: {
        getCreators: (state, action: PayloadAction<any>) => {
            state = action.payload;
        },
        shadowBanCreator: (state, action: PayloadAction<any>) => {
            state = action.payload;
        },
        setCreatorListForBussiness: (state, action: PayloadAction<any>) => {
            state.creatorListForBussiness = action.payload;
        },
        editCreator: (state, action: PayloadAction<any>) => {
            state = action.payload;
        }
    }
})

export const {
    getCreators,
    shadowBanCreator,
    editCreator,
    setCreatorListForBussiness 
} = creatorSlice.actions;
export default creatorSlice.reducer;