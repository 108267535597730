import logo from "../assets/images/zeter adv white logo1.png";
import logoBlack from "../assets/images/zeter adv black logo1.png";

import tools from "../assets/images/tools.svg";
import "./style.header.css";
import "../global/style.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { headerProp } from "./prop";
import { AiOutlineMenu } from "react-icons/ai";
import Menu from "../menu/menu";
import {
  isHome,
  isHowItWorks,
  getLinkClass,
  isPricing,
  isAboutUs
} from "./controller.header";
import awtarLogo from "../assets/logos/awatarLogo.svg";
import twoOneTwoLogo from "../assets/logos/twoOneTwoLogo.svg";
import aliboLogo from "../assets/logos/alibo.svg";
import esyeNursingLogo from "../assets/logos/eseye.svg";

export default function Header({ title: string, category }: headerProp) {
  const [prop] = useState({ title: string });
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={prop.title !== "home" ? "header-white" : "header"}>
      <Menu
        isOpen={isOpen}
        title={prop.title}
        callBack={() => {
          setIsOpen(!isOpen);
        }}
      />

    
        <div className="headerMenu">
          <div className="headerLogo">
            <Link to={"/"}>      <img src={isHome(prop.title) ? logo : logoBlack} alt="logo" /></Link>

          </div>

          <div className="bigScreenMenu">
            <ul className="navigationWrapper">
              <li>
                <Link
                  className={getLinkClass(prop.title, "home", "bigScreen")}
                  to="/"
                >
                  {"Home"}
                </Link>
              </li>
              <li>
                <Link
                  className={getLinkClass(prop.title, "pricing", "bigScreen")}
                  to="/pricing"
                >
                  {"Plans"}
                </Link>
              </li>
              <li>
                <Link
                  className={getLinkClass(
                    prop.title,
                    "how_it_works",
                    "bigScreen"
                  )}
                  to="/how_it_works"
                >
                  How it works
                </Link>
              </li>
              <li>
                <Link
                  className={getLinkClass(
                    prop.title,
                    "about_us",
                    "bigScreen"
                  )}
                  to="/about_us"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link to={"/creator_login"} className={"roundButtonBorder"}>
                  Login
                </Link>
              </li>
            </ul>
          </div>
          <div className="smallScreenMenu">
            <button
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className="menuButton"
            >
              <AiOutlineMenu size={40} color={"#6968FF"} />
            </button>
          </div>
        </div>
     

      {isHome(prop.title) ? (
        <div className="headerHero">
          <div className="leftSideHero">
            <h1>
             
             <span className={"top-header"}>  {"Boost your"} </span> 
             <br/>
             <span className={"top-header"}>  {"Sales with"} </span> 
             <br></br>
               <span style={{ color: "#6968FF" }}>Influencers! </span>
               <span style={{ color: "#6968FF" }}>Marketing </span>
            </h1>
            {/* <h2>With just a click.</h2> */}
            <p className="header-3">
          Leverage the power of Ethiopian influencer on TikTok, Telegram, and YoutTube. ZeterAds connects you 
          with the right creators to amplify your brand, drive engagement,
            </p>
            <div className="joinOptions">
              <button onClick={()=>{
                window.location.href = "/business-create-account-new"
              }}>
                {" "}
                <Link
                  style={{ textDecoration: "none" }}
                  to="business-create-account"
                >
                  {" "}
                  Join as a business{" "}
                </Link>
                {/* <Link
                  style={{ textDecoration: "none" }}
                  to="business-create-account-new"
                >
                  {" "}
                  Join as a business{" "}
                </Link> */}
              </button>
              <button onClick={()=>{
                window.location.href = "/create-account"
              }}>
                {" "}
                <Link style={{ textDecoration: "none" }} to="create-account">
                  {" "}
                  Join as an influencer{" "}
                </Link>
              </button>
            </div>
          </div>
          <div className="rightSideHero">
            <img src={tools} alt="tools" />
          </div>
        </div>
      ) : isHowItWorks(prop.title) ? (
        <div className="headerHero-2">
          {category === "business" ? (
            <h3>
              Collaborate with creators, <br />
              Scale your campaign & <br />
              <span className="flavorText"> Take over the Internet! </span>
            </h3>
          ) : (
            <h3>
              Creating content for fun is
              <span className="flavorText"> Great </span>
              <br />
              but when you get paid for it <br /> it becomes{" "}
              <span className="flavorText"> AWESOME!</span>
            </h3>
          )}
        </div>
      ) : isPricing(prop.title) ? (
        <div className="headerHero-2">
          <h3>
            Play the <span className="flavorText"> FAIR </span> game, <br />
            Use pay per view method to <span className="flavorText">GROW.</span>
          </h3>
        </div>
      ) : isAboutUs(prop.title) ? (
        <div className="headerHero-2">
          <h3>
            Creating an environment for <span className="flavorText"> Growth </span>
          </h3>
        </div> 
      ) : null}
      <div style={{width: '100%', height: 200, background:'white', overflow: 'scroll', whiteSpace: 'nowrap', display:'flex',flexDirection:'row', justifyContent:'space-evenly',padding: 10 }}>
          <img src={awtarLogo} style={{width: 150, height: 150, objectFit: 'contain', margin: '0 20px'}} alt="Awtar Logo" /> 
          <img src={twoOneTwoLogo} style={{width: 150, height: 150, objectFit: 'contain', margin: '0 20px'}} alt="212 Logo" /> 
          <img src={aliboLogo} style={{width: 150, height: 150, objectFit: 'contain', margin: '0 20px'}} alt="Alibo Logo" /> 
          <img src={esyeNursingLogo} style={{width: 150, height: 150, objectFit: 'contain', margin: '0 20px'}} alt="Esye Nursing Logo" /> 
      </div>
    </div>
  );
}
