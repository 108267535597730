import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axiosConfig";
import { url } from "../../util/constant";
import { clearError, setError, setLoading } from "../zeterState/zeterStateSlice";
import { setNicheList } from "./lookupSlice";


export const lookupThunk = createAsyncThunk(
    "lookup/getLookup",
    async (_, { dispatch }) => {
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            try{
            const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
            const headers = { Authorization: `Bearer ${userToken}` };
            const nicheList = await axiosInstance.get(`${url}/creator/get_all_niches`, { headers });
                if(nicheList.status === 200){
                        console.log("KKKKKKK, ", nicheList.data)
                    dispatch(setNicheList(nicheList.data))
                }

            }catch(e:any){
                dispatch(setError({
                    message: "Something Happend",
                    code: 0
                }))
            } finally{
                dispatch(setLoading(false));
            } 
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    }
)