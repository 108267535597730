import "./style.orders.css";
import "./style.mobile.orders.css";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useOrders } from "./hooks/useOrders";
import OrdersTable from "./OrdersTable";

export default function Orders(){

      let isMobile = window.innerWidth < 768;
      const [selectedOption,setSelectedOption] = useState<string>("All");
      const {  filteredCampaignList , loading } = useOrders();
      const [optionList] = useState<string[]>(["All","Requested","Accepted","Rejected","Completed"]);

    return (
        <div className="orderScreen" style={styles.orderScreen}>
          <div className="campaignList-header" style={styles.header}>
                {optionList.map((option,index)=>{
                    if(option == selectedOption){
                        return(
                            <span key={index} className={`option-selected`} style={styles.optionText}>{option}</span>
                        )
                    }
                    return(
                        <span onClick={()=>{
                              setSelectedOption(option);
                        }} key={index} className={`option`} style={styles.optionText}>{option}</span>
                    )
                })
                }
            </div>
            <div data-testid="table-container" style={{...styles.tableContainer, width: isMobile ? '100%' : '110%'}}>
            {loading ? <CircularProgress /> : 
            <OrdersTable campagins={filteredCampaignList(selectedOption) } />
            }</div>
        </div>
    );
}

const styles = {
  orderScreen: {
    height: 900,
    padding: '20px 30px'
  },
  header: {
    borderRadius: 12,
    width: '100%',
    justifyContent: 'space-between',
    gap: '20px',
    display: 'flex'
  },
  optionText: {
    fontWeight: 500
  },
  tableContainer: {
    margin: 0,
    height: 620,
    background: '#fff',
    borderRadius: 12,
    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
    padding: '24px 20px',
    overflow: 'scroll'
  }
};