// MediaUpload.tsx
import React, { useRef, useState } from 'react';
import axios from 'axios';
import { url } from '../../../../util/constant';
import { fileUpload } from '../../../create_account/controller.create_account';
import { useCampaignCreation } from '../hooks/useCampaignCreation';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { setPhotoUrl, setVideoUrl } from '../../../../store/campaigns/campaignSlice';

interface FileObject {
  name: string;
  duration?: number;
}

interface Props {
  fileObjects: FileObject[];
  setFileObjects: any; 
  setVideoLength: (value: string) => void;
  setCampaignPhoto: any; 
  socialMedia: string;
  setFileLoading: (value: boolean) => void;
}

const MediaUpload: React.FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {state,updateCampaignPhoto,updateFileObjects,updateLoading, updateVideoLength} = useCampaignCreation();
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    handleFileChange({ target: { files: droppedFiles } } as any);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    updateLoading(true);
    const files: any = event.target.files;
    if(state.socialMedia == "Telegram"){
      
      var formData = new FormData();
  formData.append("file", files[0]);

      axios.post(`${url}/uploads`, formData)
              .then((res) => {
               
                dispatch(setVideoUrl(res.data.file)); 
                dispatch(setPhotoUrl(res.data.file))
                updateCampaignPhoto(res.data.file);
                //updateFileObjects(false);


              })
              .catch((err) => {
                console.error(err);
              });
    }else{

    
  
  }
    const newFileObjects: FileObject[] = [];
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileObject: FileObject = { name: file.name };
        if (file.type.startsWith('video/')) {
          const video = document.createElement('video');
          video.src = URL.createObjectURL(file);
          video.addEventListener('loadedmetadata', () => {
            const duration = video.duration;
            updateVideoLength(duration.toString());
            fileObject.duration = duration;
            URL.revokeObjectURL(video.src);
            updateFileObjects([fileObject]);
            if(duration > 45){
              alert("Video duration must be less than 45 seconds");
              return;
            }else{
              fileUpload(files[0]).then((response: any ) => {
                
                if(response.data.id){
                  updateCampaignPhoto("https://www.youtube.com/watch?v="+response.data.id);
                  updateLoading(false);
                }else{
                 updateLoading(false);
                }
              }).catch((err: any) => {
                console.error(err);
              
              })
            }
            // axios
            //   .post(`${url}/uploads`, file)
            //   .then((res) => {
            //     
            //   })
            //   .catch((err) => {
            //     console.error(err);
            //   });
          });

        
          video.addEventListener('error', (error) => {
            console.error('Error loading video:', error);
            URL.revokeObjectURL(video.src);
          });
          await video.load();
        } else {
          newFileObjects.push(fileObject);
        }
      }
      updateFileObjects(newFileObjects);
    }
  };

  const handleSelectFilesClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="fadeIn">
    <p className='question'>{state.socialMedia === "telegram" ? "Upload Campaign image" : "Upload Campaign Video"} </p>
    <p style={{textAlign: 'start', color:'gray', fontSize: 17, marginBottom: 20}}>You must upload a video that is less than or equal to 45 secconds in length, you can learn more about the ruels on videos  <a style={{color: "#2D2CAB"}} href="/dashboard/business/campaign-new" > Here </a>  </p>

    <div className="flex flex-col items-center justify-center">
      <div
        className="w-[100%] h-64 border-dashed border-2 border-gray-300 rounded-md flex items-center justify-center"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={handleSelectFilesClick}
      >
        <input
          type="file"
          className="hidden"
          multiple
          onChange={(e) => {
            
            handleFileChange(e)  
          
          }}
          ref={fileInputRef}
        />
        <p className="text-gray-500">Drag & drop files here, or click to select files</p>
      </div>
  
      {state.fileObjects.map((fileObject, index) => (
<div key={index} style={{ marginTop: "30px",display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100%' }}>
<p style={{backgroundColor: 'lightblue', padding: 10,marginBottom: 10, borderRadius: 15, alignSelf: 'flex-start' }}>File Name: {fileObject.name}</p>
{fileObject.duration && <p  className={`border${fileObject.duration > 45? 'enabled': 'disabled'}`} style={{ backgroundColor: 'lightblue', padding: 10, borderRadius: 15, alignSelf: 'flex-start' }}>Duration: {fileObject.duration} seconds</p>}
</div>
))}
    </div>
  </div>
  );
};

export default MediaUpload;
