import SelectedCreatorsSummary from './SelectedCreatorsSummary';
import FiltersAndSearch from './Filter_And_Search/FiltersAndSearch';
import CreatorListTableComponent from './CreateListTableComponent';
import SocialMediaTabs from './SocialMediaTabs';

export default function SearchCreators() {
    return (
        <div>
            <h1 className="hidden md:block text-2xl font-bold text-[#090E24] mb-6 text-left">Create a Campaign</h1>
            <div className='mt-9'></div>
            <SocialMediaTabs />
            <SelectedCreatorsSummary />
            <FiltersAndSearch />
            <CreatorListTableComponent />
            <div style={{height: 90}}>

            </div>
        </div>
    );
}