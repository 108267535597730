import { useState, useEffect } from "react";
import { getCampaignVideoDetail, getYouTubeVideoId } from "./controller.campaign";
import { useParams, useLocation } from "react-router-dom";
import TelegramEmbed from "../../dashboard/orders/video_uploader/telegram/embed_telegram";
import ChatBox from "../../../component/chat_box/chatBox";
import tgBackground from "../../../component/assets/images/telegramBackground.png";
import CountDownTimer from "../../dashboard/orders/accepted_orders/countDownTimer";

interface CampaignOrder {
  id: number;
  creatorId: number;
  campaignId: number;
  socialMediaId: number;
  nicheId: number;
  videoUrl: string;
  viewCount: number | null;
  commentCount: number | null;
  likeCount: number | null;
}

export default function CampaignOrderDetail(order: any) {
  const isMobile = window.innerWidth < 768; // Check if device is mobile
  const [loadingVideoDetail, setLoadingVideoDetail] = useState<boolean>(false);
  const [campaignVideoDetail, setCampaignVideoDetail] = useState<any>();
  const [videoUrl, setVideoUrl] = useState<string>("");
  const param = useParams();
  const stateData = useLocation();

  useEffect(() => {
    const creatorId: number = stateData.state.creatorId;
    const campaignId: number = parseInt(param.id ?? "0");
    
    const socialMediaAccountId: number = parseInt(stateData.state.socialMediaAccountId ?? "0");
    
    
    
    

    getCampaignVideoDetail(creatorId, campaignId,socialMediaAccountId)
      .then((res: any) => {
        
        setCampaignVideoDetail(res);
        var embedUrl: string = getYouTubeVideoId(res?.videoUrl);
        setVideoUrl(embedUrl);
      })
      .catch((err) => {
        
      });
  }, []);

  if(campaignVideoDetail?.socialMediaId === 3){

    return(
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
          <div style={{ 
            display: 'flex',
            width:'90%',
            alignSelf:'center',
            borderRadius:10,
            justifyContent:'center',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center'
          }}>
              <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
                <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{campaignVideoDetail.totalPrice + " ETB"}</h3>
                <h2 style={{ borderRadius: 5, fontSize: isMobile ? '1rem' : '1.2rem' }}>Total Price</h2>
              </div>
              <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
                <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{campaignVideoDetail.days + " Days"}</h3>
                <h2 style={{ borderRadius: 5, fontSize: isMobile ? '1rem' : '1.2rem' }}>Total Days</h2>

              </div>
              <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
                {
                      (Date.now() - parseInt(campaignVideoDetail[campaignVideoDetail.length - 1]?.timePosted) * 1000) >= 24 * 60 * 60 * 1000 ?

                  <h3 style={{ color: 'red', textTransform:'uppercase', fontSize: isMobile ? '1.2rem' : '1.5rem' }}>compeleted</h3>
                  :
                <h3 style={{ textTransform:'uppercase', fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{campaignVideoDetail.status}</h3>

                }
                <h2 style={{ fontSize: isMobile ? '1rem' : '1.2rem' }}>Status</h2>
              </div>
            </div>
        {/* <h1>Watch Your Ads here </h1>  */}
        <div className="hoverCard" style={{ 
          width: '88%',
          height: isMobile ? 200 : 270,
          justifyContent:'center',
          display:'flex',
          flexDirection:'column',
          background:'radial-gradient(circle, rgba(9,13,37,0.3449754901960784) 0%, rgba(9,13,37,1) 92%)',
          padding: isMobile ? '10px 5px' : '10px'
        }}>
        <CountDownTimer targetDates={new Date(parseInt(campaignVideoDetail.telegramPosts[campaignVideoDetail.telegramPosts.length - 1]?.timePosted) * 1000 + 24 * 60 * 60 * 1000)} />

      </div>
        <div style={{ display: 'flex',width:'90%',alignSelf:'center' ,borderRadius:10,justifyContent:'center'}}>

      <div style={{
        width:'100%',
        height: isMobile ? 'auto' : '100%',
        display:'flex',
        justifyContent:'center',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems:'center',
        padding: isMobile ? 5 : 10,
        margin: isMobile ? 5 : 10,
        backgroundImage:'url("'+tgBackground +'")',
        borderRadius:10,
        flexWrap:'wrap',
        overflow:'scroll'
      }}>
           {campaignVideoDetail.telegramPosts.map((res:any,index:number) => (
            <div style={{
              display:'flex',
              flexDirection:'column',
              margin: isMobile ? 8 : 15,
              width: isMobile ? '90%' : 'auto'
            }}>
                 <TelegramEmbed index={index} link={res.videoUrl.split("t.me/")[1]} />
            <span style={{
              backgroundColor:'#33AFED',
              padding:5,
              margin: isMobile ? 5 : 10,
              borderRadius: 5,
              width: isMobile ? '90%' : 300,
              textAlign: 'center',
              fontSize: isMobile ? '0.9rem' : '1rem'
            }}>Hours On Top {res?.hoursOnTop}</span>

            </div>

           ))

           }
         </div>
      </div>
      <ChatBox campaignId={161} userId={2} />
      </div>
    )
  }

  return (
    <div className="singleOrder" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
      <div style={{ 
        display: 'flex', 
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
          <>
            <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{parseInt(campaignVideoDetail?.viewCount).toLocaleString() ?? "NA"}</h3>
            <p style={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>Current View</p>
          </>
        </div>

        <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
          <>
            <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{parseInt(campaignVideoDetail?.likeCount).toLocaleString() ?? "NA"}</h3>
            <p style={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>Like Count</p>
          </>
        </div>

        <div className="hoverCard" style={{ width: isMobile ? '90%' : 'auto', margin: isMobile ? '5px 0' : '0' }}>
          <>
            <h3 style={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>{parseInt(campaignVideoDetail?.commentCount).toLocaleString() ?? "NA"}</h3>
            <p style={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>Comment Count</p>
          </>
        </div>
      </div>

      <div className="singleOrder" style={{ width: '100%' }}>
        {
          videoUrl?.includes("youtube") ?
          <iframe
            width={isMobile ? "95%" : "83%"}
            height={isMobile ? "300" : "615"}
            style={{
              marginLeft: isMobile ? '0' : '20px',
              marginTop: isMobile ? '15px' : '0'
            }}
            src={videoUrl? videoUrl.replace("http://","https://"): ""}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          :
          <iframe
            width={isMobile ? "95%" : "83%"}
            height={isMobile ? "300" : "615"}
            style={{
              marginLeft: isMobile ? '0' : '20px',
              marginTop: isMobile ? '15px' : '0'
            }}
            src={videoUrl? videoUrl.replace("http://","https://") : ""}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        }
       
      </div>
    </div>
  );
}
