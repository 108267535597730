import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store/RootState"
import { AppDispatch } from "../../../../store";
import { useEffect } from "react";
import { tiktokThunk } from "../../../../store/tiktok/tiktokThunk";
import { tiktok, tiktokState } from "../../../../store/tiktok/tiktokInterface";
import { ZeterState } from "../../../../store/zeterState/zeterStateSlice";

interface useTiktok extends tiktokState, ZeterState { };

export const useTiktok = (): useTiktok => {

    const state = useSelector((state: RootState) => state.zeterState);
    const tiktokState = useSelector((state: RootState) => state.tiktok);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(tiktokThunk());
    }, [dispatch])
    
    return {
        ...tiktokState,
        ...state
    }
}