import { FormControl, InputLabel, MenuItem, Select, Chip, Box, OutlinedInput } from '@mui/material';
import { AiOutlineSearch, AiOutlineFilter, AiOutlineRobot } from 'react-icons/ai';
import AIPopup from '../AIPopup/AIPopup';
import FilterPopup from '../FilterPopup/FilterPopup';
import { useState } from 'react';
import { useCampaignCreation } from '../../hooks/useCampaignCreation';
import { CreatorFilter } from '../../../../../store/creator/creatorInterface';

export default function FiltersAndSearch() {
    const [isAIPopupOpen, setIsAIPopupOpen] = useState(false);
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [selectedNiches, setSelectedNiches] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    const { state, updateCreatorFilter, getCreatorList, lookups } = useCampaignCreation();

    const handleSearch = () => {
        const selectedNicheId = selectedNiches.length > 0
            ? lookups.niche.find(n => n.name === selectedNiches[0])?.id || 0
            : 0;

        const filterData: CreatorFilter = {
            name: searchQuery,
            nicheId: selectedNicheId,
            averageViewRange: state.creatorSearch.creatorFilter.averageViewRange,
            subscriberRange: state.creatorSearch.creatorFilter.subscriberRange,
        };
        updateCreatorFilter(filterData);
        getCreatorList(filterData);
    };

    return (
        <div className="flex flex-wrap gap-4 mb-6 items-center">
            <div className="flex gap-2 min-w-[300px]">
                <FormControl fullWidth>
                    <InputLabel id="multiple-niche-label">Niches</InputLabel>
                    <Select
                        labelId="multiple-niche-label"
                        multiple
                        value={selectedNiches}
                        onChange={(e) => {
                            const newNiches = typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value;
                            setSelectedNiches(newNiches);
                            handleSearch();
                        }}
                        input={<OutlinedInput label="Niches" />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                            </Box>
                        )}
                        className="bg-white"
                    >
                        {lookups.niche.map((niche) => (
                            <MenuItem key={niche.id} value={niche.name}>
                                {niche.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>

            <div className="flex-1 min-w-md">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="search..."
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            handleSearch();
                        }}
                        className="w-full px-4 py-2 rounded-lg border border-gray-200 pr-20"
                    />
                    <div className="absolute right-2 top-1/2 transform -translate-y-1/2 flex items-center gap-2">
                        <button
                            onClick={() => setIsFilterPopupOpen(true)}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                        >
                            <AiOutlineFilter size={20} className="text-gray-400" />
                        </button>
                        <button
                            onClick={handleSearch}
                            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                        >
                            <AiOutlineSearch size={20} className="text-gray-400" />
                        </button>
                    </div>
                </div>
            </div>

            <button
                onClick={() => setIsAIPopupOpen(true)}
                className="px-4 py-2 rounded-lg text-white font-medium flex items-center gap-2 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
            >
                <AiOutlineRobot size={20} />
                Use AI
            </button>
            <AIPopup open={isAIPopupOpen} onClose={() => setIsAIPopupOpen(false)} />
            <FilterPopup open={isFilterPopupOpen} onClose={() => setIsFilterPopupOpen(false)} />
        </div>
    );
}