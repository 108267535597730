import React, { useState } from 'react';
import './CampaignForm.css';

interface CampaignFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: FormData) => void;
}

interface FormData {
  media: File | null;
  duration: number;
  campaignType: 'long' | 'split';
}

const CampaignForm: React.FC<CampaignFormProps> = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState<FormData>({
    media: null,
    duration: 30,
    campaignType: 'long'
  });

  const handleMediaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, media: e.target.files[0] });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <div className={`campaign-form-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}>
      <div className="campaign-form-container" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2>Create Campaign</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Upload Media</label>
            <input
              type="file"
              accept="image/*,video/*"
              onChange={handleMediaChange}
              required
            />
          </div>
          <div className="form-group">
            <label>Campaign Duration (days)</label>
            <input
              type="number"
              min="1"
              value={formData.duration}
              onChange={e => setFormData({ ...formData, duration: parseInt(e.target.value) })}
              required
            />
          </div>
          <div className="form-group">
            <label>Campaign Type</label>
            <div className="campaign-type-buttons">
              <button
                type="button"
                className={`type-button ${formData.campaignType === 'long' ? 'active' : ''}`}
                onClick={() => setFormData({ ...formData, campaignType: 'long' })}
              >
                Long Run
              </button>
              <button
                type="button"
                className={`type-button ${formData.campaignType === 'split' ? 'active' : ''}`}
                onClick={() => setFormData({ ...formData, campaignType: 'split' })}
              >
                Split Run
              </button>
            </div>
          </div>
          <button type="submit" className="submit-button">
            Create Campaign
          </button>
        </form>
      </div>
    </div>
  );
};

export default CampaignForm;