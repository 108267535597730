import { formBuilder } from "./prop.form";
import CTextInput from "./textInput/textInput";
import "./style.form.css";
import { useState } from "react";
import CheckBox from "./checkBox/checkBox";
import DropDown from "./dropDown/dropDown";
import DatePicker from "./datePicker/DatePIcker";
import CTextArea from "./textArea/textArea";
import CFileUpload from "./fileUpload/fileUpload";

interface componentProp {
  formData: formBuilder[],
  callback: Function,
  startForm: number,
  endForm: number
}

export default function CFormComponent(
  { formData, callback, startForm, endForm }: componentProp
) {
  const [entredFormData, setEntredFormData] = useState<formBuilder[]>(formData);

  const handleCheckboxChange = (index: number, indexN: number) => {
    const updatedData = [...entredFormData]; // Create a copy of the state array
    updatedData[index].checkListValues[indexN] =
      !updatedData[index].checkListValues[indexN]; // Toggle checkbox value
    setEntredFormData(updatedData); // Update the state
    callback(updatedData); // Call the callback with the updated data
  };


  const handleDropDown = (index: number, indexN: number) => {
    const updatedData = [...entredFormData]; // Create a copy of the state array
    var value = updatedData[index].checkList![indexN];
    

    
    updatedData[index].value = value.toString();
    setEntredFormData(updatedData); // Update the state
    callback(updatedData); // Call the callback with the updated data
  };

  // const handleDatePicker = (index: number, indexN: number) => {
  //   const updatedData = [...entredFormData]; // Create a copy of the state array
  //   var value = updatedData[index].checkList ? [indexN] : [];
  //   updatedData[index].value = value.toString();
  //   setEntredFormData(updatedData); // Update the state
  //   callback(updatedData); // Call the callback with the updated data
  // };

  return (
    <div className="form">
      {entredFormData.map((form, index) => {
        return (
          index >= startForm &&
          index <= endForm &&
          (form.type === "TextInput" ? (
            <CTextInput
              label={form.label}
              placeholder={form.placeholder}
              value={form.value}
              onChange={(text) => {
                let temp = entredFormData;
                temp[index].value = text;
                callback(temp);
                setEntredFormData(temp);
              }}
              checkListValues={[]}
              name={form.name}
              required={form.required}
            />
          ) : form.type === "checkbox" ? (
            <CheckBox
              title={form.label}
              list={form.checkList || []}
              checkListValues={form.checkListValues}


              callback={(indexN: number) => handleCheckboxChange(index, indexN)}
            />

          ) : form.type === "dropdown" ? (
            <DropDown
              title={form.label}
              value={form.value}
              list={form.checkList || []}
              callback={(indexN: number) => handleDropDown(index, indexN)}
            />
          ) : form.type === "date" ? (
            <DatePicker
              title={form.label}
              value={form.value}
              onChange={(DateValue: string) => {
                let temp = [...entredFormData];
                
                temp[index].value = DateValue;

                callback(temp);
                setEntredFormData(temp);
              }}
            />
          ) : form.type === "textArea" ? (
            <CTextArea
              label={form.label}
              placeholder={form.placeholder}
              value={form.value}
              onChange={(text) => {
                let temp = entredFormData;
                temp[index].value = text;

                callback(temp);
                setEntredFormData(temp);
              }}
              checkListValues={[]}
              required={form.required}
            />
          ) : form.type === "file" ? (
            <CFileUpload
              uploadType="image"
              lable={form.label}
              onChange={(text: any) => {
                

                form.onChange(text);

                let temp = entredFormData;
                temp[index].value = text;

                callback(temp);
                setEntredFormData(temp);
              }}
              value={form.value}

            />
          ) : null)
        );
      })}
    </div>
  );
}
