import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import axiosInstance from "../../../axiosConfig";
import { url } from "../../../util/constant";


export const paymentColumn: GridColDef[] = [
  {
    field: 'id', headerName: 'Name', flex: 1,
    renderCell: (params: any) => {
      const fullName = params.row.FirstName + " " + params.row.LastName;
      return (
        <span>
          {fullName}
        </span>
      );
    }
  },
  {
    field: 'bank_name', headerName: 'Bank Name', flex: 1,
    renderCell: (params: any) => {
      const bank_name = params.row.bank_name;
      return (
        <span>
          {bank_name}
        </span>
      );
    }
  },
  {
    field: 'bank_account_number', headerName: 'Account', flex: 1,
    renderCell: (params: any) => {
      const bank_account_number = params.row.bank_account_number;
      return (
        <span>
          {bank_account_number}
        </span>
      );
    }
  },
  { field: 'totalPrice', headerName: 'Payment Amount', width: 100 },
  {
    field: '', headerName: 'status', flex: 1,
    renderCell: (params: any) => {


      return (
        <span className={params.value === "Requested" ? "statusButton-pending" : params.value === "Rejected" ? "statusButton-rejected" : "statusButton-active"} >
          {"Not Paid"}
        </span>
      );
    }
  },


];

export async function getPaymentRowData() {
  var token = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
  return new Promise((resolve, reject) => {
    axiosInstance.get(`${url}/admin/get_payments`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      
      resolve(res.data);
    }).catch((err) => {
      reject({ error: err });
    })

  })
}


export function DownloadCsvFromArrayJson(data: any, filename: string) {
  if (!data || !data.length) {
    return;
  }
  const header = Object.keys(data[0]);
  const csv = [
    header.join(','),
    ...data.map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName])).join(','))
  ].join('\r\n');

  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);

}

export async function getPayDayInfo() {
  var token = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
    axiosInstance.get(`${url}/campaign/pay_days/get`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      resolve(res.data);
    }
    ).catch((err) => {
      
      reject({ error: err });
    })
  })
}

export async function payCreators() {
  var token = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {

    axiosInstance.get(`${url}/admin/pay_for_creators`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      reject({ error: err });

    })


  })

}