import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import CampaignForm from './CampaignForm';

interface ShowOfBuildingProps {
  onBillboardSelect?: (billboardId: string) => void;
  modelUrl?: string;
  stats?: {
    footTraffic?: number;
    carTraffic?: number;
    areaPopulation?: number;
    eyeTraffic?: number;
  };
}


const ShowOfBuilding: React.FC<ShowOfBuildingProps> = ({ onBillboardSelect, modelUrl, stats }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const handleFormSubmit = (formData: any) => {
    console.log('Form submitted:', formData);
    setIsFormOpen(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const billboardsRef = useRef<THREE.Mesh[]>([]);

  useEffect(() => {
    if (!containerRef.current) return;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf0f0f0);
    sceneRef.current = scene;

    // Initialize camera with closer starting position
    const camera = new THREE.PerspectiveCamera(
      25, // Narrower FOV for more zoomed in view
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      2000
    );
    camera.position.set(6, 4, 6); // Closer starting position
    cameraRef.current = camera;

    // Initialize renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    containerRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    // Add controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.minDistance = 2; // Reduced minimum distance for closer zoom
    controls.maxDistance = 50; // Increased maximum distance for wider view
    controlsRef.current = controls;

    // Add lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);

    // Load 3D model if URL is provided, otherwise create default building
    let buildingModel: THREE.Mesh | THREE.Group | null = null;

    if (modelUrl) {
      const loader = new OBJLoader();
      loader.load(
        modelUrl,
        (obj) => {
          // Remove existing building if any
          if (buildingModel) {
            scene.remove(buildingModel);
          }

          buildingModel = obj;
          scene.add(obj);
          
          // Center and scale the model
          const box = new THREE.Box3().setFromObject(obj);
          const center = box.getCenter(new THREE.Vector3());
          const size = box.getSize(new THREE.Vector3());
          const maxDim = Math.max(size.x, size.y, size.z);
          const scale = 4 / maxDim;
          obj.scale.setScalar(scale);
          obj.position.sub(center.multiplyScalar(scale));
        },
        undefined,
        (error) => {
          console.error('Error loading 3D model:', error);
          createDefaultBuilding();
        }
      );
    } else {
      createDefaultBuilding();
    }

    function createDefaultBuilding() {
      const buildingGeometry = new THREE.BoxGeometry(4, 8, 4);
      const buildingMaterial = new THREE.MeshPhongMaterial({ color: 0x808080 });
      const building = new THREE.Mesh(buildingGeometry, buildingMaterial);
      scene.add(building);
    }

    // Create billboards
    const createBillboard = (position: THREE.Vector3, rotation: THREE.Euler) => {
      const billboardGeometry = new THREE.PlaneGeometry(2, 1);
      const billboardMaterial = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        side: THREE.DoubleSide,
      });
      const billboard = new THREE.Mesh(billboardGeometry, billboardMaterial);
      billboard.position.copy(position);
      billboard.rotation.copy(rotation);
      billboard.userData.isBillboard = true;
      billboard.userData.id = `billboard-${billboardsRef.current.length}`;
      billboardsRef.current.push(billboard);
      scene.add(billboard);
    };

    // Add billboards to different sides of the building
    // createBillboard(
    //   new THREE.Vector3(-4.01, 4, 0), // Moved closer to building wall (x: -2.01 instead of -2.1)
    //   new THREE.Euler(0, -Math.PI / 2, 0)
    // );
    // createBillboard(
    //   new THREE.Vector3(2.1, 4, 0),
    //   new THREE.Euler(0, Math.PI / 2, 0)
    // );
    // createBillboard(
    //   new THREE.Vector3(0, 4, -2.1),
    //   new THREE.Euler(0, Math.PI, 0)
    // );
    // createBillboard(
    //   new THREE.Vector3(-2.1, 4, 0),
    //   new THREE.Euler(0, -Math.PI / 2, 0)
    // );

    // Add raycaster for billboard selection
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    const onMouseMove = (event: MouseEvent) => {
      const rect = containerRef.current?.getBoundingClientRect();
      if (!rect) return;

      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(billboardsRef.current);

      // Reset all billboard colors
      billboardsRef.current.forEach((billboard) => {
        (billboard.material as THREE.MeshPhongMaterial).color.setHex(0xffffff);
      });

      // Highlight hovered billboard
      if (intersects.length > 0) {
        const hoveredBillboard = intersects[0].object as THREE.Mesh;
        (hoveredBillboard.material as THREE.MeshPhongMaterial).color.setHex(0xffff00);
      }
    };

    const onClick = () => {
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(billboardsRef.current);

      if (intersects.length > 0) {
        const selectedBillboard = intersects[0].object as THREE.Mesh;
        onBillboardSelect?.(selectedBillboard.userData.id);
      }
    };

    containerRef.current.addEventListener('mousemove', onMouseMove);
    containerRef.current.addEventListener('click', onClick);

    // Animation loop with automatic rotation
    const rotationSpeed = 0.005; // Adjust this value to control rotation speed
    let animationFrameId: number;
    const animate = () => {
      animationFrameId = requestAnimationFrame(animate);
      
      // Rotate camera position around the building
      const currentPosition = new THREE.Vector3();
      camera.getWorldPosition(currentPosition);
      
      const angle = rotationSpeed;
      const x = currentPosition.x * Math.cos(angle) - currentPosition.z * Math.sin(angle);
      const z = currentPosition.x * Math.sin(angle) + currentPosition.z * Math.cos(angle);
      
      camera.position.x = x;
      camera.position.z = z;
      camera.lookAt(0, 0, 0); // Keep camera looking at the center
      
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      if (!containerRef.current || !camera || !renderer) return;
      
      camera.aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      if (rendererRef.current) {
        rendererRef.current.dispose();
        rendererRef.current.forceContextLoss();
        if (rendererRef.current.domElement && rendererRef.current.domElement.parentNode) {
          rendererRef.current.domElement.parentNode.removeChild(rendererRef.current.domElement);
        }
        rendererRef.current = null;
      }
      if (controlsRef.current) {
        controlsRef.current.dispose();
        controlsRef.current = null;
      }
      if (cameraRef.current) {
        cameraRef.current = null;
      }
      // Clear any remaining billboards
      billboardsRef.current = [];
      // Clear scene
      if (sceneRef.current) {
        sceneRef.current.clear();
        sceneRef.current = null;
      }
      // Cancel animation frame if it exists
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [onBillboardSelect, modelUrl]);

  return (
    <div ref={containerRef} style={styles.container}>
      <CampaignForm
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        onSubmit={handleFormSubmit}
      />
      <div style={{ ...styles.statsPanel, right: isFormOpen ? '520px' : '20px' }}>
        <div style={styles.statsTitle}>
          Traffic Statistics
        </div>
        <div
          style={styles.statItem}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          👣 Foot Traffic: {stats?.footTraffic?.toLocaleString() || 'N/A'} /day
        </div>
        <div
          style={styles.statItem}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          🚗 Car Traffic: {stats?.carTraffic?.toLocaleString() || 'N/A'} /day
        </div>
        <div
          style={styles.statItem}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          👥 Area Population: {stats?.areaPopulation?.toLocaleString() || 'N/A'}
        </div>
        <div
          style={styles.statItem}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          👁️ Eye Traffic: {stats?.eyeTraffic?.toLocaleString() || 'N/A'} /day
        </div>
        <div
          style={styles.statItem}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.15)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.1)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'translateY(0)';
            e.currentTarget.style.backgroundColor = 'rgba(255, 255, 255, 0.1)';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          📏 Billboard Size: {stats?.eyeTraffic?.toLocaleString() || 'N/A'}
        </div>
        <div style={styles.buttonContainer}>
          <button
            onClick={() => setIsFormOpen(true)}
            style={styles.createButton}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = '#006699';
              e.currentTarget.style.transform = 'translateY(-2px)';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = '#0088cc';
              e.currentTarget.style.transform = 'translateY(0)';
            }}
          >
            <span>Create Campaign</span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
const styles = {
    container: {
      width: '100%',
      height: '800px',
      position: 'relative' as const
    },
    statsPanel: {
      position: 'absolute' as const,
      top: '20px',
      background: 'rgba(0, 0, 0, 0.7)',
      padding: '15px',
      borderRadius: '8px',
      color: 'white',
      fontFamily: 'Arial, sans-serif',
      zIndex: 1000,
      width: 300,
      height: 400,
      transition: 'right 0.3s ease-in-out'
    },
    statsTitle: {
      marginBottom: '10px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#fff'
    },
    statItem: {
      fontSize: '14px',
      marginBottom: '12px',
      padding: '12px',
      borderRadius: '12px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(5px)',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    },
    buttonContainer: {
      fontSize: '14px',
      marginTop: '15px',
      display: 'flex',
      justifyContent: 'center'
    },
    createButton: {
      backgroundColor: '#0088cc',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      borderRadius: '6px',
      fontSize: '14px',
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px'
    }
  };

export default ShowOfBuilding;