import { useMemo } from "react";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getRows } from "./controller.campaign";

export default function ListCampaign() {
    const [data, setData] = useState<any>([]);
    const [notFilteredData, setNotFilteredData] = useState<any>([]);
    const [selectedOption, setSelectedOption] = useState<string>("All");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(25);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const optionList = ["All", "distribution", "draft", "production", "Completed"];

    useEffect(() => {
        async function getRowsAsync() {
            let rowDataList = await getRows();
            setData(rowDataList);
            setNotFilteredData(rowDataList);
        }
        getRowsAsync();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFilterChange = (option: string) => {
        if (option === "All") {
            setData(notFilteredData);
        } else {
            const filteredData = notFilteredData.filter((item: any) => 
                item.campaignStatus === option
            );
            setData(filteredData);
        }
        setSelectedOption(option);
        setCurrentPage(1);
    };

    // Pagination logic
    const totalPages = Math.ceil(data.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const columns = getColumns();

    return (
        <div className="space-y-4 p-2 pl-10 max-w-full overflow-x-hidden">
            <div className="flex flex-wrap gap-2 mb-4">
                {optionList.map((option, index) => (
                    <button
                        key={index}
                        onClick={() => handleFilterChange(option)}
                        className={`px-3 py-1.5 rounded-lg text-xs font-medium transition-colors duration-200 ${option === selectedOption
                            ? 'bg-purple-600 text-white'
                            : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-200'}`}
                    >
                        {option}
                    </button>
                ))}
            </div>

            <div className="space-y-2 overflow-x-auto">
                {/* Column Headers - Only show on desktop */}
                <div className="hidden md:grid grid-cols-[1fr_1fr_0.8fr_0.8fr] gap-2 px-4 py-2 bg-gray-50 rounded-lg min-w-full relative">
                    <div className="text-sm font-medium text-gray-500 uppercase truncate">Description</div>
                    <div className="text-sm font-medium text-gray-500 uppercase truncate text-center relative before:content-[''] before:absolute before:left-[-1rem] before:top-[-0.5rem] before:bottom-[-0.5rem] before:w-[1px] before:bg-gray-200">Estimated View</div>
                    <div className="text-sm font-medium text-gray-500 uppercase truncate relative before:content-[''] before:absolute before:left-[-1rem] before:top-[-0.5rem] before:bottom-[-0.5rem] before:w-[1px] before:bg-gray-200">Status</div>
                    <div className="text-sm font-medium text-gray-500 uppercase truncate relative before:content-[''] before:absolute before:left-[-1rem] before:top-[-0.5rem] before:bottom-[-0.5rem] before:w-[1px] before:bg-gray-200">Action</div>
                </div>

                {/* Data Rows */}
                {currentItems.map((item: any, rowIndex: number) => (
                    <div key={rowIndex} className="md:grid md:grid-cols-[1fr_1fr_0.8fr_0.8fr] gap-2 p-3 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300 ease-in-out items-center min-w-full group hover:bg-gray-50 relative">
                        {/* Mobile Layout */}
                        <div className="md:hidden space-y-3 mb-2 shadow-lg p-4">
                            <div className="flex justify-between items-center relative">
                                <div className="text-sm text-gray-700 pr-4 relative before:content-[''] before:absolute before:right-0 before:top-0 before:bottom-0 before:w-[1px] ">{item.contentDescription?.length > 100 ? `${item.contentDescription.substring(0, 100)}...` : item.contentDescription}</div>
                                <span className={`px-2 py-1 rounded-full text-xs ${item.campaignStatus === 'draft' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'}`}>
                                    {item.campaignStatus}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="flex items-center space-x-1">
                                    <span className="text-green-600 font-bold text-sm">{item.totalViews?.toLocaleString()}</span>
                                    <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg>
                                </div>
                                <button
                                    onClick={() => window.location.href = `/dashboard/business/campaignDetail/${item.id}`}
                                    className="px-3 py-1 bg-gradient-to-r from-purple-600 to-purple-700 text-white rounded-lg shadow-sm text-xs flex items-center gap-1"
                                >
                                    <span>Detail</span>
                                    <svg className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        {/* Desktop Layout */}
                        <div className="hidden md:block text-sm text-gray-700 truncate">{item.contentDescription?.length > 200 ? `${item.contentDescription.substring(0, 200)}...` : item.contentDescription}</div>
                        <div className="hidden md:flex text-sm text-gray-700 truncate items-center justify-center relative before:content-[''] before:absolute before:left-[-1rem] before:top-0 before:bottom-0 before:w-[1px] before:bg-gray-200">
                            <span className="text-green-600 font-bold">{item.totalViews?.toLocaleString()}</span>
                            <svg className="w-5 h-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                            </svg>
                        </div>
                        <div className="hidden md:block text-sm text-gray-700 truncate relative before:content-[''] before:absolute before:left-[-1rem] before:top-0 before:bottom-0 before:w-[1px] before:bg-gray-200">
                            <span className={`px-2 py-1 rounded-full ${item.campaignStatus === 'draft' ? 'bg-yellow-100 text-yellow-800' : 'bg-green-100 text-green-800'}`}>
                                {item.campaignStatus}
                            </span>
                        </div>
                        <div className="hidden md:flex text-sm text-gray-700 truncate justify-end relative before:content-[''] before:absolute before:left-[-1rem] before:top-0 before:bottom-0 before:w-[1px] before:bg-gray-200">
                            <button
                                onClick={() => window.location.href = `/dashboard/business/campaignDetail/${item.id}`}
                                className="px-4 py-1.5 bg-gradient-to-r from-purple-600 to-purple-700 text-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 flex items-center gap-2 group-hover:bg-gradient-to-r group-hover:from-purple-700 group-hover:to-purple-800"
                            >
                                <span>Detail</span>
                                <svg className="w-4 h-4 transition-transform duration-300 transform group-hover:translate-x-0.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                            </button>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex flex-col sm:flex-row items-center justify-between px-4 py-3 bg-white rounded-lg shadow-sm">
                <div className="flex items-center space-x-2 mb-4 sm:mb-0 text-xs">
                    <span className="text-gray-500">Items per page:</span>
                    <select
                        value={itemsPerPage}
                        onChange={handleItemsPerPageChange}
                        className="p-1.5 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-xs"
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                    <span className="text-gray-500">
                        Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, data.length)} of {data.length}
                    </span>
                </div>

                <div className="flex items-center space-x-1">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`p-1.5 rounded-lg ${currentPage === 1 ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                    >
                        <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>

                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                        <button
                            key={number}
                            onClick={() => handlePageChange(number)}
                            className={`px-2.5 py-1 rounded-lg text-xs font-medium ${currentPage === number ? 'bg-purple-600 text-white' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                        >
                            {number}
                        </button>
                    ))}

                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`p-1.5 rounded-lg ${currentPage === totalPages ? 'bg-gray-100 text-gray-400' : 'bg-white text-gray-700 hover:bg-gray-50'}`}
                    >
                        <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    );
}