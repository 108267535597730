import axios from "axios";
import { url } from "../../../../util/constant";
import axiosInstance from "../../../../axiosConfig";



export function getVideoDetail(video_url: string){
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    var videoId = video_url.split("v=")[1];
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/campaign/getVideoViewCount/youtube/'+videoId, {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            
            resolve(response);
          })
          .catch(function (error) {
            
          resolve(error);
          });
        });
  }

  export function getCampaignTelgramDetail(campaign_id: number){
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/creator/campaign/telegram/detail/'+campaign_id, {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            
            resolve(response);
          })
          .catch(function (error) {
            
          resolve(error);
          });
        });
  }


  export function addCommas(number: number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }


  export function getVideoBugdget(totalExpectedView: number ,
  totalExtimatedBudget: number , singleExpectedView: number){
    var videoBudget = 0;
    if(totalExpectedView > 0 && totalExtimatedBudget > 0){
      videoBudget = totalExtimatedBudget / totalExpectedView;
    }else{
      videoBudget = 0;
    }
    return videoBudget;
  }


  export function percentageCalcualtor(expectedView: number , actuallView: number){
    var percentage = 0;
    if(expectedView > 0 && actuallView > 0){
      percentage = (actuallView / expectedView) * 100;
      if(percentage > 100){
        percentage = 100;
      }
    }else{
      percentage = 0;
    }
    
    
    
    return percentage;
  }