import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { RiTelegramFill } from 'react-icons/ri';
import { IoCloseCircle } from "react-icons/io5";
import { Link } from 'react-router-dom';
import axiosInstance from '../../../../axiosConfig';
import { url } from '../../../../util/constant';
import { AiFillYoutube } from 'react-icons/ai';

export default function SocialMediaPopup({ onClosed, campaignId, navigation }: any) {

    const [loading, setLoading] = useState(false);
    const [socialMediaContent, setSocialMediaContent] = useState<any>();

    useEffect(() => {
        setLoading(true);
        let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        axiosInstance.get(url + "/creator/get_social_media_account/for/campaign/" + campaignId.campaign_Id, {
            headers: {
                "Authorization": "Bearer " + userInfo
            }
        }).then((res) => {
            
            setSocialMediaContent(res.data);
            setLoading(false);
        }).catch((err) => {
            
            setLoading(false);
        });

    }, [])

    return (
        <PopupContainer>

            <PopupContent>
                <HeaderSection>Your Channels
                    <CancelButton onClick={() => { onClosed() }}>

                        <IoCloseCircle size={20} color="#040612" />
                    </CancelButton>

                </HeaderSection>
                {loading &&
                    <div>
                        Loading...
                    </div>}

                {socialMediaContent && socialMediaContent.status == "failed" &&
                    <ShowMessageInside>
                        You don't have any channels connected yet , please connect your channels to get started.
                        <Link to="/dashboard/telegram">
                            <RequestAsButton theme={{ "primaryColor": "#040612", "secondaryColor": '#1E90FF' }}>
                                Connect Channel
                            </RequestAsButton>
                        </Link>
                    </ShowMessageInside>
                }
                <div style={{ overflowY: 'scroll', height: 210 }}>
                    {socialMediaContent && socialMediaContent.data.map((res: any) => {

                        return (
                            <AccountCard style={{ opacity: res.isValid && res.status === "true" ? 1 : 0.4 }}>
                                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                    {res.socialMediaId == 3 ? <RiTelegramFill size={30} color="#27A5E5" /> : <AiFillYoutube size={30} color="red" />}
                                    <span style={{ fontSize: 12 }}> {res.accountName} </span>

                                </div>
                                <RequestAsButton onClick={() => {
                                    let token = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
                                    axiosInstance.get(url + "/creator/campaign_request/" + res.id + "/" + campaignId.campaign_Id, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    }).then((res) => {
                                        alert("Successfully Requested");
                                        onClosed();
                                        navigation("/dashboard/order/" + res.data.data[0].id);
                                    }).catch((err) => {
                                        
                                    })
                                }} theme={{ "primaryColor": "#040612", "secondaryColor": '#1E90FF' }}>
                                    Request As
                                </RequestAsButton>
                            </AccountCard>
                        )
                    })

                    }
                </div>
            </PopupContent>

        </PopupContainer>
    );
}

const ShowMessageInside = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: white;
    justify-content: space-between;
    color: gray;
`;

const RequestAsButton = styled.button`
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 12px;
    &:hover {
        background-color: ${({ theme }) => theme.secondaryColor};
    }
`;


const AccountCard = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-radius: 2px;
    background-color: white;
    box-shadow: 0px 0px 2px 0px lightgray;
    justify-content: space-between;
    height: 50px;
`;

const HeaderSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height:  40px;
    background: white;
    border-bottom: 1px solid lightgray;
`;

const CancelButton = styled.button`
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
        background-color: transparent;
    }
`;

const PopupContent = styled.div`
    width: 500px;
    height: 300px;
    background-color: white;
    border-radius: 2px;
    padding: 10px;
    box-sizing: border-box;
`;

const PopupContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
`;