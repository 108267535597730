import react, { useState } from "react";
import styled from "styled-components";
import logo from "../../../component/assets/images/zeter adv white logo1.png"
import axiosInstance from "../../../axiosConfig";
import { url } from "../../../util/constant";

export default function ForgetPassword(){

    const [email, setEmail] = useState('');
    const [emailSent,setEmailSent] = useState(false);

    const handelSendEmail = () => {
        axiosInstance.post(url+'/auth/forgot-password', {
            email: email
        }).then((res) => {
            if(res.data.status === 'success'){
                setEmailSent(true);
            }
            
        }).catch((err) => {
            
        });
    }

    return(
        <ForgetPasswordContainer>
            {emailSent && <p>Email sent successfully, Please check your email at {email} </p>}
            {!emailSent && 
            <ForgetPasswordWrapper>
            <ForgetPasswordTitle>Forget your password?</ForgetPasswordTitle>
            <p style={{textAlign:'start',width:'80%',color:'gray'}}>Please add the email accociated with your account, we will send you an OTP to verify your identity</p>
            <ForgetPasswordInput onChange={(e) => {setEmail(e.target.value)}} type="email" placeholder="Email"/>
            <ForgetPasswordButton onClick={() => {handelSendEmail()}}>Send</ForgetPasswordButton>
            </ForgetPasswordWrapper>
            }

        </ForgetPasswordContainer>
    );
}


const ForgetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EBEFF3;
    width: 100%;
    height: 100vh;
`;

const ForgetPasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 400px;
    height: 350px;
    margin-top: 0px;
    margin-bottom: 20px;
    `;

const ForgetPasswordTitle = styled.h1`
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: start;
`;

const ForgetPasswordInput = styled.input`
    margin-top: 20px;
    padding: 10px;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
`;

const ForgetPasswordButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #151B78;
    color: white;
`;