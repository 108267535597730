import type { GridColDef } from "@mui/x-data-grid/models";
import { Link } from "react-router-dom";
import { AiFillYoutube, AiOutlineFundView } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa6";
import Table from "../../../component/table/table";
import { Campaign } from "../../../store/campaigns/campaignInterface";

export default function OrdersTable({ campagins }: { campagins: Campaign[] }) {

  const columns: GridColDef[] = [
    {
      field: 'business.name', headerName: 'Company Name', flex: 1, align: 'center', headerClassName: 'centerHeader', disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder' }}>
            {params.row.business?.name || 'N/A'}
          </span>
        )
      }
    },
    {
      field: 'socialMediaId', headerName: 'Social Media', flex: 1,
      renderCell: (params: any) => {
        return (
          <span style={{ color: 'black', fontWeight: 'bolder', display: 'flex', flexDirection: 'row' }}>
            {params.value == 1 ?
              <AiFillYoutube style={{ fontSize: 23 }} /> :
              <a href={params.row.creatorinacampaigns[0].socialMediaAccount.accountLink} target="_blank" >
               <p><FaTelegram style={{ fontSize: 23 }} />   Telegram </p> 
              </a>}
          </span>
        )
      }
    },
    { field: 'startDate', headerName: 'Start Date', flex: 1 },
    {
      field: 'campaignStatus', headerName: 'status', flex: 1,
      renderCell: (params: any) => {

        return (
          <span className={params.value === "Requested" ? "statusButton-pending" : params.value === "Rejected" ? "statusButton-rejected" : "statusButton-active"} >
            {params.value + ""}
          </span>
        );
      }
    },
    {
      field: '',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell: (params: any) => {
        return (
          <div>
            <Link to={"/dashboard/order/" + params.row.creatorinacampaigns[0].id} className="actionButton" style={{ background: '#090D25' }} >
              Detail
            </Link>
          </div>
        );
      },
      flex: 1,
    },
  ]
  return (
    <div>
      <Table columns={columns} data={campagins} />
    </div>
  )
}