import { Creator } from '../../../../store/creator/creatorInterface';
import { useState, useEffect } from 'react';

interface MobileCreatorPopupProps {
    selectedCreator: Creator;
    showMobilePopup: boolean;
    onClose: () => void;
    onDaysChange: (creatorId: number, days: number) => void;
    onPinToggle: (creatorId: number) => void;
    onCreatorSelect: (creator: Creator) => void;
    formatNumber: (num: number) => string;
}

export default function MobileCreatorPopup({
    selectedCreator,
    showMobilePopup,
    onClose,
    onDaysChange,
    onPinToggle,
    onCreatorSelect,
    formatNumber
}: MobileCreatorPopupProps) {
    const [localDays, setLocalDays] = useState(selectedCreator?.campaignDays || 1);
    const [localIsPin, setLocalIsPin] = useState(selectedCreator.isPin || false);

    useEffect(() => {
        setLocalDays(selectedCreator.campaignDays || 1);
        setLocalIsPin(selectedCreator.isPin || false);
    }, [selectedCreator]);

    const handleDaysChange = (days: number) => {
        setLocalDays(days);
        onDaysChange(selectedCreator.id, days);
    };

    const handlePinToggle = () => {
        setLocalIsPin(!localIsPin);
        onPinToggle(selectedCreator.id);
    };

    const calculatePrice = () => {
        const selectedPlan = selectedCreator.telegramPaymentPlans?.find(plan => plan.days === localDays);
        return localIsPin ? (selectedPlan?.with_pin || 0) : (selectedPlan?.with_out_pin || 0);
    };

    if (!showMobilePopup) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg p-6 w-full max-w-sm">
                <div className="flex justify-between items-start mb-4">
                    <h3 className="text-lg font-medium text-gray-900">{selectedCreator.accountName}</h3>
                    <button
                        onClick={onClose}
                        className="text-gray-400 hover:text-gray-500"
                    >
                        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                
                <div className="space-y-4 text-left">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Campaign Days</label>
                        <select
                            value={localDays}
                            onChange={(e) => handleDaysChange(parseInt(e.target.value))}
                            className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                        >
                            {selectedCreator.telegramPaymentPlans?.map((plan) => (
                                <option key={plan.id} value={plan.days}>{plan.days} days</option>
                            ))}
                        </select>
                    </div>
                    
                    <div className="flex justify-between items-start space-x-4">
                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Pin Post</label>
                            <button
                                onClick={handlePinToggle}
                                className={`w-12 h-6 flex items-center rounded-full p-1 ${localIsPin ? 'bg-purple-600' : 'bg-gray-300'} transition-colors duration-200`}
                            >
                                <div className={`w-4 h-4 rounded-full bg-white transform transition-transform duration-200 ${localIsPin ? 'translate-x-6' : 'translate-x-0'}`} />
                            </button>
                        </div>

                        <div className="flex-1">
                            <label className="block text-sm font-medium text-gray-700 mb-2">Price</label>
                            <div className="text-lg font-semibold text-purple-600">
                                {formatNumber(calculatePrice())} br
                            </div>
                        </div>
                    </div>

                    <div className="pt-4">
                        <button
                            onClick={() => {
                                onCreatorSelect({
                                    ...selectedCreator,
                                    campaignDays: localDays,
                                    isPin: localIsPin
                                });
                                onClose();
                            }}
                            className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors duration-200"
                        >
                            Select Creator
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}