import { createAsyncThunk } from "@reduxjs/toolkit";
import { setError, setLoading } from "../zeterState/zeterStateSlice";
import axiosInstance from "../../axiosConfig";
import { url } from "../../util/constant";
import { setResponse } from "./zeterAiSlice";
import { Message } from "./interfaceZeterAi";
import { setSelectedCreatorList } from "../campaigns/campaignSlice";

export const passPrompt = createAsyncThunk(
    "zeterAi/passPrompt",
    async (prompt: string, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
            const headers = {
                Authorization: `Bearer ${userToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json"
            };

            const promptResponse = await axiosInstance.post(`${url}/zeter-ai/zeterBot/domagic`, {
                headers,
                text: prompt
            });
            let botResponse: Message[] = [];
            botResponse = [{
                text: promptResponse.data.data,
                isBot: false
            }]; 
            dispatch(setResponse(botResponse))

        } catch (e: any) {
            dispatch(setError({
                message: e.message,
                code: 0
            }))
        } finally {
            dispatch(setLoading(false));
        }
    }
);


export const customBuiltPrompt = createAsyncThunk(
    "zeter/customBuiltPrompt",
    async (prompt: string, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
            const headers = {
                Authorization: `Bearer ${userToken}`,
            }
            const promptResponse = await axiosInstance.post(`${url}/zeter-ai/zeterBot/get_creators`, {
                headers,
                text: prompt
            });
            dispatch(setSelectedCreatorList(promptResponse.data.data))
            return promptResponse.data;
        }catch(e:any) {
            dispatch(setError({
                message: e.message,
                code: 0
            }))
        }finally {
            dispatch(setLoading(false));
        }
    }
);
