import { createAsyncThunk } from "@reduxjs/toolkit";
import { clearError, setError, setLoading, setSuccessMessage } from "../zeterState/zeterStateSlice";
import axiosInstance from "../../axiosConfig";
import { url } from "../../util/constant";
import { AxiosError } from "axios";
import { setTiktok, setTiktokAccountList } from "./tiktokSlice";
import { tiktok, tiktokState } from "./tiktokInterface";

export const tiktokThunk = createAsyncThunk(
    "titkok/getTiktok",
    async (_, { dispatch }) => {
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
            const headers = { Authorization: `Bearer ${userToken}` };
            const connectedAccountList = await axiosInstance.get(`${url}/tiktok/get_tiktok_channels`, { headers });
                dispatch(setTiktokAccountList(connectedAccountList.data.data))
        } catch (e: AxiosError | any) {
            dispatch(setError({
                message: e.message,
                code: e.response.status
            }))
        } finally {
            dispatch(setLoading(false));
            dispatch(setSuccessMessage("Success"))
        }
    })

export const updateTiktokThunk = createAsyncThunk(
     "tiktok/updateTiktok",
    async (data: tiktok , { dispatch }) => {
        try {
            dispatch(clearError());
            dispatch(setLoading(true));
            const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
            const headers = { Authorization: `Bearer ${userToken}` };

            const response = await axiosInstance.put(`${url}/tiktok/update_tiktok_channel`, data, { headers });
            //dispatch(setTiktok(response.data.data))


        }catch(e){
            dispatch(setError({
                message: "Something went wrong",
                code: 200 
            }))
        } finally{
            dispatch(setLoading(false))
        }
    }
);