import { useState } from "react";
import "./style.fileupload.css";

export default function CFileUpload(props: {
  uploadType: string;
  lable: string;
  value: string;

  onChange: (value: any) => void;
  
}) 

{
  const [duration, setDuration] = useState<string | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const video = document.createElement('video');
      video.onloadedmetadata = () => {
        const durationInSeconds = Math.floor(video.duration);
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;
        setDuration(`${hours}:${minutes}:${seconds}`);
      };
      video.src = URL.createObjectURL(file);
      props.onChange(file);
    }
  };
  return (
    <>
      {props.uploadType === "image" ? (
        <div className="uploadUI">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            {props.lable}
            
          </label>
          <input
            className="block p-2 font-sans font-normal w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            aria-describedby="file_input_help"
            name="file_input"
            type="file"
            // accept=".png,.jpg,.jpeg"
          //   onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              
          //     const file = e.target.files && e.target.files[0];
          //     // setUpdate(file);
          //     props.onChange(file);
              
          //   }
          // }
          onChange={handleFileChange}
         
            // onInputCapture={(val) => {
            //     
                
            //   props.onChange("hello");
            // }}
          />
           {duration && <div>Duration: {duration}</div>}
          <p
            className="mt-1 self-start font-sans text-xs font-semibold text-xs text-gray-500 dark:text-gray-300"
            id="file_input_help"
          >
            PNG, JPEG or JPG.
          </p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
