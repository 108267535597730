import { useEffect, useState } from "react";
import { getCreatorDetailById } from "./controller.publicCreator";
import avater from "../../../component/assets/images/avater.webp";
import "./style.publicProfile.css";
import logo from "../../../component/assets/images/zeter adv white logo1.png";

export default function PublicCreatorPage(){
   
    const [creatorDetail, setCreatorDetail] = useState<any>();
    const [userInfo, setUserInfo] = useState<any>();
    
    useEffect(()=>{
  
        let creatorId = window.location.pathname.split("/").pop();
        
        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}");
        setUserInfo(userInfo);
        getCreatorDetailById(creatorId as string).then((res)=>{
            
            setCreatorDetail(res);
        }
        ).catch((err)=>{
            
        })

        
    },[])

    if(creatorDetail === undefined && userInfo === undefined){
        return(
        
            <div>
                
            </div>
        );
    }else if(creatorDetail !== undefined && userInfo !== undefined){
        return(
        
            <div className={"public_profile_container"} style={{background: '#090d251f'}}>
              <div className={"profile_pic_public"}> 
                <img style={{width: 400}} src={creatorDetail.photo ? creatorDetail.photo : avater} alt="creator" />
                </div>
                <div style={{display: 'flex',flexDirection:'row',gap: 5}}>
                <h2>Name:</h2>
                <h2>{creatorDetail.creatorName} </h2>
                </div>
                <div style={{display: 'flex',flexDirection:'row',gap: 5}}>
                <h2>Total Campaign:</h2>
                <h2>{creatorDetail.totalCampaigns} </h2>
                </div>
                <div style={{display: 'flex',flexDirection:'row',gap: 5}}>
                <h2>Total Campaign with {userInfo?.businessDetail?.name}:</h2>
                <h2>{creatorDetail.totalCampaigns} </h2>
                </div>
                <div style={{display: 'flex',flexDirection:'row',gap: 5}}>
                <h2>About Me</h2>
                <h2>{creatorDetail.about} </h2>
                </div>
            </div>
        );
    }else{
        return (
            <div></div>
        );
    }
   
}