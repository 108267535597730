// a very goold looking forbidden page
import React from 'react';
import { IconContext } from 'react-icons';
import { FiAlertTriangle } from 'react-icons/fi';

const ForbiddenPage: React.FC = () => {
  return (
    <div style={{ width: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', height: '100vh', background: 'linear-gradient(39deg, rgba(108,74,18,1) 0%, rgba(196,196,196,1) 92%)' }}>
      <h1>403 Forbidden</h1>
      <IconContext.Provider value={{ size: '10em', color: 'orange' }}>
        <FiAlertTriangle />
      </IconContext.Provider>
      <p>Oops! You don't have permission to access this page.</p>
    </div>
  );
};

export default ForbiddenPage;
