import { useCampaignCreation } from "../hooks/useCampaignCreation";
import CampaignDescription from "../components/campaignDescription";
import MediaUpload from "../components/mediaUpload";
import SocialMediaSelection from "../components/SocialMediaSelection";

export default function PublicCampaignFlow() {
    const { state, previousStep, nextStep } = useCampaignCreation();
    return (
        <div>
            {
                state.currentStep === 1 && state.isPublic &&
                <SocialMediaSelection />
            }

            {
                state.currentStep === 2 && state.isPublic &&
                <div style={style.centeritation}>
                    <CampaignDescription />
                </div>
            }

            {
                state.currentStep === 3 && state.isPublic &&
                <div style={style.centeritation}>
                    <MediaUpload />
                </div>
            }

            {
                state.currentStep !== 0 && state.currentStep !== 4 && state.isPublic &&
                <div className="flex gap-4 mt-6 justify-center">
                    <button
                        onClick={() => previousStep()}
                        className="px-6 py-3 bg-gray-50 text-gray-700 rounded-lg font-medium transition-all hover:bg-gray-100 hover:shadow-md active:transform active:scale-95"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => nextStep()}
                        className="px-6 py-3 bg-[#090D25] text-white rounded-lg font-medium transition-all hover:bg-[#1a1f3d] hover:shadow-md active:transform active:scale-95"
                    >
                        Next
                    </button>
                </div>
            }



        </div>
    );
}

const style = {
    centeritation: {
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '70vh'
    }
};