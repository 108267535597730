import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import axiosInstance from "../../axiosConfig";
import { url } from "../../util/constant";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail() {
    // Timer state (initially set to 5 minutes and 12 seconds)
    
  
            const [minutes, setMinutes] = useState(5);
    const [seconds, setSeconds] = useState(12);
    const [otp, setOtp] = useState("");
    const [email, setEmail] = useState("");
    const navigation = useNavigate();
    const [postInfo,setPostInfo] = useState<any>({});


    // useEffect(() => {
    //     let dateTime = new Date(postInfo.time || "") || new Date();
    //     dateTime.setMinutes(dateTime.getMinutes() + 5);
    //     dateTime.setSeconds(dateTime.getSeconds() );

    //         // get the remaining time
    //         let remainingTime = dateTime.getTime() - new Date().getTime();

    //         // convert remaining time to minutes and seconds
    //         let minutesLocal = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    //         let secondsLocal = Math.floor((remainingTime % (1000 * 60)) / 1000);
       
    //         setMinutes(minutesLocal);
    //         setSeconds(secondsLocal);

    // },[postInfo])


    useEffect(() => {
        
        const countdown = setInterval(() => {
            // start from a timestamp
          


            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (minutes > 0 && seconds === 0) {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        }, 1000);

        // Clear interval when timer reaches 0:00
        if (minutes === 0 && seconds === 0) {
            clearInterval(countdown);
        }

        // Cleanup interval on component unmount
        return () => clearInterval(countdown);
    }, [minutes, seconds]);


    useEffect(() => {
        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

        axiosInstance.post(url+'/creator/resend_email_verification',{},{
            headers: {
                "Authorization": "Bearer " + userInfo
            }
        }).then((res) => {
            
            setPostInfo(res.data);
            setEmail(res.data.email);
        }).catch((err) => {
            
        })
    },[])


    const verifyEmailOtp = () => {
        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

        axiosInstance.post(url+'/creator/verify_email',{"otp": otp , "email": email},{
            headers: {  
                    "Authorization": "Bearer " + userInfo
            }
        }).then((res) => {
            
            if(res.data.status === 'success'){
            navigation("/dashboard");
              
            }else{
                alert("Invalid OTP");
            }
        }).catch((err) => {
            
        }
    );
    }

    return (
        <Container>
            <h3 style={{ fontSize: 40, marginBottom: 50 }}>Verify Email</h3>

            <p style={{ textAlign: 'start', width: 400 }}>
                We have sent an email to {postInfo.email} with a verification code. Please enter the code below.
            </p>
            <CustomInput onChange={(e) => {
                setOtp(e.target.value);
            }} type="number" placeholder="Enter OTP" />
            <div style={{ display: 'flex', width: 400, justifyContent: 'start', color: 'black' }}>
                <p>{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}</p>
            </div>
            <div style={{ display: 'flex', width: 400, justifyContent: 'start', color: 'white' }}>
             
            </div>
            <div style={{ display: 'flex', width: 400, alignItems: 'space-between', justifyContent: 'space-between', color: 'white' }}>
               {otp && <Button onClick={()=>{verifyEmailOtp()}} style={{ background: '#0E0D1B', padding: 5, width: 290, color: 'white' }}>Verify</Button>}
                
                {
                
                    <Button onClick={() => {
                        if(minutes === 0 && seconds === 0){
                            var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

                            axiosInstance.post(url+'/creator/resend_email_verification',{},{
                                headers: {
                                    "Authorization": "Bearer " + userInfo
                                }
                            }).then((res) => {
                                
                                setPostInfo(res.data);
                                setEmail(res.data.email);
                                setMinutes(5);
                            setSeconds(12);
                            }).catch((err) => {
                                
                            })
                            
                        }else{

                        }
                    }} style={{ background: (minutes === 0 && seconds === 0 )? '#0E0D1B' : 'lightgray', padding: 5, width: 100, color: 'white' }}>Resend</Button>
                }
            </div>
        </Container>
    );
}

let CustomInput = styled.input`
    padding: 20px;
    border-radius: 5px;
    margin: 5px;
    border: 1px solid #ccc;
    width: 400px;
`;

let Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1000;
`;
