import { useEffect, useState } from "react"
import axiosInstance from "../../../axiosConfig"
import { url } from "../../../util/constant"
import BusinessAccordion, { BusinessData } from "./BusinessAccordion";

export default function Businesses() {

    const [businesses, setBusinesses] = useState<BusinessData[]>([]);

    useEffect(() => {
        var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;

        axiosInstance.get(url + "/admin/get_businesses", {
            headers: {
                "Authorization": "Bearer " + userInfo
            }
        }).then((res) => {
            
            if (res.data.status == "success") {
                setBusinesses(res.data.data);
            }
        }).catch((err) => {
            
        })
    }, [])

    return (

        <div className="m-auto w-[95%]">

            {businesses.map((business) => {
                return (
                    <BusinessAccordion key={business.id} business={business} />

                )
            })}
    

        </div>
    )
}




