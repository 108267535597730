import { createAsyncThunk } from '@reduxjs/toolkit';
import { setEmailVerified, setAccountStatus, setDashboardContent } from './dashboardSlice';
import { setError, clearError, setLoading } from '../zeterState/zeterStateSlice';
import axiosInstance from '../../axiosConfig';
import { url } from '../../util/constant';
import { ChartData } from './dashboardInterface';
import { RootState } from '../RootState';
import { AppDispatch } from '..';

interface FHFData {
    CampaignId: string | 0;
    ExpectedView: number | 0;
    viewCount: number | 0; 
}

export const initializeDashboard = createAsyncThunk(
    'dashboard/initialize',
    async (_, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };

        try {
            dispatch(clearError());
            dispatch(setLoading(true));

            const emailVerificationResponse = await axiosInstance.get(
                `${url}/creator/is_email_verified`,
                { headers }
            );
            dispatch(setEmailVerified(emailVerificationResponse.data.verifiedEmail));

            const accountStatusResponse = await axiosInstance.get(
                `${url}/creator/status/get_account_status`,
                { headers }
            );
            if (accountStatusResponse.data.status === "success") {
                dispatch(setAccountStatus(accountStatusResponse.data));
            }

            return { success: true };
        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error initializing dashboard',
                code: error.response?.status || 500
            }));
            return { success: false, error };
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const fetchDashboardData = createAsyncThunk(
    'dashboard/fetchData',
    async (_, { dispatch }) => {
        const userToken = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"];
        const headers = { Authorization: `Bearer ${userToken}` };
        try {
            dispatch(clearError());
            dispatch({ type: 'zeterState/setLoading', payload: true });
            // Fetch earnings data
            const earningsResponse = await axiosInstance.get(
                `${url}/creator/dashboarad/data/earning`,
                { headers }
            );
            // Fetch campaign stats
            const campaignStatsResponse = await axiosInstance.get(
                `${url}/creator/dashboarad/data/all`,
                { headers }
            );
            // Fetch FHF data
            const fhfResponse = await axiosInstance.get(
                `${url}/creator/dashboarad/data/perorder`,
                { headers }
            );
            let averageMonthlyEarning = earningsResponse.data.montlyEarnings.reduce((acc: number, val: { TotalEarnings: number }) => acc + val.TotalEarnings, 0)

            let fhfFinalData  = {
                labels: fhfResponse.data.map((val: FHFData) => "cmp" ),
                datasets: [
                    {
                        label: 'Expected View',
                        backgroundColor: "#0A0D25",
                        data: fhfResponse.data.map((item: FHFData) => item?.ExpectedView),
                    },
                    {
                        label: 'Actual View',
                        backgroundColor: "#707EAE",
                        pointBackgroundColor: "black",
                        data: fhfResponse.data.map((item: FHFData) => item?.viewCount )
                    }
                ]
            };

            let weeklyOrderFinalData : ChartData = {
                labels: earningsResponse.data.montlyEarnings.map((item: {Month: string}) => item.Month),
                datasets: [
                    {
                        label: 'Earnings',
                        backgroundColor: "#0A0D25",
                        data: earningsResponse.data.montlyEarnings.map((item: {TotalEarnings: number}) => item.TotalEarnings),
                        barPercentage: 0.3,
                        categoryPercentage: 0.5
                    }
                ]
            };

            dispatch(setDashboardContent({
                earningData: earningsResponse.data,
                averageMonthlyEarning: averageMonthlyEarning,
                campaignStats: campaignStatsResponse.data,
                fhfData: fhfFinalData,
                weeklyOrder: weeklyOrderFinalData
            }));

        } catch (error: any) {
            dispatch(setError({
                message: error.response?.data?.message || 'Error fetching dashboard data',
                code: error.response?.status || 500
            }));
            throw error;
        } finally {
            dispatch({ type: 'zeterState/setLoading', payload: false });
        }
    }
)